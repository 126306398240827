import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import ResponsiveContainer, {
  ResponsiveFullWidth,
} from "@src/styles/responsive-container"
import HtmlContent, { htmlToJsx } from "@src/components/core-value-html"
const VariantBlue = css`
  background-color: ${props => props.theme.lightBlue};
  color: ${props => props.theme.darkBlue};
  margin-top: 30px;
  padding: 30px 0;

  & > h3 {
    margin: 0;
    text-align: center;
    font-weight: normal;
  }

  &:last-child {
    margin-bottom: -30px;
  }

  &.blue + .blue {
    margin-top: 5px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-top: 50px;
    padding: 40px 0;
    & > h3 {
      font-size: 24px;
      line-height: 30px;
      margin: 15px 0;
    }
    &:last-child {
      margin-bottom: -50px;
    }
  }
`
const VariantGray = css`
  color: ${props => props.theme.darkGray};
  & a {
    color: ${props => props.theme.darkGray};
  }
  & > h3 {
    text-align: center;
    margin: 30px 0 20px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > h3 {
      margin: 50px 0 30px;
    }
  }
`
const VariantTiles = css`
  justify-items: center;
  & > .actions {
    margin: 10px 0;
    &:last-child {
      margin: 10px 0 0;
    }
  }

  @media only screen and (${props => props.theme.screen.small.max}) {
    grid-template-columns: minmax(1rem, 1fr) minmax(0, calc(100vw - 20px)) minmax(
        1rem,
        1fr
      );
  }

  @media only screen and (${props => props.theme.screen.desktop.min}) {
    justify-items: stretch;
    & > h2,
    & > h3 {
      text-align: center;
    }
    & > .actions {
      margin: 30px 0;
      &:last-child {
        margin: 30px 0 0;
      }
    }
  }
`
const VariantCard = css`
  display: grid;
  & > * {
    grid-column: 2 / -2;
  }
  grid-template-columns: minmax(1rem, 1fr) minmax(auto, 600px) minmax(1rem, 1fr);
`
const VariantDefault = css`
  & img {
    max-width: 100%;
    height: auto;
  }
  & picture > img {
    height: 100%;
  }
`
const AlignLeft = css`
  & > h2,
  & > h3,
  &.contained > div > h2,
  &.contained > div > h3 {
    text-align: left;
  }
`
const AlignDefault = css`
  & > h2,
  & > h3,
  &.contained > div > h2,
  &.contained > div > h3 {
    text-align: center;
  }
`
const AlignCenter = css`
  text-align: center;
`
const AlignLeftMobile = css`
  ${AlignDefault}
  @media only screen and (${props => props.theme.screen.mobile.max}) {
    justify-items: flex-start;
    ${AlignLeft}
  }
`
const AlignLeftTablet = css`
  ${AlignDefault}
  @media only screen and (${props => props.theme.screen.tablet.max}) {
    justify-items: flex-start;
    ${AlignLeft}
  }
`
const alignment = {
  default: AlignDefault,
  left: AlignLeft,
  "left-mobile": AlignLeftMobile,
  "left-tablet": AlignLeftTablet,
  center: AlignCenter,
}

const StyledSection = styled.section`
  ${props =>
    props.variant === "card"
      ? VariantCard
      : css`
          ${ResponsiveContainer}
          ${ResponsiveFullWidth}
        `}

  & > .actions {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .actions {
      gap: 20px;
    }
  }

  ${props =>
    props.variant === "blue"
      ? VariantBlue
      : props.variant === "gray"
      ? VariantGray
      : props.variant === "tiles"
      ? VariantTiles
      : VariantDefault}

  ${props => (props.align ? alignment[props.align] : alignment["default"])}

  ${props =>
    props.hide === "small"
      ? css`
          @media only screen and (${props => props.theme.screen.small.min}) {
            display: none;
          }
        `
      : undefined}

        ${props =>
    props.visible === "small"
      ? css`
          display: none;
          @media only screen and (${props => props.theme.screen.small.min}) {
            display: block;
          }
        `
      : undefined}
`
const ResponsiveSection = ({
  children,
  html,
  id,
  className,
  contained,
  variant,
  align,
  visible,
  hide,
}) => {
  const classNames = []
  if (className) {
    classNames.push(className)
  }
  if (variant) {
    classNames.push(variant)
  }
  if (contained) {
    classNames.push("contained")
  }
  const sectionProps = {
    id,
    className: classNames.join(" "),
    variant,
    align,
    visible,
    hide,
  }
  return html ? (
    <StyledSection {...sectionProps}>
      {contained ? <HtmlContent html={html} /> : htmlToJsx(html)}
    </StyledSection>
  ) : (
    <StyledSection {...sectionProps}>
      {contained ? <div>{children}</div> : children}
    </StyledSection>
  )
}
ResponsiveSection.propTypes = {
  children: PropTypes.node,
  html: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
  contained: PropTypes.bool,
  variant: PropTypes.oneOf(["blue", "gray", "tiles", "card"]),
  align: PropTypes.oneOf(["left", "left-tablet", "left-mobile", "center"]),
  visible: PropTypes.oneOf(["small"]),
  hide: PropTypes.oneOf(["small"]),
}
export default ResponsiveSection
