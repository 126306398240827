export const SYSTEM_NAMES = {
  imperial: "imperial",
  metric: "metric",
}

export const SYSTEM_UNITS = {
  kilometer: "km",
  meter: "m",
  mile: "mi",
  foot: "ft",
}
export const SYSTEM_UNITS_LONG = {
  [SYSTEM_UNITS.kilometer]: "kilometer",
  [SYSTEM_UNITS.meter]: "meter",
  [SYSTEM_UNITS.mile]: "mile",
  [SYSTEM_UNITS.foot]: "foot",
}

const CONVERSIONS = {
  [SYSTEM_UNITS.kilometer]: {
    [SYSTEM_UNITS.mile]: 0.621371,
    [SYSTEM_UNITS.foot]: 3280.84,
    [SYSTEM_UNITS.kilometer]: 1.0,
    [SYSTEM_UNITS.meter]: 1000.0,
  },
  [SYSTEM_UNITS.meter]: {
    [SYSTEM_UNITS.foot]: 3.28084,
    [SYSTEM_UNITS.mile]: 0.000621371,
    [SYSTEM_UNITS.meter]: 1.0,
    [SYSTEM_UNITS.kilometer]: 0.001,
  },
  [SYSTEM_UNITS.mile]: {
    [SYSTEM_UNITS.kilometer]: 1.60934,
    [SYSTEM_UNITS.meter]: 1609.34,
    [SYSTEM_UNITS.mile]: 1.0,
    [SYSTEM_UNITS.foot]: 5280.0,
  },
  [SYSTEM_UNITS.foot]: {
    [SYSTEM_UNITS.meter]: 0.3048,
    [SYSTEM_UNITS.kilometer]: 0.0003048,
    [SYSTEM_UNITS.foot]: 1.0,
    [SYSTEM_UNITS.mile]: 0.000189394,
  },
}

const UNIT_CONVERSIONS = {
  [SYSTEM_UNITS.kilometer]: {
    [SYSTEM_NAMES.imperial]: SYSTEM_UNITS.mile,
  },
  [SYSTEM_UNITS.meter]: {
    [SYSTEM_NAMES.imperial]: SYSTEM_UNITS.foot,
  },
  [SYSTEM_UNITS.mile]: {
    [SYSTEM_NAMES.metric]: SYSTEM_UNITS.kilometer,
  },
  [SYSTEM_UNITS.foot]: {
    [SYSTEM_NAMES.metric]: SYSTEM_UNITS.meter,
  },
}

export const convertUnit = (value, fromUnit, toUnit) => {
  if (CONVERSIONS[fromUnit] && CONVERSIONS[fromUnit][toUnit]) {
    return value * CONVERSIONS[fromUnit][toUnit]
  }
  return value
}
export const getUnitEquivalent = (fromUnit, toSystem) => {
  return (
    (UNIT_CONVERSIONS[fromUnit] && UNIT_CONVERSIONS[fromUnit][toSystem]) ||
    fromUnit
  )
}
export const getUnitSystem = unit => {
  switch (unit.toLowerCase()) {
    case SYSTEM_UNITS.mile:
    case SYSTEM_UNITS.foot:
      return SYSTEM_NAMES.imperial

    case SYSTEM_UNITS.kilometer:
    case SYSTEM_UNITS.meter:
      return SYSTEM_NAMES.metric
  }
}
