import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const ImagePlaceholderStyle = styled.div`
  background-color: ${props =>
    props.variant === "green" ? props.theme.green : props.theme.placeholder};
  ${props =>
    props.wrapped
      ? "width: 100%;"
      : props.styleWidth &&
        css`
          width: ${props.styleWidth}${!isNaN(props.styleWidth) && "px"};
        `}
  ${props =>
    props.wrapped
      ? "height: 100%;"
      : props.styleHeight &&
        css`
          height: ${props.styleHeight}${!isNaN(props.styleHeight) && "px"};
        `}
`
const ImagePlaceholder = ({ width, height, wrapped, variant }) => {
  return (
    <ImagePlaceholderStyle
      styleWidth={width}
      styleHeight={height}
      wrapped={wrapped}
      variant={variant}
    />
  )
}
ImagePlaceholder.propTypes = {
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapped: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}
export default ImagePlaceholder
