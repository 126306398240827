import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Link from "@src/components/core-link"
import Icon from "@src/components/core-icon"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import NavFooter from "@src/components/nav-site-footer"
import { mapListToTree } from "@src/utils/trees"
import { useFooterBG } from "@src/hooks/useFooterBG"

const Brand = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(3, auto);
  column-gap: 10%;
  row-gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  & > p {
    grid-row: 1 / 2;
    grid-column: 1 / 4;
    color: ${props => props.theme.darkGray};
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  & > .atta-image {
    grid-row: 2 / 3;
    grid-column: 1 / 4;
    padding-bottom: 15px;
  }
  & > .bbb-image {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    justify-self: center;
    padding-left: 10px;
  }
  & > .ustoa-image {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    width: fit-content;
  }
  & > .lnt-image {
    grid-row: 3 / 4;
    grid-column: 3 / 4;
    width: fit-content;
    padding-right: 10px;
  }
  @media only screen and (${props => props.theme.footer.small.min}) {
    grid-template-columns: repeat(4, auto);
    column-gap: 30px;
    & > p {
      grid-column: 1 / 5;
    }
    & > .ustoa-image {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
    & > .bbb-image {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
      padding: 0px;
    }
    & > .atta-image {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
      padding: 0px;
    }
    & > .lnt-image {
      grid-row: 2 / 3;
      grid-column: 4 / 5;
      padding: 0px;
    }
  }
  @media only screen and (${props => props.theme.footer.large.min}) {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, auto);
    column-gap: 0px;
    row-gap: 30px;

    margin-right: 60px;
    & > p {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
      margin-top: 0px;
      text-align: left;
    }
    & > .atta-image {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
    & > .bbb-image {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
      width: fit-content;
    }
    & > .ustoa-image {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
      & img {
        width: 220px;
      }
    }
    & > .lnt-image {
      grid-row: 3 / 4;
      grid-column: 2 / 3;
      margin-left: -70px;
    }
  }
`
const StyledFooter = styled.footer`
  padding: 30px 0 16px;
  background-color: ${props => props.theme.black};
  & > * {
    font-family: system-ui;
  }
  & a {
    text-decoration: none;
  }
  // Footer Content
  & .footer-content {
    display: flex;
    justify-content: center;
    @media only screen and (${props => props.theme.footer.medium.max}) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    @media only screen and (${props => props.theme.footer.large.min}) {
      align-items: start;
      position: relative;
      padding-bottom: 80px;
      justify-content: center;
      border-bottom: 1px solid ${props => props.theme.white};
      max-width: ${props => props.theme.footer.large.contentMaxWidths}px;
      margin: 0 auto;
    }
  }
  & .footer-header {
    color: ${props => props.theme.primary};
    font-size: 1.5rem;
    font-weight: 700;
  }
  // App links
  & .app-container {
    width: 100%;
    text-align: left;
    padding: 20px 30px 20px 16px;
    & > span,
    & > a {
      font-weight: 500;
    }
    & > .footer-app-links {
      display: flex;
      margin-top: 20px;
      & > a:first-child {
        margin-right: 20px;
      }
    }
    @media only screen and (${props => props.theme.screen.medium.min}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin-bottom: 20px;
      & > .footer-app-links {
        & > a:first-child {
          margin-left: 10px;
        }
      }
    }
    @media only screen and (${props => props.theme.screen.large.min}) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;
      position: absolute;
      margin-bottom: 0;
      bottom: 20px;
      left: 0;
      & > .footer-app-links {
        margin-top: 20px;
        & > a:first-child {
          margin-left: 0;
        }
      }
    }
  }
  // Social Media Links
  & .social-media {
    align-self: baseline;
    margin: 15px 0 25px 10px;
    & > a {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid ${props => props.theme.white};
      text-align: center;
      line-height: 26px;
      margin: 0 5px;
      & > .icon {
        font-size: 18px;
        line-height: 16px;
        color: ${props => props.theme.white};
      }
    }

    @media only screen and (${props => props.theme.footer.medium.min}) {
      align-self: center;
      justify-content: flex-start;
      margin: 20px 0 0;
    }
    @media only screen and (${props => props.theme.footer.large.min}) {
      margin: 0;
      position: absolute;
      bottom: 35px;
      right: 0;
    }
  }

  // Official Content
  & .official-content {
    color: ${props => props.theme.lightGray};
    font-size: 16px;
    line-height: 38px;
    padding-top: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (${props => props.theme.footer.large.min}) {
      flex-direction: row-reverse;
      justify-content: center;
      & > .official-links,
      & > .copyright {
        flex: 0 1 ${props => props.theme.footer.large.contentMaxWidths / 2.0}px;
      }
    }
  }
  // Official Links
  & .official-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & > a {
      flex: 0 0 40%;
      text-align: center;
      margin: 0 5px;
      color: ${props => props.theme.lightGray};
      white-space: nowrap;
      padding: 0 5px;
    }
    @media only screen and (${props => props.theme.footer.small.min}) {
      & > a {
        flex: 0 0 auto;
      }
    }
    @media only screen and (${props => props.theme.footer.large.min}) {
      flex-wrap: nowrap;
      justify-content: flex-end;
    }
  }
  // Copyright
  & .copyright {
    text-align: center;
    line-height: 24px;
    margin-top: 12px;

    & > span {
      display: block;
    }

    @media only screen and (${props => props.theme.footer.large.min}) {
      text-align: left;
    }
  }

  @media only screen and (${props => props.theme.footer.large.min}) {
    padding: 50px 0 22px;
    ${props =>
      props.$bgImage
        ? css`
            background-image: url(${props.$bgImage});
            background-size: cover;
            background-position: right;
            background-color: #fbf8f5; // Natasha design
            padding-top: 320px;
          `
        : null}
  }
`
const StoreBadgeWrappers = styled(ImageWrapper)`
  width: 140px;
  // Required or else app store icon will be larger
  & > .lazyload-wrapper > img.app-store-download {
    width: 90%;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: 210px;
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    width: 140px;
  }
`
const AppStoreLinks = () => {
  const appStoreLink = process.env.GATSBY_10A_APP_STORE_LINK
  const playStoreLink = process.env.GATSBY_10A_PLAY_STORE_LINK
  return (
    <div className="footer-app-links">
      <Link to={playStoreLink}>
        <Image
          src="/icons/google-play-badge-cropped.png"
          alt="Play store icon"
          wrapper={StoreBadgeWrappers}
        />
      </Link>
      <Link to={appStoreLink}>
        <Image
          className="app-store-download"
          src="/icons/app-store-badge.svg"
          alt="App store icon"
          wrapper={StoreBadgeWrappers}
        />
      </Link>
    </div>
  )
}
const SiteFooter = ({ legalLinks, footerLinks, socialMediaLinks }) => {
  const { bgImage } = useFooterBG()
  return (
    <StyledFooter $bgImage={bgImage && bgImage?.image?.src}>
      <div className="footer-content">
        <Brand>
          <p className="footer-header">Find your next adventure</p>
          <Link
            className="bbb-image"
            external
            rel="nofollow"
            href="https://www.bbb.org/"
            title="Better Business Bureau"
          >
            <Image
              src="/images/BBB-white.svg"
              width={72}
              height={90}
              alt="A+ Rated, BBB Accredited Business"
            />
          </Link>
          <Link
            className="atta-image"
            external
            rel="nofollow"
            href="https://www.adventuretravel.biz/"
            title="Adventure Travel Trade Association"
          >
            <Image
              src="/images/ATTA-member-white.svg"
              width={322}
              height={86}
              alt="Member of the Adventure Travel Trade Association"
            />
          </Link>
          <Link
            className="ustoa-image"
            external
            rel="nofollow"
            href="https://ustoa.com/"
            title="USTOA"
          >
            <Image
              src="/images/USTOA-white.svg"
              width={150}
              height={90}
              alt="Member of United States Tour Operators Association"
            />
          </Link>
          <Link
            className="lnt-image"
            external
            rel="nofollow"
            href="https://lnt.org/"
            title="Leave No Trace"
          >
            <Image
              src="/images/LNT-white.svg"
              width={150}
              height={90}
              alt="Proud Partner of Leave No Trace"
            />
          </Link>
          {/* </Certifications> */}
        </Brand>
        <NavFooter items={mapListToTree(footerLinks)} />
        <div className="app-container">
          <AppStoreLinks />
        </div>
        <div className="social-media">
          {Object.keys(socialMediaLinks).map(key => {
            const platform = socialMediaLinks[key]
            return (
              <Link
                external
                href={platform.link}
                title={`${platform.tag} on ${platform.name}`}
                key={key}
              >
                <Icon glyph={platform.icon} />
              </Link>
            )
          })}
        </div>
      </div>
      <div className="official-content">
        <div className="official-links">
          {legalLinks?.map(({ id, label, url }) => (
            <Link key={id} to={url}>
              {label}
            </Link>
          ))}
        </div>
        <div className="copyright">
          <span>Copyright &copy; 2022, 10Adventures Inc.</span>{" "}
          <span>All rights reserved.</span>
        </div>
      </div>
    </StyledFooter>
  )
}
SiteFooter.propTypes = {
  legalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      parentId: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  socialMediaLinks: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.string,
      tag: PropTypes.string,
    })
  ),
}

export default SiteFooter
