import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Link from "@src/components/core-link"
import Icon from "@src/components/core-icon"

const StyledPages = styled.nav`
  display: flex;
  width: 100%;
  margin: 15px auto ${props => (props.variant === "block" ? "0" : "30px")};
  width: 282px;

  ${props =>
    props.variant === "routes" &&
    css`
      justify-content: center;
      @media only screen and (${props => props.theme.screen.small.max}) {
        width: 100%;
      }
    `}

  @media only screen and (${props => props.theme.screen.small.min}) {
    margin: 25px auto ${props => (props.variant === "block" ? "0" : "30px")};
    width: ${props =>
      props.variant === "full-width" || props.variant === "routes"
        ? "100%"
        : "564px"};
  }
`
const StyledPage = styled(Link)`
  flex: 1 1 100%;
  display: ${props => (props.variant === "routes" ? "flex" : "inline-block")};
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: ${props => (props.active ? "underline" : "none")};

  font-size: 16px;
  line-height: 18px;
  height: 18px;

  & ${Icon} {
    & .icon {
      vertical-align: top;
      font-size: 18px;
    }
  }
  ${props =>
    props.variant === "block"
      ? css`
          font-family: system-ui;
          text-decoration: none;
          border-radius: 8px;
          ${props.active
            ? css`
                color: ${props.theme.white};
                background: ${props.theme.black};
              `
            : undefined}
        `
      : undefined}

  ${props =>
    props.variant === "routes"
      ? css`
          text-decoration: none;
          border-radius: 8px;
          font-size: 16px;
          background: #ffffff;
          margin: 5px;
          box-shadow: 0px 2px 4px rgba(118, 117, 117, 0.25);
          & :hover {
            color: #26383b;
          }

          & ${Icon} {
            font-size: 14px;

            & .icon {
              vertical-align: top;
              font-size: 16px;
            }
          }

          ${props.active
            ? css`
                color: #ffffff;
                background: #26383b;

                :hover {
                  color: #ffffff;
                  cursor: default;
                }
                & ${Icon} {
                  font-size: 14px;
                  & .icon {
                    vertical-align: top;
                    font-size: 16px;
                  }
                }
              `
            : undefined}
        `
      : undefined}
      
  @media only screen and (${props => props.theme.screen.small.max}) {
    ${props =>
      props.variant === "routes"
        ? css`
            height: 25px;
          `
        : undefined}
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    ${props =>
      props.variant === "routes"
        ? css`
            line-height: 40px;
            height: 40px;
            max-width: 40px;
            min-height: 40px;
            max-width: 40px;
            margin: 5px;

            & ${Icon} {
              font-size: 16px;

              & .icon {
                vertical-align: top;
                font-size: 16px;
              }
            }

            ${props.active
              ? css`
                  color: #ffffff;
                  background: #26383b;

                  :hover {
                    color: #ffffff;
                    cursor: default;
                  }
                  & ${Icon} {
                    font-size: 14px;
                    & .icon {
                      vertical-align: top;
                      font-size: 16px;
                    }
                  }
                `
              : undefined}
          `
        : undefined}

    font-size: 18px;
    line-height: 24px;
    height: 24px;
    & ${Icon} {
      & .icon {
        font-size: 24px;
      }
    }
  }
`

const NavPaginate = ({
  className,
  label,
  current,
  total,
  show,
  setPage, // Callback for dynamic pagination
  getLinkProps, // For static
  navEnds,
  navStep,
  variant,
}) => {
  const handlePageClick = (event, pageNumber) => {
    event.preventDefault()
    if (setPage) {
      setPage(pageNumber)
    }
  }
  const renderPageLink = (
    pageNumber,
    { first, last, prev, next, disabled } = {}
  ) => {
    const linkProps = getLinkProps ? getLinkProps(pageNumber) : {}
    if (!linkProps.to && !linkProps.href) {
      linkProps.onClick = event => handlePageClick(event, pageNumber)
    }
    const active = !disabled && pageNumber === current
    const linkContent = first ? (
      "First"
    ) : last ? (
      "Last"
    ) : prev ? (
      <>
        <Icon
          glyph={
            variant === "block" || variant === "routes"
              ? "v2-angle-small-left"
              : "circle-chevron-left"
          }
        />
      </>
    ) : next ? (
      <>
        <Icon
          glyph={
            variant === "block" || variant === "routes"
              ? "v2-angle-small-right"
              : "circle-chevron-right"
          }
        />
      </>
    ) : (
      pageNumber
    )
    const key = first
      ? "page-first"
      : last
      ? "page-last"
      : prev
      ? "page-prev"
      : next
      ? "page-next"
      : `page-${pageNumber}`
    const label = active
      ? `Current page, Page ${pageNumber}`
      : first
      ? "Go to first page"
      : last
      ? `Go to last page, Page ${total}`
      : prev
      ? `Go to previous page, Page ${pageNumber}`
      : next
      ? `Go to next page, Page ${pageNumber}`
      : `Go to Page ${pageNumber}`
    if (disabled) {
      linkProps["aria-disabled"] = true
    } else {
      linkProps["aria-label"] = label
      if (active) {
        linkProps["aria-current"] = true
      }
    }
    return (
      <StyledPage
        key={key}
        active={active}
        variant={variant}
        disabled={disabled}
        {...linkProps}
      >
        {linkContent || pageNumber}
      </StyledPage>
    )
  }
  const start = Math.max(1, Math.min(current - show / 2, total - show + 1))
  const finish = Math.min(total, start + show - 1)
  const Pages = Array.from(Array(finish - start + 1), (value, index) =>
    renderPageLink(start + index)
  )
  if (navStep) {
    Pages.unshift(
      renderPageLink(current > 1 ? current - 1 : null, {
        prev: true,
        disabled: current <= 1,
      })
    )
  }
  if (navEnds) {
    Pages.unshift(renderPageLink(1, { first: true, disabled: current <= 1 }))
  }
  if (navStep) {
    Pages.push(
      renderPageLink(current < total ? current + 1 : null, {
        next: true,
        disabled: current >= total,
      })
    )
  }
  if (navEnds) {
    Pages.push(
      renderPageLink(total, { last: true, disabled: current >= total })
    )
  }
  return (
    <StyledPages
      className={className}
      role="navigation"
      aria-label={label || "Page navigation"}
      variant={variant}
    >
      {Pages}
    </StyledPages>
  )
}
NavPaginate.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  current: PropTypes.number,
  total: PropTypes.number,
  show: PropTypes.number,
  setPage: PropTypes.func,
  getLinkProps: PropTypes.func,
  navEnds: PropTypes.bool,
  navStep: PropTypes.bool,
  variant: PropTypes.oneOf(["full-width", "block", "routes"]),
}
NavPaginate.defaultProps = {
  label: "Page navigation",
  current: 1,
  total: 1,
  show: 10,
  navEnds: false,
  navStep: true,
}
export default NavPaginate
