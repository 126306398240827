import axios from "axios"
import { netlifyFormsEndpoint } from "./_config"

const stripPassword = submitData => {
  delete submitData.password
  delete submitData["password-confirm"]
  return submitData
}

const NetlifyForms = {
  submitForm: (formName, values) => {
    if (!netlifyFormsEndpoint) {
      throw new Error("Missing form submission endpoint")
    }
    stripPassword(values)
    return axios
      .post(
        netlifyFormsEndpoint,
        new URLSearchParams({ "form-name": formName, ...values }).toString(),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .then(() => true)
  },
}
export default NetlifyForms
