import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { LocaleContext } from "@src/context/locale-context"
import { UserContext } from "@src/context/user-context"
import SiteSearch from "@src/components/site-search"
import NavPrimary from "@src/components/nav-site-primary"
import NavUser from "@src/components/nav-user"
import Link from "@src/components/core-link"
import LocalPhoneNumber from "@src/components/core-value-local-phone"
import { scrollbarStyle, scrollbarStyleOutline } from "@src/styles/elements"
import LogoSVG from "@src/images/logo-dark.svg"
import UserActions from "@src/components/content-user-flow-modal"

const Brand = styled.div`
  & > a {
    display: inline-block;
    text-decoration: none;
    & > svg {
      height: 17px;
      display: inline-block;
    }
  }

  @media only screen and (${props => props.theme.header.tablet.max}) {
    text-align: center;
    flex: 1 1 100%;
    padding: 3px 22px 0;
  }
  @media only screen and (${props => props.theme.header.small.min}) {
    flex: 0 0 100%;
    padding: 10px;
    & > a {
      & > svg {
        height: 24px;
      }
    }
  }
  @media only screen and (${props => props.theme.header.medium.min}) {
    flex: 1 0 100%;
    padding: 10px 200px;
    text-align: center;
  }
  @media only screen and (${props => props.theme.header.large.min}) {
    flex: 1 0 auto;
    padding: 10px 20px 10px 60px;
    text-align: left;
  }
  @media only screen and (${props => props.theme.header.full.min}) {
    min-width: 293px;
    max-width: 333px;
  }
`
const Search = styled(SiteSearch)`
  position: fixed;
  z-index: ${props => props.theme.layers.siteHeader - 25};
  top: ${props => props.theme.header.tablet.height}px;
  left: 0;
  width: 100%;
  background-color: ${props => props.theme.bg1};
  padding: 16px;

  /* animate the active state */
  transition: transform 0.1s ease-in, opacity 0.2s ease;
  transform-origin: top center;

  /* opacity is important to prevent flicker when resizing large to small  */
  opacity: 0;
  transform: scaleY(0);
  &.active {
    opacity: 1;
    transform: scaleY(1);
  }

  & input {
    border-radius: 22px;
    padding: 0 24px 0 44px;
  }
  & .icon {
    padding: 12px 10px 12px 14px;
  }

  & .results {
    left: 35px;
    right: 35px;
    max-width: 100%;
    max-height: calc(
      100vh - ${props => props.theme.header.tablet.height + 60}px
    );
  }

  @media only screen and (${props => props.theme.header.tablet.max}) {
    & input {
      padding: 0 24px;
    }
    & .icon {
      display: none;
    }
    & + button {
      position: absolute;
      z-index: ${props => props.theme.layers.siteHeader - 20};
      top: 10px;
      right: 6px;
    }
  }
  @media only screen and (${props =>
      props.theme.header.small.min}) and (${props =>
      props.theme.header.large.max}) {
    position: absolute;
    top: ${props => props.theme.header.small.height + 16}px;

    background-color: transparent;
    text-align: center;
    padding: 0 16px;

    & > span {
      max-width: 456px;
    }

    & .results {
      text-align: left;
      position: initial;
      left: initial;
      right: initial;
      margin: 0 auto;
      min-width: 420px;
      max-width: 600px;
      max-height: calc(
        100vh - ${props => props.theme.header.small.height + 60}px
      );
    }
  }
  @media only screen and (${props => props.theme.header.large.min}) {
    top: ${props => props.theme.header.large.height + 16}px;
    & .results {
      max-height: calc(
        100vh - ${props => props.theme.header.large.height + 60}px
      );
      max-width: 600px;
    }
  }
  @media only screen and (${props => props.theme.header.full.min}) {
    position: relative;
    top: initial;
    opacity: 1;
    transform: none;
    background-color: transparent;
    flex: 0 1 400px;
    padding: 0;
    & + button {
      display: none;
    }
    & .results {
      border-radius: 0 4px 4px 4px;
      left: 20px;
      right: initial;
      min-width: 500px;
      max-height: calc(100vh - 62px);
    }
  }
`
const PhoneNumber = styled(LocalPhoneNumber)`
  @media only screen and (${props => props.theme.header.small.min}) {
    padding: 0 20px 2px;
    position: absolute;
    top: 22px;
    right: 30px;
  }
  @media only screen and (${props => props.theme.header.large.min}) {
    position: initial;
    top: initial;
    right: initial;
  }
`
const UserMenu = styled(NavUser)`
  @media only screen and (${props => props.theme.header.small.min}) {
    & > ul {
      display: flex;
      & > li {
        flex: 0 0 auto;
        text-align: center;
        padding: 10px 15px;
        display: block;
        & > a {
          display: block;
        }
      }
    }
  }
  @media only screen and (${props =>
      props.theme.header.medium.min}) and (${props =>
      props.theme.header.medium.max}) {
    position: absolute;
    top: 66px;
    right: 30px;
  }
`
const Menu = styled(NavPrimary)`
  @media only screen and (${props => props.theme.header.tablet.max}) {
    position: absolute;
    z-index: ${props => props.theme.layers.siteHeader - 5};
    top: 10px;
    left: 6px;
    & + nav {
      position: fixed;
      z-index: ${props => props.theme.layers.siteHeader - 10};
      top: ${props => props.theme.header.tablet.height}px;
      bottom: 0;
      width: 95%;
      right: 100%;
      opacity: 0;
      transition: right 0.1s ease-in, opacity 0.1s ease-in;
      background-color: ${props => props.theme.bg1};
      padding: 20px 20px 30px 25px;

      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      & > div {
        flex: 0 0 auto;
      }
      & > ul {
        flex: 1 1 100%;
        overflow-y: auto;
        ${scrollbarStyle}
        ${scrollbarStyleOutline}
      }
      & ul,
      & li,
      & div {
        width: 100%;
      }

      & ul > li {
        margin: 0 0 5px;
        & > div:first-child {
          position: relative;
          padding: 5px 0 0 30px;

          & > a {
            margin: 4px 0 0;
            & > .icon {
              display: none;
            }
          }

          & > .submenu-toggle {
            position: absolute;
            left: 0;
            padding: 5px;
            & > .icon {
              font-size: 18px;
            }
          }
        }

        & .spread-header {
          padding: 10px 0 0;
          & > a + a {
            margin-left: 1.5em;
          }
        }
        & .spread-footer {
          padding: 5px 0;
        }

        &.active {
          & > div:first-child {
            position: sticky;
            top: 0;
            z-index: 10;
            background-color: ${props => props.theme.bg1};
          }
        }
      }

      & > ul > li {
        & > .spread-container {
          & > .spread-header,
          & > .spread-footer {
            padding-left: 12px;
          }
          & > .spread-header {
            position: sticky;
            top: 30px;
            z-index: 9;
            background-color: ${props => props.theme.bg1};
          }
          & > .spread-content {
            padding-left: 10px;
            & ul > li.with-separator {
              margin-left: -10px;
            }
          }
        }
        & ul > li {
          &.active > div:first-child {
            top: 60px;
            z-index: 8;
          }
          & > div:first-child {
            padding-left: 40px;
            & > .submenu-toggle {
              left: 10px;
            }
          }
          & ul > li {
            &.active > div:first-child {
              top: 90px;
              z-index: 7;
            }
            & > div:first-child {
              padding-left: 50px;
              & > .submenu-toggle {
                left: 20px;
              }
            }
            & ul > li {
              &.active > div:first-child {
                top: 120px;
                z-index: 6;
              }
              & > div:first-child {
                padding-left: 60px;
                & > .submenu-toggle {
                  left: 30px;
                }
              }
              & ul > li {
                &.active > div:first-child {
                  top: 150px;
                  z-index: 5;
                }
                & > div:first-child {
                  padding-left: 70px;
                  & > .submenu-toggle {
                    left: 40px;
                  }
                }
                & ul > li {
                  & > div:first-child {
                    padding-left: 80px;
                    & > .submenu-toggle {
                      left: 50px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      & > .addons {
        padding: 20px 0 0 5px;
        & > nav {
          & > ul {
            margin-top: 10px;
            & > li {
              margin: 8px 0 0;
              & > a {
                margin-left: 24px;
              }
            }
          }
        }
      }
    }
    &.active + nav {
      right: 5%;
      opacity: 1;
    }
  }
  @media only screen and (${props => props.theme.header.small.min}) {
    display: none;

    & + nav {
      /* primary navigation in header */
      flex: 1 0 auto;
      display: flex;
      justify-content: space-between;

      /* ensure everything in nav is flexed, including injected children */
      & > * {
        flex: 0 0 auto;
      }

      /* top-level menu */
      & > ul {
        display: flex;
        align-items: flex-start;
        & > li {
          flex: 0 1 auto;
          padding: 10px 15px 24px;
          text-align: center;

          /* general style for all nested menu items */
          & ul > li {
            margin: 10px;
            position: relative;
            &.with-icon {
              padding-left: 4px;
            }
            &.with-separator {
              border-top: 1px solid ${props => props.theme.borderGray};
              padding-top: 10px;
            }
          }

          /* style for 2nd-level menu */
          & > ul,
          &.spread > .spread-container {
            z-index: ${props => props.theme.layers.siteHeader - 10};
            background-color: ${props => props.theme.bg1};
            border-radius: 0 0 18px 18px;
            border-top: 1px solid ${props => props.theme.borderGray};
            padding: 10px;
          }

          /* horizontal spread */
          &.spread,
          & li.spread {
            & .spread-container {
              max-height: calc(
                99vh - ${props => props.theme.header.small.height}px
              );
              & > .spread-content {
                padding: 5px 0 5px 10px;
                max-width: calc(99vw - 20px);
                overflow-x: auto;
                ${scrollbarStyle}
                ${scrollbarStyleOutline}
                &::-webkit-scrollbar-track {
                  border-bottom-width: 0;
                }
              }
              & > .spread-header,
              & > .spread-footer {
                text-align: left;
              }
              & > .spread-header {
                border-bottom: 1px solid ${props => props.theme.borderGray};
                padding-bottom: 5px;
                & > a + a {
                  margin-left: 1.75em;
                }
              }
              & > .spread-footer {
                border-top: 1px solid ${props => props.theme.borderGray};
                padding-top: 12px;
              }
            }
            & .spread-content {
              text-align: left;
              & > ul {
                flex: 1 0 auto;
                max-height: 100%;
                overflow-y: auto;
                padding: 0 5px;
                ${scrollbarStyle}
                ${scrollbarStyleOutline}
                &::-webkit-scrollbar-track {
                  /* -4px vertical margins to hide left-border of next one (4px is half the border-radius of the scrollbar-track) */
                  margin: -4px 0;
                }
                &:first-child {
                  min-width: 190px;
                  padding-left: 0;
                  & > li {
                    margin-left: 25px;
                  }
                  & > li.with-separator,
                  & > li.with-separator ~ li {
                    margin-left: 10px;
                    padding-left: 5px;
                    margin-right: 5px;
                    padding-right: 5px;
                  }
                }
                & > li {
                  & > div {
                    & > .icon {
                      margin-right: -5px;
                    }
                  }
                  &.active {
                    position: sticky;
                    top: 0;
                    bottom: 0;
                    z-index: 10;
                    background-color: ${props => props.theme.white};
                  }
                }
                & + ul {
                  border-left: 1px solid ${props => props.theme.borderGray};
                  /* -1px to hide border behind previous scrollbar if there is one */
                  margin-left: -1px;
                }
              }
            }
          }

          /* positioning for dropdown menus */
          position: relative;
          & > ul,
          &.spread > .spread-container {
            position: absolute;
            top: 100%;
            left: -10px;
            min-width: max-content;
          }

          /* non-spread dropdown menus */
          &:not(.spread) > ul {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      & > .addons {
        display: flex;
        & > ${UserMenu}, & > ${PhoneNumber} {
          flex: 0 0 auto;
        }
      }
    }
  }
  @media only screen and (${props => props.theme.header.medium.min}) {
    & + nav {
      flex: 0 0 auto;
      justify-content: flex-end;
    }
  }
  @media only screen and (${props => props.theme.header.large.min}) {
    & + nav {
      /* top-level menu */
      & > ul > li {
        padding-bottom: 31px;
        /* horizontal spread */
        &.spread,
        & li.spread {
          & .spread-container {
            max-height: calc(
              99vh - ${props => props.theme.header.large.height}px
            );
          }
        }
      }
    }
  }
  @media only screen and (${props => props.theme.header.full.min}) {
    & + nav {
      flex: 1 0 auto;
    }
  }
`
const HeaderContainer = styled.header`
  position: sticky;
  z-index: ${props => props.theme.layers.siteHeader};
  top: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.bg1};
  height: ${props => props.theme.header.tablet.height}px;
  padding: 19px 16px;
  display: flex;

  /* add the effect this way so it can overlap Header children (ex. mobile menu panel) */
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 10px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: ${props => props.theme.layers.siteHeader - 1};
  }

  height: ${props => props.theme.header.tablet.height}px;

  @media only screen and (${props => props.theme.header.small.min}) {
    height: ${props => props.theme.header.small.height}px;
    padding: 18px 30px;

    flex-wrap: wrap;
    justify-content: center;

    &::before {
      display: none;
      height: 0;
    }
  }
  @media only screen and (${props => props.theme.header.large.min}) {
    height: ${props => props.theme.header.large.height}px;
    padding: 18px 40px;

    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`
const SiteHeader = ({ navItems }) => {
  const { user, signout } = useContext(UserContext)
  const [userFlowMode, setUserFlowMode] = useState(null)
  const [showUserModal, setShowUserModal] = useState(false)
  return (
    <>
      <HeaderContainer>
        <Brand>
          <Link href="/" title="10Adventures.com">
            <LogoSVG />
          </Link>
        </Brand>
        <Search />
        <Menu items={navItems}>
          <div className="addons">
            <LocaleContext.Consumer>
              {({ country }) => <PhoneNumber countryCode={country} />}
            </LocaleContext.Consumer>
            <UserMenu
              user={user}
              signout={signout}
              setUserFlowMode={setUserFlowMode}
              setShowUserModal={setShowUserModal}
            />
          </div>
        </Menu>
      </HeaderContainer>
      <UserActions
        defaultVisualMode={userFlowMode}
        isOpen={showUserModal}
        setIsOpen={setShowUserModal}
      />
    </>
  )
}
SiteHeader.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  loginRequired: PropTypes.bool,
}
export default SiteHeader
