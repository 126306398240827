import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { LocaleContext } from "@src/context/locale-context"
import { getCurrency } from "@src/utils/currency"

const CurrencyStyled = styled.span`
  & .currency-code {
    font-size: 0.4em;
  }
  & .currency-code-large {
    &:before {
      content: " ";
      white-space: pre;
    }
  }
`

const Currency = ({
  code: fromCurrency,
  amount: fromAmount,
  showAbbr,
  showSymbol,
  showDecimals,
  applyRounding,
  className,
}) => {
  return (
    <LocaleContext.Consumer>
      {({ currency, convertCurrency }) => {
        const toCurrency = currency && currency.code
        const exchangedAmount = convertCurrency(
          fromAmount,
          fromCurrency,
          toCurrency
        )
        const exchanged = exchangedAmount !== null
        const amount = exchanged ? exchangedAmount : fromAmount
        const currencyDef = getCurrency(exchanged ? toCurrency : fromCurrency)
        return (
          <CurrencyStyled className={className}>
            {currencyDef &&
              currencyDef.format(amount, {
                showAbbr: showAbbr === true,
                showSymbol,
                showDecimals,
                applyRounding:
                  applyRounding === "auto"
                    ? fromCurrency !== toCurrency
                    : applyRounding,
              })}
            {currencyDef && ["after", "after-full-size"].includes(showAbbr) && (
              <span
                className={`${
                  showAbbr === "after" ? `currency-code` : `currency-code-large`
                }`}
              >
                {currencyDef.code}
              </span>
            )}
          </CurrencyStyled>
        )
      }}
    </LocaleContext.Consumer>
  )
}
Currency.propTypes = {
  amount: PropTypes.number,
  code: PropTypes.string,
  className: PropTypes.string,
  showAbbr: PropTypes.oneOf([true, false, "after", "after-full-size"]),
  showSymbol: PropTypes.bool,
  showDecimals: PropTypes.bool,
  applyRounding: PropTypes.oneOf([true, false, "auto"]),
}
Currency.defaultProps = {
  showAbbr: false,
  showSymbol: true,
  showDecimals: false,
  applyRounding: "auto",
}
export default Currency
