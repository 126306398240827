import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import GTM from "@src/services/gtm"
import Icon from "@src/components/core-icon"
import Link from "@src/components/core-link"
const StyledIcon = styled(Icon)``

const FilledButton = css`
  ${props =>
    props.$color === "white-gray"
      ? css`
          background-color: ${props.theme.white};
          color: ${props.theme.lightGray};
          &:hover {
            color: ${props => props.theme.primary};
          }
        `
      : props.$color === "blue"
      ? css`
          background-color: ${props.theme.lightBlue};
          color: ${props.theme.darkBlue};
          border: 1px solid ${props.theme.darkBlue};
        `
      : props.$color === "white-black"
      ? css`
          background-color: ${props.theme.white};
          color: #26383b;
          border: 1px solid #26383b;
          &:hover {
            color: #26383b !important;
          }
        `
      : props.$color === "outline"
      ? css`
          background-color: ${props => props.theme.bg1};
          color: ${props => props.theme.darkerGray};
          border: 1px solid ${props => props.theme.primary};
        `
      : props.$color === "outline-black"
      ? css`
          background-color: ${props => props.theme.bg1};
          border: 1px solid ${props => props.theme.black};
          &:hover {
            color: ${props => props.theme.primary};
          }
        `
      : props.$color === "gray"
      ? css`
          background-color: ${props => props.theme.buttonGray};
          & > ${StyledIcon} {
            color: ${props => props.theme.primary};
          }
          &:hover {
            border: 3px solid ${props => props.theme.primary};
          }
        `
      : props.$color === "purple"
      ? css`
          background-color: ${props => props.theme.accentPurple};
          color: ${props => props.theme.white};
          transition: color 0.2s ease-out;
          &:hover {
            color: ${props => props.theme.primary};
          }
        `
      : props.$color === "red"
      ? css`
          background-color: ${props => props.theme.accentRed};
          color: ${props => props.theme.white};
          transition: all 0.2s ease-out;
          transition-property: background-color, color;
          &:hover {
            background-color: ${props => props.theme.accentRed2};
            color: ${props => props.theme.primary};
          }
        `
      : props.$color === "black-white"
      ? css`
          background-color: ${props => props.theme.black};
          color: ${props => props.theme.white};
          &:hover {
            color: ${props => props.theme.primary};
          }
        `
      : props.$color === "routes-v2"
      ? css`
          background-color: #26383b;
          color: #ffffff;
        `
      : props.$color === "green"
      ? css`
          background-color: #daece4;
          color: #2b4346;
        `
      : props.$color === "dark-blue-outline"
      ? css`
          background-color: #ffffff;
          color: #2b4346;
          border: 1.5px solid #2b4346;
        `
      : css`
          background-color: ${props => props.theme.primary};
          color: ${props =>
            props.$color === "yellow-black"
              ? props.theme.fg1
              : props.theme.fg2};
        `}
`

const CircleButton = css`
  ${FilledButton}
  border-radius: 50%;

  ${props =>
    props.$size === "small"
      ? css`
          width: 20px;
          height: 20px;
          & > ${StyledIcon} {
            font-size: 11px;
            line-height: 8px;
          }
        `
      : css`
          width: 30px;
          height: 30px;
          & > ${StyledIcon} {
            font-size: 20px;
          }
        `}

  ${props =>
    props.$color !== "blue"
      ? css`
          box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.08);
        `
      : undefined}

  ${props =>
    !props.$mobileSizeOnly &&
    css`
      @media only screen and (${props => props.theme.screen.small.min}) {
        ${props =>
          props.$size === "small"
            ? css`
                width: 30px;
                height: 30px;
                & > ${StyledIcon} {
                  font-size: 16px;
                  line-height: 15px;
                }
              `
            : css`
                width: 50px;
                height: 50px;
                & > ${StyledIcon} {
                  font-size: 28px;
                }
              `}
      }
    `}
`

const TextButton = css`
  background-color: transparent;
  & > ${StyledIcon} {
    height: 14px;
    line-height: 14px;
    margin: -2px 4px 0 0;
  }

  ${props =>
    props.$size === "small"
      ? css`
          padding: 6px 12px;
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          & > ${StyledIcon} {
            font-size: 16px;
          }
        `
      : // medium and large look the same on mobile
        css`
          padding: 9px 18px 10px;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          & > ${StyledIcon} {
            font-size: 18px;
          }
        `}

  ${props =>
    !props.$mobileSizeOnly &&
    css`
      @media only screen and (${props => props.theme.screen.small.min}) {
        & > ${StyledIcon} {
          display: inline-block;
        }
        ${props =>
          props.$size === "small"
            ? css`
                padding: 7px 16px 8px;
                font-size: 15px;
                line-height: 19px;
                & > ${StyledIcon} {
                  font-size: 19px;
                  height: 15px;
                  line-height: 15px;
                  margin: 0 4px 0 0;
                  &.icon-right {
                    margin: 0 0 0 4px;
                  }
                }
              `
            : props.$size === "large"
            ? css`
                padding: 15px 32px 16px;
                font-weight: 900;
                font-size: 24px;
                line-height: 29px;
                text-decoration: underline;
                & > ${StyledIcon} {
                  text-decoration: none;
                  font-size: 40px;
                  height: 24px;
                  line-height: 24px;
                  margin: 0 18px 0 0;
                  &.icon-right {
                    margin: 0 0 0 18px;
                  }
                }
              `
            : css`
                padding: 11px 24px 12px;
                font-size: 18px;
                line-height: 21px;
                & > ${StyledIcon} {
                  font-size: 24px;
                  height: 18px;
                  line-height: 18px;
                  margin: 0 6px 0 0;
                  &.icon-right {
                    margin: 0 0 0 6px;
                  }
                }
              `}
      `}
`
const PlainButton = css`
  padding: 0;
  background-color: transparent;
  text-decoration: underline;
  & > ${StyledIcon} {
    font-size: 14px;
    height: 1em;
    line-height: 1em;
    margin: -2px 0 0;
    &:not(.icon-right):not(:last-child) {
      margin-right: 4px;
    }
    &.icon-right:not(:first-child) {
      margin-left: 4px;
    }
  }
  &:hover {
    color: ${props => props.theme.primary};
  }
`
const IconButton = css`
  ${props => (props.$color ? FilledButton : "background-color: transparent;")}
  text-decoration: none;
  padding: 10px;
  display: flex;
  & > ${StyledIcon} {
    display: block;
    font-size: 22px;
    height: 1em;
    line-height: 1em;
  }
  &:hover {
    color: ${props => props.theme.primary};
  }
`

const NoButton = css`
  display: none;
`

const CloseButton = css`
  ${FilledButton}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.$size === "small"
      ? css`
          width: 20px;
          height: 20px;
          & > ${StyledIcon} {
            font-size: 11px;
            line-height: 8px;
          }
        `
      : css`
          width: 30px;
          height: 30px;
          & > ${StyledIcon} {
            font-size: 20px;
          }
        `}

  ${props =>
    !props.$mobileSizeOnly &&
    css`
      @media only screen and (${props => props.theme.screen.small.min}) {
        ${props =>
          props.$size === "small"
            ? css`
                width: 30px;
                height: 30px;
                & > ${StyledIcon} {
                  font-size: 16px;
                  line-height: 15px;
                }
              `
            : props.$size === "medium"
            ? css`
                width: 30px;
                height: 30px;
                & > ${StyledIcon} {
                  font-size: 16px;
                }
              `
            : css`
                width: 50px;
                height: 50px;
                & > ${StyledIcon} {
                  font-size: 28px;
                }
              `}
      }
    `}
`

const RectangleButton = css`
  ${FilledButton}
  border-radius: 4px;
  font-size: 12px;
  line-height: 15px;
  ${props =>
    props.$size !== "full-width"
      ? css`
          font-weight: bold;
        `
      : ""}

  & > ${StyledIcon} {
    font-size: 18px;
    height: 15px;
    line-height: 15px;
    margin: 0;
    &:not(.icon-right):not(:last-child) {
      margin-right: 4px;
    }
    &.icon-right:not(:first-child) {
      margin-left: 4px;
    }
  }

  ${props =>
    props.$size === "large"
      ? css`
          padding: 7px 130px 8px;
        `
      : props.$size === "x-small"
      ? css`
          padding: 0;
          width: 76px;
          height: 25px;
        ` // small and medium look the same on mobile
      : css`
          padding: 7px 36px 8px;
          & > ${StyledIcon} {
            ${props =>
              props.$showMobileIcon
                ? css`
                    margin-left: 5px;
                  `
                : css`
                    display: none;
                  `}
          }
        `}

  ${props =>
    props.$size === "full-width-large"
      ? css`
          width: 100%;
          padding: 15px 20px;
          font-size: 20px;
        `
      : props.$size === "full-width"
      ? css`
          width: 100%;
          padding: 9px 0;
          font-size: 14px;
        `
      : undefined}

  ${props =>
    !props.$mobileSizeOnly &&
    css`
      @media only screen and (${props => props.theme.screen.small.min}) {
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;

        ${props =>
          props.$size === "small"
            ? css`
                padding: 12px ${props.$color === "outline" ? "28px" : "58px"};
              `
            : props.$size === "large"
            ? css`
                padding: 12px 170px;
              `
            : css`
                padding: 12px 70px;
              `}

        & > ${StyledIcon} {
          display: inline-block;
          margin: -2px 4px 0 0;
          &.icon-right {
            margin: -2px 0 0 4px;
          }

          ${props =>
            props.$size === "large"
              ? css`
                  font-size: 22px;
                `
              : props.$size === "full-width-large"
              ? css`
                  font-size: 26px;
                `
              : css`
                  font-size: 20px;
                `}
        }
      }
    `}
`
const MapV2Button = css`
  ${FilledButton}
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  border-radius: 6px;
  padding: 5px 15px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.08);
`

const RoundedButton = css`
  ${FilledButton}

  & > ${StyledIcon} {
    height: 14px;
    line-height: 14px;
    margin: -2px 4px 0 0;
    &.icon-right {
      margin: -2px 0 0 4px;
    }
  }

  ${props =>
    props.$size === "small"
      ? css`
          border-radius: 13px;
          padding: 6px 28px;
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.08);
          & > ${StyledIcon} {
            font-size: 16px;
          }
        `
      : // medium and large look the same on mobile
        css`
          border-radius: 22px;
          padding: 9px 68px 10px;
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          & > ${StyledIcon} {
            font-size: 18px;
          }
        `}

  ${props =>
    props.$size === "large"
      ? css``
      : css`
          & > ${StyledIcon} {
            ${props =>
              props.$showMobileIcon
                ? css`
                    margin-left: 5px;
                  `
                : css`
                    display: none;
                  `}
          }
        `}

  ${props =>
    !props.$mobileSizeOnly &&
    css`
      @media only screen and (${props => props.theme.screen.small.min}) {
        & > ${StyledIcon} {
          display: inline-block;
        }
        ${props =>
          props.$size === "small"
            ? css`
                border-radius: 17px;
                font-size: 15px;
                line-height: 19px;
                & > ${StyledIcon} {
                  font-size: 19px;
                  height: 15px;
                  line-height: 15px;
                  margin: 0 4px 0 0;
                  &.icon-right {
                    margin: 0 0 0 4px;
                  }
                }
              `
            : props.$size === "large"
            ? css`
                border-radius: 45px;
                padding: 15px 140px 16px;
                font-weight: 900;
                font-size: 24px;
                line-height: 29px;
                text-decoration: underline;
                & > ${StyledIcon} {
                  text-decoration: none;
                  font-size: 40px;
                  height: 24px;
                  line-height: 24px;
                  margin: 0 20px 0 0;
                  &.icon-right {
                    margin: 0 0 0 20px;
                  }
                }
              `
            : css`
                border-radius: 22px;
                padding: 11px 115px 12px;
                font-size: 18px;
                line-height: 21px;
                & > ${StyledIcon} {
                  font-size: 24px;
                  height: 18px;
                  line-height: 18px;
                  margin: 0 6px 0 0;
                  &.icon-right {
                    margin: 0 0 0 6px;
                  }
                }
              `}
      }
    `}
`
const ButtonStyle = css`
  cursor: pointer;
  border: 0px none;
  text-align: center;
  max-width: 100%;
  display: inline-block;

  &:disabled {
    cursor: not-allowed;
  }

  ${props =>
    !props.$multiline
      ? css`
          white-space: nowrap;
        `
      : ""}

  ${props =>
    props.$variant === "circle"
      ? CircleButton
      : props.$variant === "rounded"
      ? RoundedButton
      : props.$variant === "mapV2"
      ? MapV2Button
      : props.$variant === "text"
      ? TextButton
      : props.$variant === "plain"
      ? PlainButton
      : props.$variant === "none"
      ? NoButton
      : props.$variant === "close"
      ? CloseButton
      : props.$variant === "icon"
      ? IconButton
      : RectangleButton}
`
const StyledButton = styled.button`
  ${ButtonStyle}
`
const StyledLink = styled(Link)`
  ${ButtonStyle}
  text-decoration: none;
  ${props =>
    props.$size === "x-small" &&
    css`
      line-height: 25px;
    `}
  ${props =>
    !["purple", "red"].includes(props.$color)
      ? css`
          &:hover {
            color: ${props => props.theme.fg2};
          }
        `
      : ""}
`

export const ButtonGroupStyle = css`
  display: flex;
  justify-content: center;
  margin: 0;
  & > * {
    flex: 0 0 auto;
    margin: 7px;
  }
  & .button {
    ${ButtonStyle}
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > * {
      margin: 10px;
    }
  }
`
export const ButtonGroup = styled.div`
  ${ButtonGroupStyle}
`

const Button = ({
  children,
  id,
  name,
  icon,
  iconRight,
  multiline,
  color,
  size,
  variant,
  to,
  href,
  taLink,
  gps,
  onClick,
  generateDataLayer,
  mobileSizeOnly,
  showMobileIcon,
  ...props
}) => {
  const linkProps = to || href ? { to, href, gps, taLink } : null
  const addProps = {}
  const styleProps = {
    $color: color,
    $size: size,
    $variant: variant,
    $multiline: multiline,
    $mobileSizeOnly: mobileSizeOnly,
    $showMobileIcon: showMobileIcon,
  }
  const renderChildren = () => (
    <>
      {icon && <StyledIcon glyph={icon} />}
      {variant !== "circle" ? children : undefined}
      {iconRight && <StyledIcon glyph={iconRight} className="icon-right" />}
    </>
  )
  if (id) {
    addProps.id = id
  }
  if (generateDataLayer) {
    addProps.onClick = event => {
      const dataLayer = {
        event: "10a.button.click",
      }
      if (id) {
        dataLayer["button-id"] = id
      }
      if (name) {
        dataLayer["button-name"] = name
      }
      if (linkProps) {
        dataLayer["button-target"] = linkProps.to || linkProps.href
      }
      if (typeof generateDataLayer === "function") {
        const addDataLayer = generateDataLayer()
        if (addDataLayer) {
          for (const key in addDataLayer) {
            dataLayer[key] = addDataLayer[key]
          }
        }
      }
      GTM.dataLayerPush(dataLayer)
      if (onClick) {
        return onClick(event)
      }
    }
  } else if (onClick) {
    addProps.onClick = onClick
  }
  return linkProps ? (
    <StyledLink {...addProps} {...linkProps} {...styleProps} {...props}>
      {renderChildren()}
    </StyledLink>
  ) : (
    <StyledButton type="button" {...addProps} {...styleProps} {...props}>
      {renderChildren()}
    </StyledButton>
  )
}
Button.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  iconRight: PropTypes.string,
  multiline: PropTypes.bool,
  color: PropTypes.oneOf([
    "yellow",
    "blue",
    "yellow-black",
    "outline",
    "outline-black",
    "gray",
    "purple",
    "red",
    "white-gray",
    "white-black",
    "routes-v2",
    "dark-blue-outline",
    "green",
  ]),
  size: PropTypes.oneOf([
    "x-small",
    "small",
    "medium",
    "large",
    "full-width-large",
    "full-width",
  ]),
  variant: PropTypes.oneOf([
    "circle",
    "rectangle",
    "rounded",
    "text",
    "plain",
    "icon",
    "mapV2",
    "none",
    "close",
  ]),
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  taLink: PropTypes.bool,
  gps: PropTypes.oneOfType([
    PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({ lat: PropTypes.number, lon: PropTypes.number })
    ),
  ]),
  generateDataLayer: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  mobileSizeOnly: PropTypes.bool,
  showMobileIcon: PropTypes.bool,
}
export default Button
