import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ImagePropTypes } from "@src/components/core-image"
import { SOCIAL_MEDIA } from "@src/utils/constants/social-media"
import { SITE_NAME } from "@src/utils/constants"
// import cookieScript from "../../static/js/cookieScript.js"

const locale = {
  lang: "en",
  region: "en_US",
}

const chooseImage = image => {
  if (!image) {
    return
  }
  const result = {
    url: image.url,
    alt: image.alt,
  }
  if (image.sizes && image.sizes.length && image.sizes[0].url) {
    result.url = image.sizes[0].url
    if (image.sizes[0].width && image.sizes[0].height) {
      result.width = image.sizes[0].width
      result.height = image.sizes[0].height
    }
  } else if (image.width && image.height) {
    result.width = image.width
    result.height = image.height
  }
  return result
}

const PageHelmet = ({ children, title, meta, structuredData }) => {
  const {
    canonical,
    prev,
    next,
    robots,
    description,
    image: imageSource,
    openGraph,
    twitter,
  } = meta || {}
  const isArticle = openGraph && openGraph.type === "article"
  const image = chooseImage(imageSource)
  const hasImage = image && image.url
  const hasStructuredData = !!structuredData
  return (
    <Helmet>
      <html lang={locale.lang} prefix="og: http://ogp.me/ns#" />
      <title>{title || "10Adventures"}</title>
      <link
        rel="sitemap"
        type="application/xml"
        title="Sitemap"
        href="/sitemap-index.xml"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png?v=1"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png?v=1"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png?v=1"
      />
      <link rel="manifest" href="/site.webmanifest?v=1" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg?v=1" color="#fec51b" />
      <link rel="shortcut icon" href="/favicon.ico?v=1" />
      <meta name="msapplication-TileColor" content="#fec51b" />
      <meta name="theme-color" content="#ffffff" />
      {robots && robots.length && (
        <meta name="robots" content={robots.join(",")} />
      )}
      {description && <meta name="description" content={description} />}
      {openGraph && <meta property="og:locale" content={locale.region} />}
      <meta property="og:type" content={isArticle ? "article" : "website"} />
      {openGraph && canonical && <meta property="og:url" content={canonical} />}
      {isArticle && (
        <meta
          property="article:publisher"
          content={SOCIAL_MEDIA.facebook.link}
        />
      )}
      {isArticle && openGraph.modifiedTime && (
        <meta
          property="article:modified_time"
          content={openGraph.modifiedTime}
        />
      )}
      {openGraph && (
        <meta property="og:title" content={openGraph.title || title} />
      )}
      {openGraph && description && (
        <meta property="og:description" content={description} />
      )}
      {openGraph && <meta property="og:site_name" content={SITE_NAME} />}
      {openGraph && hasImage && (
        <meta property="og:image" content={image.url} />
      )}
      {openGraph && hasImage && image.width && image.height && (
        <meta property="og:image:width" content={image.width} />
      )}
      {openGraph && hasImage && image.width && image.height && (
        <meta property="og:image:height" content={image.height} />
      )}
      {openGraph && hasImage && image.alt && (
        <meta property="og:image:alt" content={image.alt} />
      )}
      {twitter && (
        <meta
          name="twitter:card"
          content={hasImage ? "summary_large_image" : "summary"}
        />
      )}
      {twitter && (
        <meta name="twitter:site" content={SOCIAL_MEDIA.twitter.tag} />
      )}
      {canonical && <link rel="canonical" href={canonical} />}
      {prev && <link rel="prev" href={prev} />}
      {next && <link rel="next" href={next} />}

      {hasStructuredData &&
        (Array.isArray(structuredData) && structuredData.length ? (
          structuredData.map((strucData, index) => (
            <script key={index} type="application/ld+json">
              {JSON.stringify(strucData)}
            </script>
          ))
        ) : (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        ))}
      {/* eslint-disable react/no-unknown-property */}
      <link
        rel="preload"
        as="font"
        href="/icons/icons-10a/fonts/icons-10a.woff2?xhffew"
        type="font/woff2"
        crossorigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/icons/icons-10a-sp/fonts/icons-10a-sp.woff?dxy5hr"
        type="font/woff"
        crossorigin="anonymous"
      />
      {/* eslint-enable react/no-unknown-property */}
      <link defer rel="preconnect" href="https://www.googletagmanager.com" />
      {children}
    </Helmet>
  )
}
export default PageHelmet
PageHelmet.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  meta: PropTypes.shape({
    canonical: PropTypes.string,
    prev: PropTypes.string,
    next: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      alt: PropTypes.string,
    }),
    openGraph: PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
      modifiedTime: PropTypes.string,
    }),
    twitter: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          data: PropTypes.string,
        })
      ),
    }),
    robots: PropTypes.arrayOf(function (
      propValue,
      key,
      componentName,
      location,
      propFullName
    ) {
      const checkValue = propValue[key].toLowerCase()
      if (
        ![
          "index",
          "noindex",
          "follow",
          "nofollow",
          "noimageindex",
          "none",
          "noarchive",
          "nocache",
          "nosnippet",
        ].includes(checkValue) &&
        !/unavailable_after: \d{4}-\d{2}-\d{2}/.test(checkValue)
      ) {
        return new Error(
          "Invalid prop `" +
            propFullName +
            "` supplied to" +
            " `" +
            componentName +
            "`. Validation failed."
        )
      }
    }),
  }),
  structuredData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
}
export const PageSeoPropTypes = {
  title: PropTypes.string,
  opengraphTitle: PropTypes.string,
  metaDesc: PropTypes.string,
  metaRobotsNofollow: PropTypes.string,
  metaRobotsNoindex: PropTypes.string,
  opengraphModifiedTime: PropTypes.string,
  seoImage: PropTypes.shape({
    ...ImagePropTypes,
    alt: PropTypes.string,
    sizes: PropTypes.arrayOf(
      PropTypes.shape({
        ...ImagePropTypes,
      })
    ),
  }),
}
export const PageSeoQueries = graphql`
  fragment WpPostSeoQuery on WpPostTypeSEO {
    title
    opengraphTitle
    metaDesc
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphModifiedTime
    seoImage {
      url
      width
      height
      alt
      sizes {
        url
        width
        height
      }
    }
  }
  fragment WpTermSeoQuery on WpTaxonomySEO {
    title: opengraphTitle
    metaDesc
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphModifiedTime
    seoImage {
      url
      width
      height
      alt
      sizes {
        url
        width
        height
      }
    }
  }
  fragment TenAdventuresGQL_PostSEO on TenAdventuresGQL_PostTypeSEO {
    title: opengraphTitle
    metaDesc
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphModifiedTime
    seoImage {
      url
      width
      height
      alt
      sizes {
        url
        width
        height
      }
    }
  }
  fragment TenAdventuresGQL_TermSEO on TenAdventuresGQL_TaxonomySEO {
    title: opengraphTitle
    metaDesc
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphModifiedTime
    seoImage {
      url
      width
      height
      alt
      sizes {
        url
        width
        height
      }
    }
  }
  fragment TenToursGQL_PostSEO on TenToursGQL_PostTypeSEO {
    title: opengraphTitle
    metaDesc
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphModifiedTime
    seoImage {
      url
      width
      height
      alt
      sizes {
        url
        width
        height
      }
    }
  }
  fragment TenToursGQL_TermSEO on TenToursGQL_TaxonomySEO {
    title: opengraphTitle
    metaDesc
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphModifiedTime
    seoImage {
      url
      width
      height
      alt
      sizes {
        url
        width
        height
      }
    }
  }
`
