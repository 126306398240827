import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { LocaleContext } from "@src/context/locale-context"
import {
  convertUnit,
  getUnitEquivalent,
  SYSTEM_NAMES,
} from "@src/utils/distances"
import { formatNumber } from "@src/utils/numbers"
const DistanceStyled = styled.span`
  white-space: nowrap;
`
const getDistanceUnit = unit => {
  const distanceUnit = {
    unit,
    format: function (value, decimals) {
      return `${formatNumber(value, decimals)} ${this.unit}`
    },
  }
  return distanceUnit
}
const Distance = ({
  value: fromValue,
  unit: fromUnit,
  decimals,
  targetSystem,
  className,
}) => {
  if (typeof fromValue === "string" || fromValue instanceof String) {
    fromValue = parseFloat(fromValue)
  }
  return (
    <LocaleContext.Consumer>
      {({ units: toSystem }) => {
        const toUnit = getUnitEquivalent(fromUnit, targetSystem || toSystem)
        const value = convertUnit(fromValue, fromUnit, toUnit)
        const distanceUnit = getDistanceUnit(toUnit)
        return (
          <DistanceStyled className={className}>
            {isNaN(value)
              ? `${fromValue || ""}${fromValue && fromUnit ? " " : ""}${
                  fromUnit || ""
                }`
              : distanceUnit.format(value, decimals)}
          </DistanceStyled>
        )
      }}
    </LocaleContext.Consumer>
  )
}
Distance.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.oneOf(["m", "km", "ft", "mi"]),
  decimals: PropTypes.number,
  targetSystem: PropTypes.oneOf([SYSTEM_NAMES.imperial, SYSTEM_NAMES.metric]),
  className: PropTypes.string,
}
Distance.defaultProps = {
  decimals: 0,
}
export default Distance
