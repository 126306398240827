import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Link from "@src/components/core-link"
import Icon from "@src/components/core-icon"

const StyledArrowIcon = styled(Icon)`
  &.icon {
    font-size: 10px;
    transition: all 0.2s ease-in-out;
  }
  ${({ active }) =>
    active &&
    css`
      &.icon {
        transform: rotate(180deg);
      }
    `}

  @media only screen and (${props => props.theme.screen.medium.min}) {
    &.icon {
      display: none;
    }
  }
`
const NavItems = styled.ul`
  list-style-type: none;

  & ${Link} {
    white-space: nowrap;
  }

  display: flex;
  flex-direction: column;
  width: 100%;

  & > li {
    padding: 20px 30px 20px 16px;
    border-top: solid 1px ${props => props.theme.lighterGray};
    &:last-child {
      border-bottom: solid 1px ${props => props.theme.lighterGray};
    }
    & > a {
      font-weight: 500;
    }
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    flex: 1 1 auto;
    flex-direction: row;
    width: unset;
    & > li,
    & > li:last-child {
      flex: 0 1 17%;
      margin: 0 10px;
      justify-content: unset;
      padding: unset;
      display: unset;
      border: none;
    }
    & > li > .links {
      display: block;
      margin-top: 22px;
      & > a {
        line-height: 38px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    justify-content: flex-end;
  }
`
const NavLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.white};
  & > span {
    color: ${props => props.theme.primary};
    font-weight: 500;
    text-transform: uppercase;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    justify-content: unset;
    align-items: unset;
    display: unset;
  }
`
const ListItem = styled.li`
  & > .links {
    transition: max-height 0.5s linear;
    & > a {
      display: none;
      color: ${props => props.theme.white};
      text-align: left;
      &:hover {
        color: ${props => props.theme.primary};
      }
    }
  }

  @media only screen and (${props => props.theme.screen.small.max}) {
    & > .links {
      overflow: hidden;
      max-height: 0px;
    }

    & > .links > a {
      font-size: 14px;
      margin-top: 5px;
      &:first-child {
        margin-top: 20px;
      }
    }

    ${({ hasChildren }) =>
      hasChildren &&
      `
        display: block;
    `}

    ${({ active }) =>
      active &&
      css`
        & > .links {
          transition: max-height 0.5s linear;
          max-height: 500px !important;
        }

        & > .links > a {
          display: block;
        }
      `}
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    & > .links,
    & > .links > a {
      display: block;
    }
  }
`

const NavFooter = ({ items, children, className }) => {
  const [expandedItem, setExpandedItem] = useState(null)

  const handleExpand = id => {
    if (expandedItem === id) return setExpandedItem(null)
    setExpandedItem(id)
  }

  const renderItems = items => {
    return items
      ? items.map(item => {
          const childItems = item.items ? item.items : undefined
          const hasChildren = childItems && childItems.length > 0
          return (
            <ListItem
              key={`${item.id}`}
              onClick={() => handleExpand(item.id)}
              active={expandedItem === item.id}
              hasChildren={hasChildren}
            >
              <NavLabelContainer>
                <span>{item.label}</span>
                <StyledArrowIcon
                  glyph="chevron-bottom"
                  active={expandedItem === item.id}
                />
              </NavLabelContainer>
              {childItems && childItems.length ? (
                <div className="links">
                  {childItems.map(item => (
                    <Link key={item.id} to={item.url}>
                      {item.label}
                    </Link>
                  ))}
                </div>
              ) : undefined}
            </ListItem>
          )
        })
      : undefined
  }
  return (
    <NavItems className={className}>
      {renderItems(items)}
      {children}
    </NavItems>
  )
}
NavFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      parentId: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          parentId: PropTypes.string,
          label: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    })
  ),
}
export default NavFooter
