import React, { useContext, useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { debounce } from "debounce"
import SearchService from "@src/services/content/search"
import { LayerContext } from "@src/context/layer-context"
import Button from "@src/components/core-button"
import Link from "@src/components/core-link"
import InputText from "@src/components/input-text"
import NavPaginate from "@src/components/nav-content-paginate"
const StyledInput = styled(InputText)`
  font-size: 16px;
  line-height: 17px;
  font-weight: 500;
  height: 44px;
  padding: 0 20px 0 40px;

  & + .icon {
    font-size: 20px;
    line-height: 20px;
    padding: 12px 10px;
  }
`
const SearchToggle = styled(Button)`
  & .icon {
  }
`
const SearchResults = styled.div`
  position: absolute;
  background-color: ${props => props.theme.bg1};
  border: 1px solid ${props => props.theme.lightGray};
  padding: 10px 20px;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: column;
  & div {
    flex: 0 0 auto;
  }
  & ul {
    flex: 0 1 auto;
    overflow-y: auto;
    list-style-type: none;
    & > li {
      padding: 10px 0;
    }
  }
`
const StyledPaginate = styled(NavPaginate)`
  flex: 0 0 auto;
`
const SearchWrapper = styled.div`
  position: relative;
`
const SiteSearch = ({ className }) => {
  const Layers = useContext(LayerContext)
  const [isOpen, setIsOpen] = useState(false)
  const [results, setResults] = useState(null)
  const [searching, setSearching] = useState(null)

  const search = useRef({
    lastQuery: null,
    lastQueryPage: null,
    query: (query, page) => {
      page = page && parseInt(page) > 1 ? page : 1
      search.current.lastQuery = query
      search.current.lastQueryPage = page
      if (!query) {
        search.current.lastQueryPage = null
        setSearching(false)
        setResults(null)
        setIsOpen(false)
        return
      }
      setIsOpen(true)
      setSearching(true)
      SearchService.searchWebsite(query, page)
        .then(result => {
          if (
            result.config?.params?.query !== search.current.lastQuery ||
            result.config?.params?.page !== search.current.lastQueryPage
          ) {
            return
          }
          setResults(result.data)
        })
        .catch(error => console.log("Error in searchWebsite request: ", error))
        .finally(() => {
          if (
            query === search.current.lastQuery &&
            page === search.current.lastQueryPage
          ) {
            setSearching(false)
          }
        })
    },
    queryDebounce: debounce(
      (query, page) => search.current.query(query, page),
      250
    ),
  })
  useEffect(() => {
    if (isOpen) {
      Layers.open({ layerId: "header-search", onClose: handleLayerClosed })
    } else {
      Layers.close("header-search")
    }
  }, [isOpen, Layers])
  const handleLayerClosed = () => {
    setIsOpen(false)
  }
  const handleButtonClick = event => {
    event.preventDefault()
    setIsOpen(!isOpen)
  }
  const handleSearchChange = event => {
    search.current.queryDebounce(event.target.value)
  }
  const handleSearchFocus = event => {
    if (search.current.lastQuery !== event.target.value) {
      if (event.target.value) {
        search.current.query(event.target.value)
      } else {
        search.current.lastQuery = ""
        setSearching(false)
        setResults(null)
      }
    } else if (search.current.lastQuery) {
      setIsOpen(true)
    }
  }
  const handlePageChange = page => {
    search.current.query(search.current.lastQuery, page)
  }
  return (
    <>
      <SearchWrapper
        className={`${className ? className : ""}${isOpen ? " active" : ""}`}
      >
        <StyledInput
          icon="search"
          placeholder="Where do you want to go?"
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
        />
        {isOpen && (results || searching) ? (
          <SearchResults className="results">
            {results && results.items ? (
              results.items.length ? (
                <>
                  <ul>
                    {results.items.map((item, index) => (
                      <li key={`search-results-${index}`}>
                        <Link to={item.url}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                  {results.total && results.total > results.perPage && (
                    <StyledPaginate
                      current={results.page}
                      total={Math.ceil(results.total / results.perPage)}
                      setPage={handlePageChange}
                    />
                  )}
                </>
              ) : (
                <span>No results found.</span>
              )
            ) : searching ? (
              <span>Searching...</span>
            ) : null}
          </SearchResults>
        ) : undefined}
      </SearchWrapper>
      <SearchToggle
        variant="icon"
        icon="search"
        active={isOpen}
        onClick={event => handleButtonClick(event)}
        aria-label="Search 10Adventures.com"
        aria-expanded={isOpen}
      />
    </>
  )
}
SiteSearch.propTypes = {
  className: PropTypes.string,
}
export default SiteSearch
