import React from "react"
import PropTypes from "prop-types"
import { FormContext, FieldController } from "@src/components/form"
import InputWrapper from "@src/components/input-wrapper"
const FormField = (
  Input,
  {
    controlled,
    labelIdSuffix,
    labelPassthrough,
    initEvent,
    isNumber,
    isCheckable,
  } = {}
) => {
  const FormInputField = ({
    id,
    name,
    value,
    label,
    labelSuffix,
    collapsible,
    validation,
    description,
    required,
    renderFieldIntro,
    variant: fieldVariant,
    className,
    children,
    ...props
  }) => {
    const valueFromEvent = event => {
      if (event && event.target) {
        return event.target.value
      }
      return event
    }
    return (
      <FormContext>
        {({ register, control, formState, variant: formVariant }) => {
          const error =
            formState &&
            formState.errors[name] &&
            formState.errors[name].message
              ? formState.errors[name].message
              : undefined
          const applyVariant = fieldVariant || formVariant
          const fieldClasses = ["form-field"]
          if (className) {
            fieldClasses.push(className)
          }
          return (
            <InputWrapper
              className={fieldClasses.join(" ")}
              forId={id}
              required={required}
              error={error}
              label={label}
              labelSuffix={labelSuffix}
              labelIdSuffix={labelIdSuffix}
              labelPassthrough={labelPassthrough}
              description={description}
              collapsible={collapsible}
              variant={applyVariant}
            >
              {renderFieldIntro ? renderFieldIntro() : null}
              {controlled && control ? (
                <FieldController
                  control={control}
                  name={name}
                  defaultValue={value || (isNumber ? 0 : value)}
                  rules={validation}
                  render={({ name, value, onChange, onBlur }) => {
                    const addProps = {}
                    if (initEvent) {
                      addProps.onInit = event => {
                        const value = valueFromEvent(event)
                        onChange(value)
                      }
                    }
                    if (isCheckable && value) {
                      addProps.checked = true
                    }
                    return (
                      <Input
                        {...props}
                        id={id}
                        name={name}
                        value={value}
                        label={label}
                        variant={applyVariant}
                        className="field-input"
                        onChange={event => {
                          const value = valueFromEvent(event)
                          onChange(value)
                          if (props.onChange) {
                            props.onChange(value)
                          }
                        }}
                        onBlur={onBlur}
                        {...addProps}
                      />
                    )
                  }}
                />
              ) : (
                <Input
                  {...props}
                  id={id}
                  name={name}
                  value={value}
                  label={label}
                  variant={applyVariant}
                  register={register}
                  validation={validation}
                  className="field-input"
                  onChange={event => {
                    if (props.onChange) {
                      props.onChange(valueFromEvent(event))
                    }
                  }}
                />
              )}
              {children}
            </InputWrapper>
          )
        }}
      </FormContext>
    )
  }
  FormInputField.displayName = "FormField"
  FormInputField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    validation: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    collapsible: PropTypes.bool,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    required: PropTypes.bool,
    renderFieldIntro: PropTypes.func,
    onChange: PropTypes.func,
    variant: PropTypes.oneOf(["blue", "yellow"]),
    className: PropTypes.string,
    children: PropTypes.node,
  }
  return FormInputField
}
export const FormFieldControlled = (Input, config = {}) =>
  FormField(Input, { controlled: true, ...config })
export default FormField
