import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SiteFooter from "@src/components/site-footer"
import { SOCIAL_MEDIA as socialMediaLinks } from "@src/utils/constants/social-media"
const Footer = () => {
  const { privacyMenu, footer } = useStaticQuery(graphql`
    query Footer {
      privacyMenu: wpMenu(slug: { eq: "nav-privacy" }) {
        name
        slug
        items: menuItems {
          nodes {
            label
            url
            id
          }
        }
      }
      footer: wpMenu(slug: { eq: "nav-footer" }) {
        name
        slug
        items: menuItems {
          nodes {
            label
            url
            id
            parentId
          }
        }
      }
    }
  `)
  return (
    <SiteFooter
      legalLinks={privacyMenu?.items?.nodes}
      footerLinks={footer?.items?.nodes}
      socialMediaLinks={socialMediaLinks}
    />
  )
}
export default Footer
