import { formatNumber, roundToIncrement } from "@src/utils/numbers"
import { CURRENCY_ROUNDING_INCREMENTS } from "@src/utils/constants/currency"

export const getCurrency = code => {
  if (!code) {
    return undefined
  }
  const currency = {
    code,
    symbol: "",
    decimals: 2,
    format: function (
      amount,
      { showAbbr, showSymbol, showDecimals, applyRounding, separator } = {
        showSymbol: true,
        showDecimals: true,
        separator: ",",
      }
    ) {
      return `${
        showAbbr
          ? this.code.substr(0, this.symbol && showSymbol ? 2 : 3) + " "
          : ""
      }${showSymbol ? this.symbol : ""}${formatNumber(
        applyRounding
          ? roundToIncrement(amount, CURRENCY_ROUNDING_INCREMENTS)
          : amount,
        showDecimals ? this.decimals : 0,
        separator
      )}`
    },
  }
  switch (code) {
    case "CAD":
    case "USD":
    case "AUD":
    case "NZD":
      currency.symbol = "$"
      break
    case "GBP":
      currency.symbol = "£"
      break
    case "EUR":
      currency.symbol = "€"
      break
    case "JPY":
      currency.symbol = "¥"
      currency.decimals = 0
      break
    case "ISK":
      currency.decimals = 0
      break
  }
  return currency
}
