import React from "react"
import PropTypes from "prop-types"
import styled, { css, createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import icons10aStyles from "!!raw-loader!../../static/icons/icons-10a/style.css"
import icons10aSpStyles from "!!raw-loader!../../static/icons/icons-10a-sp/style.css"
import icons10aV2Styles from "!!raw-loader!../../static/icons/icons-10a-v2/style.css"
import typographyStyles from "@src/styles/typography"
import elementStyles from "@src/styles/elements"
import Header from "@src/container/header"
import Footer from "@src/container/footer"
import SiteSeoLinks from "@src/components/site-seo-links"
import BottomNewsletterSubscriptionBanner from "@src/components/form-newsletter-subscription"
const GlobalStyle = createGlobalStyle`
  ${normalize}
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  ${typographyStyles}
  ${icons10aStyles}
  ${icons10aSpStyles}
  ${icons10aV2Styles}
  ${elementStyles}
`

const Main = styled.main`
  position: relative;
  padding-bottom: 30px;

  ${props =>
    props.variant === "gray"
      ? css`
          background-color: ${props => props.theme.paleGray};
          & section:not(.blue):not(.contained):not(.full-width),
          & section.contained > div {
            padding: 20px;
            margin-bottom: 20px;
            & > h2,
            & > h3 {
              margin-top: 0;
            }
          }
          & section:not(.blue):not(.contained):not(.transparent),
          & section.contained > div {
            background-color: ${props => props.theme.bg1};
          }
        `
      : undefined}

  @media only screen and (${props => props.theme.header.small.min}) {
    padding-bottom: 50px;
  }
`

const PrimaryLayout = ({ children, variant, bottomSeoData, showSeo }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Main variant={variant}>{children}</Main>
      {showSeo && <SiteSeoLinks seoData={bottomSeoData} />}
      <BottomNewsletterSubscriptionBanner variant="banner" />
      <Footer />
    </>
  )
}
PrimaryLayout.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(["default", "gray"]),
  showSeo: PropTypes.bool,
  bottomSeoData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      backlink: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string, link: PropTypes.string })
      ),
    })
  ),
  loginRequired: PropTypes.bool,
}

PrimaryLayout.defaultProps = {
  showSeo: true,
}
export default PrimaryLayout
