import { css } from "styled-components"

export const ResponsiveContainer = css`
  display: grid;
  & > * {
    grid-column: 2 / -2;
  }
  grid-template-columns: minmax(1rem, 1fr) minmax(0, calc(100vw - 20px)) minmax(
      1rem,
      1fr
    );

  @media only screen and (${props => props.theme.screen.medium.min}) {
    grid-template-columns: minmax(1rem, 1fr) ${props =>
        props.theme.screen.medium.contentMaxWidths}px minmax(1rem, 1fr);
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    grid-template-columns: minmax(1rem, 1fr) ${props =>
        props.theme.screen.large.contentMaxWidths}px minmax(1rem, 1fr);
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    grid-template-columns: minmax(1rem, 1fr) ${props =>
        props.theme.screen.desktop.contentMaxWidths}px minmax(1rem, 1fr);
  }
`
export const ResponsiveFullWidth = css`
  grid-column: 1 / -1;
`
export default ResponsiveContainer
