import { useStaticQuery, graphql } from "gatsby"

export const useFooterBG = () => {
  const {
    tenAdventures: { footerBg: bgImage },
  } = useStaticQuery(graphql`
    query FooterBG {
      tenAdventures {
        footerBg: responsiveImage(id: "bottom-nav-background", idType: SLUG) {
          image {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
      }
    }
  `)
  return { bgImage }
}
