import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Link from "@src/components/core-link"
import Icon from "@src/components/core-icon"

const SeoBottomTile = styled.div`
  & > a.back-arrow {
    width: fit-content;
    margin: 16px 0;
    color: ${props => props.theme.primary};
    :hover {
      background-color: ${props => props.theme.primary};
      color: ${props => props.theme.black};
    }
    // Needed to center icon
    & > .icon {
      height: 18px;
    }
  }
  // 1 column
  & > div.link-list {
    display: flex;
    flex-direction: column;
    & > a {
      :not(:first-of-type) {
        margin-top: 10px;
      }
    }
  }
  // 2 columns
  @media only screen and (${props => props.theme.screen.tablet.min}) {
    & > div.link-list {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 10%;
      & > * {
        width: calc(45%);
      }
      & a:nth-child(-n + 2) {
        margin-top: 0px;
      }
    }
  }
  // 3 columns
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > div.link-list {
      & > * {
        width: calc(26.66%);
      }
      & a:nth-child(-n + 3) {
        margin-top: 0px;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    & > div.link-list {
      & > * {
        width: calc(17.5%);
      }
      & a:nth-child(-n + 4) {
        margin-top: 0px;
      }
    }
  }
`

const BottomSeoTile = ({ data }) => {
  const { backlink, items } = data
  return (
    <SeoBottomTile>
      {backlink ? (
        <Link to={backlink.link} className="back-arrow" variant="compact">
          <Icon glyph="v2-arrow-left" iconFontSize="16px" />
          {` ${backlink.text}`}
        </Link>
      ) : null}
      <div className="link-list">
        {items.map(item => (
          <Link key={item.name} to={item.link} variant="compact">
            {item.name}
          </Link>
        ))}
      </div>
    </SeoBottomTile>
  )
}

BottomSeoTile.propTypes = {
  data: PropTypes.shape({
    backlink: PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, link: PropTypes.string })
    ),
  }),
}

export default BottomSeoTile
