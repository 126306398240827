import React, { useCallback } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import {
  useForm,
  FormProvider,
  useFormContext,
  Controller,
} from "react-hook-form"
export { useFormContext }
import GTM from "@src/services/gtm"
import NetlifyForms from "@src/services/netlify/forms"

export const FormActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  & > button {
    margin-bottom: 20px;
  }
  & > p {
    margin-bottom: 20px;
  }

  ${props =>
    props.submitting
      ? css`
          & button[type="submit"]:disabled {
            cursor: wait;
          }
        `
      : undefined}

  & .form-error,
  & .form-success,
  & .form-info {
    font-weight: strong;
    margin-top: 20px;
  }
  & .form-error {
    color: ${props => props.theme.status.error};
  }
  & .form-success {
    color: ${props => props.theme.status.success};
  }
  & .form-info {
    color: ${props => props.theme.status.info};
  }

  &.horizontal {
    @media only screen and (${props => props.theme.screen.small.min}) {
      flex-flow: row wrap;
      justify-content: space-between;
      & > * {
        flex: 0 0 auto;
      }
      & p {
        flex: 1 0 100%;
      }
    }
  }
`
export const FormGroup = styled.div`
  display: flex;
  ${props =>
    props.column
      ? css`
          flex-direction: column;
          align-items: flex-start;
          & > * {
            margin: 0 0 10px;
          }
        `
      : css`
          flex-flow: row wrap;
          justify-content: flex-start;
          margin: 0 -5px;
          & > * {
            margin: 0 5px 10px;
          }
        `}
  & p {
    flex: 1 0 100%;
  }
  & > label {
    flex: 0 0 100%;
    font-weight: bold;
    margin-bottom: 10px;
  }
`

export const FieldController = Controller

export const FormContext = ({ children }) => {
  const methods = useFormContext()
  return children({ ...methods })
}

const Form = ({
  className,
  children,
  name,
  defaultValues,
  formatData,
  generateDataLayer,
  onSubmit,
  onSubmitError,
  onSubmitSuccess,
  variant,
  netlify,
  ...props
}) => {
  const methods = useForm({ defaultValues })
  const formProps = { ...props }
  if (netlify) {
    formProps["method"] = "POST"
    formProps["data-netlify"] = true
  }
  const handleSubmit = useCallback(
    async data => {
      const resetForm = methods.reset
      try {
        const submitData = formatData ? formatData(data) : data
        const result = {}
        if (onSubmit && typeof onSubmit === "function") {
          result.submit = await onSubmit(submitData, resetForm)
        }
        if (netlify) {
          result.netlify = await NetlifyForms.submitForm(name, submitData)
        }
        if (generateDataLayer) {
          const dataLayer = {
            event: "10a.form.success",
          }
          if (name) {
            dataLayer["form-name"] = name
          }
          if (typeof generateDataLayer === "function") {
            const addDataLayer = generateDataLayer(submitData)
            if (addDataLayer) {
              for (const key in addDataLayer) {
                dataLayer[key] = addDataLayer[key]
              }
            }
          }
          GTM.dataLayerPush(dataLayer)
          if (onSubmitSuccess && typeof onSubmitSuccess === "function") {
            onSubmitSuccess(result, resetForm)
          }
        }
      } catch (error) {
        if (onSubmitError && typeof onSubmitError === "function") {
          onSubmitError(error, resetForm)
        }
      }
    },
    [
      formatData,
      generateDataLayer,
      onSubmit,
      onSubmitError,
      onSubmitSuccess,
      name,
      netlify,
      methods,
    ]
  )
  return (
    <FormProvider variant={variant} {...methods}>
      <form
        className={className}
        name={name}
        onSubmit={methods.handleSubmit(handleSubmit)}
        {...formProps}
      >
        {netlify && name && (
          <input type="hidden" name="form-name" value={name} />
        )}
        {children}
      </form>
    </FormProvider>
  )
}
Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  defaultValues: PropTypes.object,
  formatData: PropTypes.func,
  generateDataLayer: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onSubmit: PropTypes.func,
  onSubmitError: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  variant: PropTypes.oneOf(["blue", "yellow"]),
  netlify: PropTypes.bool,
}
Form.defaultProps = {
  generateDataLayer: true,
}
export default Form
