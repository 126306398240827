import axios from "axios"
import { awsBasePath } from "./_config"

const SearchService = {
  // TODO: add a requestKey thing to this
  searchWebsite: (query, page) =>
    axios.get(`${awsBasePath}/es/search`, {
      params: { query, page },
    }),
}
export default SearchService
