import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

export const StyledFlag = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 24px;
    height: 24px;
  }
`

const StyledPhone = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin: 0 -2px;
  & > * {
    margin: 0 2px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 20px;
    line-height: 24px;
  }
`

const Flags = {
  AU: "/flags/flag-au.svg",
  BR: "/flags/flag-br.svg",
  CA: "/flags/flag-ca.svg",
  CH: "/flags/flag-ch.svg",
  EU: "/flags/flag-eu.svg",
  NZ: "/flags/flag-nz.svg",
  UK: "/flags/flag-uk.svg",
  US: "/flags/flag-us.svg",
  INT: "/flags/flag-us.svg",
}

const ariaLabelBase = {
  flag: "Find 10Adventures",
  phone: "Call 10Adventures",
}
const defaultCode = "INT"

const countryCodes = {
  CA: {
    phoneNumber: "1-855-909-3105",
    flag: Flags.CA,
    ariaLabel: "in Canada",
  },
  CH: {
    phoneNumber: "+1 (831) 273-6479",
    flag: Flags.CH,
    ariaLabel: "in Switzerland",
  },
  US: {
    phoneNumber: "1-855-909-3105",
    flag: Flags.US,
    ariaLabel: "in the United States",
  },
  AU: {
    phoneNumber: "+1 (587) 327-9828",
    flag: Flags.AU,
    ariaLabel: "in Australia",
  },
  NZ: {
    phoneNumber: "+1 (587) 327-9828",
    flag: Flags.NZ,
    ariaLabel: "in New Zealand",
  },
  BR: {
    phoneNumber: "+1 (831) 273-6479",
    flag: Flags.BR,
    ariaLabel: "in Brazil",
  },
  UK: {
    phoneNumber: "0808 196 0950",
    flag: Flags.UK,
    ariaLabel: "in the United Kingdom",
  },
  GB: {
    phoneNumber: "0808 196 0950",
    flag: Flags.UK,
    ariaLabel: "in the United Kingdom",
  },
  EU: {
    phoneNumber: "+44 208 106 2028",
    flag: Flags.EU,
    ariaLabel: "in Europe",
  },
  INT: {
    phoneNumber: "+1 (587) 327-9828",
    flag: Flags.INT,
    ariaLabel: "Worldwide",
  },
}

const ContentLocalPhone = ({ className, withFlag, countryCode }) => {
  const code = countryCode || defaultCode
  const country = countryCodes[code] || countryCodes[defaultCode]
  return country ? (
    <StyledPhone className={className}>
      {withFlag && country && country.flag && (
        <StyledFlag
          src={country.flag}
          aria-label={country.ariaLabel}
          alt={country.ariaLabel}
        />
      )}
      {
        <a
          href={`tel:${country.phoneNumber}`}
          aria-label={`Contact 10Adventures ${country.ariaLabel} at ${ariaLabelBase.phone}`}
        >
          {country.phoneNumber}
        </a>
      }
    </StyledPhone>
  ) : null
}
ContentLocalPhone.propTypes = {
  className: PropTypes.string,
  countryCode: PropTypes.string,
  withFlag: PropTypes.bool,
}
ContentLocalPhone.defaultProps = {
  withFlag: true,
  countryCode: "INT",
}
export default ContentLocalPhone
