import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const ImageWrapperStyle = styled.div`
  overflow: hidden;
  & > .lazyload-wrapper {
    width: 100%;
    height: 100%;
  }
  & > img,
  & > .lazyload-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${props => (props.center ? `` : `object-position: top;`)}
  }

  ${props =>
    props.overlayTop || props.overlayBottom
      ? css`
          position: relative;
          &::before,
          &::after {
            color: ${props.theme.white};
            text-shadow: 1px 1px ${props.theme.black};
          }
        `
      : ""}
  ${props =>
    props.overlayTop
      ? css`
          &::before {
            content: "${props.overlayTop}";
            position: absolute;
            top: 0;
          }
        `
      : ""}
  ${props =>
    props.overlayBottom
      ? css`
          &::after {
            content: "${props.overlayBottom}";
            position: absolute;
            bottom: 0;
          }
        `
      : ""}
`
const ImageWrapper = ({ children, className, center, overlayText }) => {
  const { top: overlayTop, bottom: overlayBottom } = overlayText || {}
  return (
    <ImageWrapperStyle
      className={className}
      center={center}
      overlayTop={overlayTop}
      overlayBottom={overlayBottom}
    >
      {children}
    </ImageWrapperStyle>
  )
}
ImageWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  center: PropTypes.bool,
  overlayText: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
  }),
}
export default ImageWrapper
