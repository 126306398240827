import { useStaticQuery, graphql } from "gatsby"
import { mapListToTree } from "@src/utils/trees"

export const useTourCategories = () => {
  const {
    tenTours: {
      tourCategories: { nodes: tourCategories },
    },
  } = useStaticQuery(graphql`
    query TourCategoriesQuery {
      tenTours {
        tourCategories(first: 1000) {
          nodes {
            id: databaseId
            name
            slug
            uri
            parentId: parentDatabaseId
          }
        }
      }
    }
  `)
  return tourCategories
}
export const useTourCategoriesTree = () => mapListToTree(useTourCategories())
