import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTourRegions } from "@src/hooks/useTourRegions"
import { useTourCategories } from "@src/hooks/useTourCategories"
import { useRouteRegions } from "@src/hooks/useRouteRegions"
import { mapListToTree } from "@src/utils/trees"
import SiteHeader from "@src/components/site-header"

const replaceItemInParent = (item, items, replacementItems) => {
  if (!item || !item.parentId) {
    return
  }
  const itemParent = items.find(check => check.id === item.parentId)
  if (!itemParent || !itemParent.items) {
    return
  }
  const itemIndex = itemParent.items.findIndex(check => check.id === item.id)
  if (itemIndex >= 0) {
    itemParent.items.splice(itemIndex, 1, ...replacementItems)
    return true
  }
}
const moveItemToParentSpread = (item, items, slot) => {
  if (!item || !item.parentId) {
    return
  }
  const itemParent = items.find(check => check.id === item.parentId)
  if (!itemParent || !itemParent.spread) {
    return
  }
  if (itemParent.spread === true) {
    itemParent.spread = {}
  }
  if (!itemParent.spread[slot]) {
    itemParent.spread[slot] = {}
  }
  if (!itemParent.spread[slot].items) {
    itemParent.spread[slot].items = []
  }
  itemParent.spread[slot].items.push(item)
  item.to = item.url
  const itemIndex = itemParent.items.findIndex(check => check.id === item.id)
  if (itemIndex >= 0) {
    itemParent.items.splice(itemIndex, 1)
    return true
  }
}

const Header = () => {
  const tourRegionsSource = useTourRegions()
  const tourActivityTypeSource = useTourCategories()
  const routeRegionsSource = useRouteRegions()
  const {
    primaryMenu: {
      menuItems: { nodes: primaryNavItems },
    },
  } = useStaticQuery(graphql`
    query Header {
      primaryMenu: wpMenu(slug: { eq: "nav-primary" }) {
        menuItems {
          nodes {
            id: databaseId
            name: label
            url
            parentId: parentDatabaseId
          }
        }
      }
    }
  `)
  const navTree = useMemo(() => {
    // tour regions
    const tourRegions = tourRegionsSource.map(item => {
      const result = { ...item }
      return result
    })
    const tourRegionTree = mapListToTree(tourRegions)

    // tour activities
    const tourActivityTypes = tourActivityTypeSource.map(
      ({ uri, ...item }) => ({
        ...item,
        uri: uri.replace(/^\/activity\//, "/tours/"),
      })
    )
    const tourActivityTree = mapListToTree(tourActivityTypes)

    // route regions
    const routeRegions = routeRegionsSource.map(item => {
      const result = {
        ...item,
        uri:
          (item.activityTypePrimary && item.activityTypePrimary.uri) ||
          item.uri,
        mapPath: `${item.slug}/`,
      }
      return result
    })
    const routeRegionTree = mapListToTree(routeRegions)

    // clone the items
    const navItems = primaryNavItems.map(item => ({ ...item }))
    const result = mapListToTree(navItems, 0)
    navItems.forEach(item => {
      const urlParse =
        item && item.url ? item.url.match(/^(.*?)\?(.*?)#(.*)?$/) : null
      if (urlParse) {
        // urlParse[1] - before ? (ie. base url)
        item.url = urlParse[1]
        // urlParse[2] - between ? and #
        if (urlParse[2]) {
          const queryParse = [
            ...urlParse[2].matchAll(/(?:^|&)(?:([^&=]+?)(?:=(.+?))?)(?=&|$)/g),
          ]
          for (const queryParsed of queryParse) {
            switch (queryParsed[1]) {
              case "icon":
                if (queryParsed[2]) {
                  item.icon = queryParsed[2]
                }
                break
              case "map":
                item.appendMapPath = true
                break
              case "tour-regions":
                if (!item.replaced) {
                  item.replaced = replaceItemInParent(
                    item,
                    navItems,
                    tourRegionTree
                  )
                }
                break
              case "tour-activities":
                if (!item.replaced) {
                  item.replaced = replaceItemInParent(
                    item,
                    navItems,
                    tourActivityTree
                  )
                }
                break
              case "route-regions":
                if (!item.replaced) {
                  item.replaced = replaceItemInParent(
                    item,
                    navItems,
                    routeRegionTree
                  )
                }
                break
            }
          }
        }
        // urlParse[3] - after #
        if (urlParse[3]) {
          switch (urlParse[3]) {
            case "separator":
              item.separator = true
              break
            case "spread":
              if (!item.spread) {
                item.spread = true
              }
              break
            case "header":
              if (!item.moved) {
                item.moved = moveItemToParentSpread(item, navItems, "header")
              }
              break
            case "footer":
              if (!item.moved) {
                item.moved = moveItemToParentSpread(item, navItems, "footer")
              }
              break
          }
        }
        if (!item.url) {
          item.url = "#"
        }
      }
    })
    return result
  }, [
    primaryNavItems,
    tourRegionsSource,
    tourActivityTypeSource,
    routeRegionsSource,
  ])
  return <SiteHeader navItems={navTree} />
}

export default Header
