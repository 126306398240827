import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { validatePassword } from "@src/services/user/utils/password"
import { FormContext } from "@src/components/form"
import FormFieldInputPassword from "@src/components/form-field-input-password"
import Icon from "@src/components/core-icon"
const passwordStrengths = [
  {
    min: 0,
    label: "Weak",
    className: "strength-weak",
  },
  {
    min: 60,
    label: "Moderate",
    className: "strength-moderate",
  },
  {
    min: 70,
    label: "Strong",
    className: "strength-strong",
  },
  {
    min: 80,
    label: "Very Strong",
    className: "strength-vstrong",
  },
]
const getPasswordStrength = score => {
  for (let i = passwordStrengths.length - 1; i >= 0; i--) {
    if (score > passwordStrengths[i].min) {
      return passwordStrengths[i]
    }
  }
  return null
}

const StyledRuleIcon = styled(Icon)`
  &.icon {
    font-weight: normal;
    text-align: center;
  }
`
const StyledRule = styled.li`
  & > .icon {
    margin-right: 4px;
  }
  &.pass {
    & > .icon {
      color: ${props => props.theme.status.success};
    }
  }
  &.fail {
    & > .icon {
      color: ${props => props.theme.status.error};
    }
  }
`
const StyledRules = styled.ul`
  list-style-type: none;
  margin-bottom: 18px;

  font-size: 12px;
  line-height: 15px;

  ${StyledRuleIcon}.icon {
    font-size: 13px;
    line-height: 12px;
    width: 13px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 16px;
    line-height: 20px;

    ${StyledRuleIcon}.icon {
      font-size: 20px;
      line-height: 16px;
      width: 20px;
    }
  }

  &.validated {
    ${StyledRule}.fail {
      font-weight: bold;
    }
  }
`

const StyledStrength = styled.div`
  max-width: 150px;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 4px;
  margin: 4px 0;
  transition: background-color 0.5s ease-out;
  &.strength-weak {
    background-color: #ff0000;
  }
  &.strength-moderate {
    background-color: #ffcc00;
  }
  &.strength-strong {
    background-color: #00cc00;
  }
  &.strength-vstrong {
    background-color: #00ff00;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    max-width: 180px;
    font-size: 14px;
    padding: 6px 12px;
    margin: 6px 0;
  }
`
const StyledPassword = styled(FormFieldInputPassword)`
  margin-bottom: 0;
  & .field-error {
    margin-bottom: 6px;
  }
`
const FormFieldCreatePassword = ({
  id,
  name,
  label,
  confirmation,
  validation,
  ...props
}) => {
  const [passwordRules, setPasswordRules] = useState([])
  const [passwordStrength, setPasswordStrength] = useState(null)
  const [passwordIsValid, setPasswordIsValid] = useState(false)
  const [hasBlurred, setHasBlurred] = useState(false)
  const [confirmationHasBlurred, setConfirmationHasBlurred] = useState(false)

  const updatePasswordState = passwordValidation => {
    setPasswordIsValid(passwordValidation.valid)
    setPasswordRules(passwordValidation.rules)
    setPasswordStrength(getPasswordStrength(passwordValidation.strength))
  }

  return (
    <FormContext>
      {({ getValues, trigger }) => (
        <>
          <StyledPassword
            id={id}
            name={name}
            label={label}
            onBlur={() => {
              if (!hasBlurred) {
                setHasBlurred(true)
              }
              trigger(name)
            }}
            onChange={() => {
              if (hasBlurred) {
                trigger(name)
                if (confirmation && confirmationHasBlurred) {
                  trigger(`${name}-confirm`)
                }
              }
            }}
            validation={{
              ...validation,
              validate: async value => {
                try {
                  const passwordValidation = await validatePassword(value)
                  updatePasswordState(passwordValidation)
                  return true
                } catch (passwordValidation) {
                  updatePasswordState(passwordValidation)
                  return "Try a stronger password"
                }
              },
            }}
            {...props}
          />
          {passwordIsValid && passwordStrength && (
            <StyledStrength className={passwordStrength.className}>
              {passwordStrength.label}
            </StyledStrength>
          )}
          {
            <StyledRules
              className={`${passwordIsValid ? "valid" : "invalid"}${
                hasBlurred ? " validated" : ""
              }`}
            >
              {passwordRules && passwordRules.length
                ? passwordRules.map(rule => (
                    <StyledRule
                      key={rule.name}
                      className={rule.pass ? "pass" : "fail"}
                    >
                      <StyledRuleIcon glyph={rule.pass ? "check" : "close"} />
                      {rule.message}
                    </StyledRule>
                  ))
                : undefined}
            </StyledRules>
          }
          {confirmation && (
            <FormFieldInputPassword
              id={`${id}-confirm`}
              name={`${name}-confirm`}
              label={`Confirm ${label}`}
              placeholder="Confirm your password"
              onBlur={() => {
                if (!confirmationHasBlurred) {
                  setConfirmationHasBlurred(true)
                }
                trigger(`${name}-confirm`)
              }}
              onChange={() => {
                if (confirmationHasBlurred) {
                  trigger(`${name}-confirm`)
                }
              }}
              validation={{
                validate: value => {
                  const values = getValues()
                  const password = values[name]
                  return (
                    (password && password === value) || "Passwords must match"
                  )
                },
              }}
            />
          )}
        </>
      )}
    </FormContext>
  )
}
FormFieldCreatePassword.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  confirmation: PropTypes.bool,
  validation: PropTypes.object,
}
FormFieldCreatePassword.defaultProps = {
  validation: {},
  confirmation: true,
}
export default FormFieldCreatePassword
