import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { window, exists } from "browser-monads"
import { LayerContext } from "@src/context/layer-context"
import Button from "@src/components/core-button"
import Link, { FancyLink } from "@src/components/core-link"
import Icon from "@src/components/core-icon"
import theme from "@src/styles/theme"

const StyledIcon = styled(Icon)`
  transition: color 0.2s ease-out;
  &.icon {
    font-size: 20px;
  }
`
const StyledSubmenuToggle = styled(Button)`
  padding: 5px;
`
const StyledNav = styled.nav`
  /* general list styles */
  & ul,
  & li {
    list-style-type: none;
  }

  /* top level */
  & > ul,
  & > ul > li {
    display: block;
  }
  /* sub-menus */
  & > ul > li ul,
  & > ul > li li,
  & > ul > li .spread-container {
    display: none;
  }

  /* active trail */
  & ul > li.active {
    &,
    & > ul,
    & > ul > li,
    &.spread .spread-content > ul.active,
    &.spread .spread-content > ul.active > li {
      display: block;
    }
    &.spread > .spread-container,
    &.spread .spread-content {
      display: flex;
    }
    &.spread .spread-content {
      & > ul.active {
        & ~ ul.active {
          display: none;
        }
      }
    }
    &.spread > .spread-container:not(.spread-content) {
      flex-direction: column;
      align-items: stretch;
      & > .spread-header,
      & > .spread-footer {
        flex: 0 0 auto;
      }
      & > .spread-content {
        flex: 1 1 100%;
      }
    }
  }

  & a {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: ${props => props.theme.black};
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    & > span {
      ${FancyLink}
    }
    & > .icon + span,
    & > span + .icon {
      margin-left: 0.5em;
    }
  }

  & li.active > a,
  & li.active > div > a,
  & a:hover {
    font-weight: 500;
    & > span {
      /* copied styles from FancyLink .active,:hover */
      color: ${theme.black};
      &::before {
        animation: 0.15s ease-out 0s fancyUnderline;
        opacity: 1;
        width: 100%;
      }
    }
    & > .icon {
      color: ${props => props.theme.primary};
    }
  }
  & li.active > button,
  & li.active > div > button {
    color: ${props => props.theme.primary};
  }

  & .spread-header,
  & .spread-footer {
    & > a {
      padding: 0 2px 0 1px;
    }
  }

  @media only screen and (${props => props.theme.header.small.min}) {
    & ul > li.active {
      & > ul > li > div:not(.spread-container) {
        display: flex;
        justify-content: center;
      }
      &.spread .spread-content > ul.active > li > a,
      &.spread .spread-content > ul.active > li > div > a {
        display: flex;
        justify-content: space-between;
      }
    }
    & ul > li.active {
      &.spread .spread-content {
        align-items: stretch;
        & > ul.active {
          & ~ ul.active {
            display: block;
          }
          & ul {
            display: none;
          }
        }
      }
    }
  }
`
const ToggleButton = styled(Button)``
const StyledSpreadContainer = styled.div`
  margin-left: 0;
  transition: margin-left 0.1s ease-out;
`

const isTouchScreen =
  exists(window) &&
  ("ontouchstart" in window ||
    ("navigator" in window &&
      (window.navigator.maxTouchPoints > 0 ||
        window.navigator.msMaxTouchPoints > 0)))

const isWindowMobileHeader = () =>
  exists(window) && window.innerWidth < theme.header.small.minWidth

const conditionalRendering = true
const NavPrimary = ({ children, className, maxPerColumn, items }) => {
  const { open: openLayer, close: closeLayer } = useContext(LayerContext)
  const [isMobileScreen, setIsMobileScreen] = useState(isWindowMobileHeader())
  const isMobileScreenRef = useRef(isWindowMobileHeader())
  const [isOpen, setIsOpen] = useState(false)
  const [activeMapPath, setActiveMapPath] = useState("")
  const [activeTrail, setActiveTrail] = useState([])
  const activeTrailStr = activeTrail.join("/")

  // activeSpread holds ref to active spread-container
  const activeSpread = useRef(null)

  // activeSpreadTrack is used in handling outgoing ref
  const activeSpreadTrack = useRef(null)

  // Remove trip-rating
  const tourItem = items.find(item => item.name === "Book a Tour")
  const aboutToursItem =
    tourItem &&
    tourItem.items.find(item => item.name === "About 10Adventures Tours")
  const filteredAboutToursItems =
    aboutToursItem &&
    aboutToursItem.items.filter(item => item.name !== "Trip rating scale")
  if (aboutToursItem) {
    aboutToursItem["items"] = filteredAboutToursItems
  }

  useEffect(() => {
    if (exists(window)) {
      const handleWindowResize = () => {
        const isWindowMobile = isWindowMobileHeader()
        if (isWindowMobile) {
          if (!isMobileScreenRef.current) {
            setIsMobileScreen(true)
          }
        } else if (isMobileScreenRef.current) {
          setIsMobileScreen(false)
        }
        isMobileScreenRef.current = isWindowMobile
      }
      window.addEventListener("resize", handleWindowResize)
      return () => window.removeEventListener("resize", handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // effect to guess when activeSpread might have changed size
  useEffect(() => {
    const node = activeSpread.current
    if (node && exists(window)) {
      // parse current marginLeft offset
      const currentMarginLeft = parseFloat(node.style.marginLeft)
      const currentOffset = !isNaN(currentMarginLeft) ? currentMarginLeft : 0

      // check that the activeSpread stays within the window
      const maxX = window.innerWidth - 8
      const minX = 8
      const bounds = node.getBoundingClientRect()

      // only handle the case of pushing it into the screen when necessary
      // supports a UX goal to minimize things shifting around
      let newOffset = 0
      if (bounds.right > maxX) {
        newOffset = maxX - bounds.right
      } else if (bounds.left < minX) {
        newOffset = minX - bounds.left
      }
      if (newOffset !== 0) {
        node.classList.add("window-bounded")
        node.style.marginLeft = `${currentOffset + newOffset}px`
      }
    }

    // handle change of activeSpread ref node
    if (activeSpreadTrack.current !== node) {
      if (activeSpreadTrack.current) {
        // reset any changes that were made to it
        // next time the menu opens it will be back to normal
        const nodeClosed = activeSpreadTrack.current
        nodeClosed.classList.remove("window-bounded")
        nodeClosed.style.marginLeft = 0
      }
      // track the current activeSpread panel
      activeSpreadTrack.current = node
    }
  }, [activeTrail])

  const handleLayerClosed = useCallback(() => {
    setIsOpen(false)
    setActiveTrail([])
  }, [setIsOpen, setActiveTrail])
  const handleToggleClick = useCallback(
    event => {
      event.preventDefault()
      if (isOpen) {
        setActiveTrail([])
        closeLayer("main-menu")
      } else {
        openLayer({ layerId: "main-menu", onClose: handleLayerClosed })
      }
      setIsOpen(!isOpen)
    },
    [isOpen, setActiveTrail, openLayer, closeLayer, handleLayerClosed]
  )

  const closeTrail = useCallback(() => {
    setActiveTrail([])
    setActiveMapPath("")
    setIsOpen(false)
    closeLayer("main-menu")
  }, [setActiveTrail, setActiveMapPath, setIsOpen, closeLayer])

  const activateTrailStr = useCallback(
    (trail, keepOpen) => {
      if (trail || keepOpen) {
        openLayer({ layerId: "main-menu", onClose: handleLayerClosed })
        if (activeTrailStr !== trail) {
          setActiveTrail(trail.split("/"))
        }
      } else {
        closeTrail()
      }
    },
    [activeTrailStr, setActiveTrail, openLayer, handleLayerClosed, closeTrail]
  )

  const handleItemClick = useCallback(
    event => {
      if (!event || !event.target) {
        return
      }
      if (event.target.getAttribute("data-nav-path")) {
        event.preventDefault()
        activateTrailStr(event.target.getAttribute("data-nav-path"))
        setActiveMapPath(event.target.getAttribute("data-nav-map-path") || "")
      } else if (event.target.getAttribute("href") === "#") {
        event.preventDefault()
      } else {
        // these links close the menu and open the page
        closeTrail()
      }
    },
    [activateTrailStr, setActiveMapPath, closeTrail]
  )
  const handleItemHover = useCallback(
    (event, { trail: resetTrail, mapPath }) => {
      if (isTouchScreen || isMobileScreen) {
        return
      }
      let linkTarget = event && event.target ? event.target : null
      if (linkTarget && linkTarget.nodeName?.toUpperCase() !== "A") {
        linkTarget = event.target.parentNode
      }
      if (resetTrail) {
        activateTrailStr(resetTrail.join("/"))
        setActiveMapPath(mapPath || "")
      } else if (
        linkTarget &&
        !linkTarget.classList.contains("active") &&
        linkTarget.getAttribute("data-nav-path")
      ) {
        event.preventDefault()
        activateTrailStr(linkTarget.getAttribute("data-nav-path"))
        setActiveMapPath(linkTarget.getAttribute("data-nav-map-path") || "")
      }
    },
    [activateTrailStr, setActiveMapPath, isMobileScreen]
  )
  const handleSubmenuToggleClick = useCallback(
    (event, { trail, mapPath }) => {
      event.preventDefault()
      if (activeTrailStr.substr(0, trail.length) === trail) {
        const lastSlash = trail.lastIndexOf("/")
        activateTrailStr(lastSlash > -1 ? trail.substr(0, lastSlash) : "", true)
      } else {
        activateTrailStr(trail)
      }
      setActiveMapPath(mapPath || "")
    },
    [activeTrailStr, activateTrailStr]
  )
  const renderAuxItem = (
    { name, icon, appendMapPath, to: linkTo, href: linkHref },
    key
  ) => {
    if (linkTo && appendMapPath && activeMapPath) {
      linkTo += activeMapPath
    }
    return (
      <Link
        key={key}
        to={linkTo}
        href={linkHref}
        onClick={event => handleItemClick(event)}
      >
        {icon && <StyledIcon glyph={icon} />}
        <span>{name}</span>
      </Link>
    )
  }
  const renderSpread = (item, { itemTrailStr, itemInActive }, collector) => {
    const wrapperClass = ["spread-container"]
    const itemSpread =
      item && item.spread === true ? {} : item ? item.spread : undefined
    const shouldInnerWrap =
      itemSpread && (itemSpread.header || itemSpread.footer)
    if (!shouldInnerWrap) {
      wrapperClass.push("spread-content")
    }
    const spreadContent = shouldInnerWrap ? (
      <div className="spread-content">{collector}</div>
    ) : (
      collector
    )
    return (
      <StyledSpreadContainer
        ref={itemInActive ? activeSpread : undefined}
        key={`${itemTrailStr}-spread`}
        className={wrapperClass.join(" ")}
      >
        {itemSpread && itemSpread.header ? (
          <div className="spread-header">
            {itemSpread.header.items &&
              itemSpread.header.items.map((item, index) =>
                renderAuxItem(item, `${itemTrailStr}-header-${index}`)
              )}
          </div>
        ) : null}
        {spreadContent}
        {itemSpread && itemSpread.footer ? (
          <div className="spread-footer">
            {itemSpread.footer.items &&
              itemSpread.footer.items.map((item, index) =>
                renderAuxItem(item, `${itemTrailStr}-footer-${index}`)
              )}
          </div>
        ) : null}
      </StyledSpreadContainer>
    )
  }
  const handleMenuUnhovered = useCallback(() => {
    if (isTouchScreen || isMobileScreen) {
      return
    }
    closeTrail()
  }, [closeTrail, isMobileScreen])
  const renderItems = (
    items,
    trail = [],
    forceShowChildren = false,
    renderAllChildren = false,
    collectRender = null
  ) => {
    if (!items || !items.length) {
      return undefined
    }
    const trailStr = trail.join("/")
    const inTrail =
      activeTrail.length &&
      activeTrail.length >= trail.length &&
      activeTrail.slice(0, trail.length).join("/") === trailStr
    const menuProps = {}
    if (inTrail) {
      menuProps.className = "active"
    }
    if (!trail.length) {
      menuProps.onMouseLeave = handleMenuUnhovered
    }
    const itemList = (
      <ul
        key={
          collectRender
            ? `${trail.join("/")}-${collectRender.length}`
            : undefined
        }
        {...menuProps}
      >
        {items
          ? items.map(item => {
              const itemUrl = item.url || item.uri

              const itemTrail = [...trail, item.id]
              const itemTrailStr = itemTrail.join("/")
              const isActiveNode =
                activeTrail.length === itemTrail.length &&
                activeTrailStr === itemTrailStr
              const inActiveTrail =
                activeTrail.length &&
                activeTrail.length >= itemTrail.length &&
                activeTrail.slice(0, itemTrail.length).join("/") ===
                  itemTrailStr
              const itemMapPath = item.mapPath
              const classNames = []

              // grab parameters that could be changed for this item
              let renderItemChildren = forceShowChildren
              let renderItemAllChildren = renderAllChildren

              // initiate params for next level of children
              let shouldRenderAllChildren = false
              let shouldForceShow = false

              // spread items show the next level of sub-menus
              // auto-spread top-level (see css)
              if (item.spread) {
                classNames.push("spread")
              }

              // tag the item with an icon
              if (item.icon) {
                classNames.push("with-icon")
              }

              // handle the active trail of open menus
              if (inActiveTrail) {
                classNames.push("active")
                renderItemChildren = true
                shouldForceShow = true
                shouldRenderAllChildren = true
              }

              // handle the active menu item
              if (isActiveNode) {
                classNames.push("active-node")
                renderItemAllChildren = true
              }

              if (item.separator) {
                classNames.push("with-separator")
              }

              // read the childItems
              let childItems = item.items
                ? item.items
                : item.childRegions
                ? item.childRegions
                : undefined

              // set the item to expand sub-menu unless it's active
              const hasChildren = childItems && childItems.length
              const useExpand =
                hasChildren &&
                !inActiveTrail &&
                !isTouchScreen &&
                !isMobileScreen

              // filter out childItems that don't need to be rendered
              if (
                conditionalRendering &&
                childItems &&
                !renderItemAllChildren
              ) {
                childItems = childItems.filter(childItem => {
                  const childTrailStr = `${itemTrailStr}/${childItem.id}`
                  return (
                    activeTrailStr.substr(0, childTrailStr.length) ===
                    childTrailStr
                  )
                })
              }

              const linkProps = {}

              // conditionally add the hover trigger
              if (
                useExpand || // allow drill-down (via click handler)
                !inTrail || // allow ancestor siblings to toggle the trail
                (!inActiveTrail && !hasChildren) // allow direct siblings to toggle the trail
              ) {
                linkProps.onMouseEnter = event =>
                  handleItemHover(event, {
                    trail: useExpand ? null : trail,
                    mapPath: itemMapPath,
                  })
              }
              if (itemMapPath) {
                linkProps["data-nav-map-path"] = itemMapPath
              }

              const itemToggleIcon =
                trail.length && hasChildren
                  ? collectRender
                    ? "chevron-right"
                    : "chevron-bottom"
                  : null

              // render the menu item and optionally its children
              const activeCollector = item.spread ? [] : null
              const childList =
                !conditionalRendering || renderItemChildren
                  ? renderItems(
                      childItems,
                      itemTrail,
                      shouldForceShow,
                      shouldRenderAllChildren,
                      item.spread ? activeCollector : collectRender
                    )
                  : null

              const itemPrimary = (
                <>
                  {hasChildren && (isTouchScreen || isMobileScreen) ? (
                    <StyledSubmenuToggle
                      aria-label={`${
                        !inActiveTrail ? "Expand" : "Collapse"
                      } sub-menu for ${item.name}`}
                      className="submenu-toggle"
                      variant="icon"
                      onClick={event =>
                        handleSubmenuToggleClick(event, {
                          trail: itemTrailStr,
                          mapPath: itemMapPath,
                        })
                      }
                    >
                      <StyledIcon glyph={!inActiveTrail ? "plus" : "minus"} />
                    </StyledSubmenuToggle>
                  ) : null}
                  <Link
                    data-nav-path={useExpand ? itemTrailStr : undefined}
                    href={!useExpand ? itemUrl : undefined}
                    onClick={event => handleItemClick(event)}
                    active={inActiveTrail ? true : undefined}
                    {...linkProps}
                  >
                    {item.icon && <StyledIcon glyph={item.icon} />}
                    <span>{item.name}</span>
                    {itemToggleIcon && <StyledIcon glyph={itemToggleIcon} />}
                  </Link>
                </>
              )

              return (
                <li
                  key={itemTrailStr}
                  className={
                    classNames.length ? classNames.join(" ") : undefined
                  }
                >
                  <div>{itemPrimary}</div>
                  {item.spread
                    ? renderSpread(
                        item,
                        { itemTrailStr, itemInActive: inActiveTrail },
                        activeCollector
                      )
                    : childList}
                </li>
              )
            })
          : undefined}
      </ul>
    )
    if (collectRender && inTrail) {
      collectRender.unshift(itemList)
    }
    return itemList
  }
  return (
    <>
      <ToggleButton
        aria-label={`${!isOpen ? "Open" : "Close"} primary menu`}
        aria-controls="navigation"
        variant="icon"
        icon="menu"
        onClick={event => handleToggleClick(event)}
        className={`${className}${className && isOpen ? " " : ""}${
          isOpen ? "active" : ""
        }`}
      />
      <StyledNav
        id="navigation"
        className={`active-${activeTrail.length}`}
        maxPerColumn={maxPerColumn}
      >
        {renderItems(items, [], true, true)}
        {children}
      </StyledNav>
    </>
  )
}
NavPrimary.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  maxPerColumn: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object),
}
NavPrimary.defaultProps = {
  maxPerColumn: 6,
}
export default NavPrimary
