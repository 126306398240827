import React, { useState } from "react"
import PropTypes from "prop-types"
import { FormContext } from "@src/components/form"
import FormFieldInputText from "@src/components/form-field-input-text"

const validateCode = code => {
  const re = /^\d{6}$/
  return re.test(String(code))
}

const FormFieldVerificationCode = ({ name, validation, ...props }) => {
  const [hasBlurred, setHasBlurred] = useState(false)
  return (
    <FormContext>
      {({ trigger }) => (
        <FormFieldInputText
          name={name}
          onBlur={() => {
            if (!hasBlurred) {
              setHasBlurred(true)
            }
            trigger(name)
          }}
          onChange={value => {
            if (hasBlurred && validateCode(value)) {
              trigger(name)
            }
          }}
          validation={{
            ...validation,
            validate: value => validateCode(value) || "Is that the right code?",
          }}
          {...props}
        />
      )}
    </FormContext>
  )
}
FormFieldVerificationCode.propTypes = {
  name: PropTypes.string,
  validation: PropTypes.object,
}
FormFieldVerificationCode.defaultProps = {
  validation: {},
}
export default FormFieldVerificationCode
