import React, { useEffect, useCallback, useContext } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { LayerContext } from "@src/context/layer-context"
import Button from "@src/components/core-button"
const StyledCloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
`
const StyledTitle = styled.h2`
  font-family: "system-ui";
  margin: 0 0 10px;
`
const StyledContent = styled.div`
  border: 1px solid ${props => props.theme.lighterGray};
  padding: 10px;
`
const StyledActions = styled.div`
  text-align: center;
  padding: 20px 10px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  & > p {
    flex: 1 0 100%;
    margin-bottom: 10px;
  }
  & > button {
    flex: 0 1 auto;
    margin: 10px;
  }
`
const StyledModal = styled.div`
  position: fixed;
  z-index: ${props => props.theme.layers.modals};
  font-family: "system-ui";
  ${props =>
    props.variant === "full-height"
      ? css`
          top: 10vh;
          bottom: 10vh;
        `
      : css`
          top: 30vh;
        `}

  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  max-width: calc(100vw - 20px);
  margin: 0 auto;
  background-color: ${props => props.theme.white};
  border-radius: 8px;
  padding: 20px 16px 20px 20px;

  display: flex;
  flex-direction: column;
  & > ${StyledTitle}, & > ${StyledActions} {
    flex: 0 0 auto;
  }
  & > ${StyledContent} {
    flex: 1 1 100%;
    overflow-y: auto;
  }

  ${props =>
    !props.open
      ? css`
          display: none;
        `
      : ""}
`
const ModalContainer = ({
  className,
  children,
  id: modalId,
  isOpen,
  onClose,
  title,
  content,
  actions,
  variant,
  closeIcon,
  disableClose,
}) => {
  const Layers = useContext(LayerContext)
  const layerId = modalId || "modal"

  const handleClose = useCallback(() => {
    if (disableClose) return
    if (onClose) {
      onClose()
    }
  }, [onClose, disableClose])

  const openLayer = useCallback(() => {
    Layers.open({
      layerId,
      level: 2,
      onClose: handleClose,
    })
  }, [Layers, layerId, handleClose])

  const closeLayer = useCallback(() => {
    if (disableClose) return
    Layers.close(layerId)
  }, [Layers, layerId, disableClose])

  useEffect(() => {
    if (isOpen) {
      openLayer()
    } else {
      closeLayer()
    }
  }, [isOpen, openLayer, closeLayer])

  return (
    <StyledModal
      id={modalId}
      className={className}
      open={isOpen}
      variant={variant}
    >
      {title && <StyledTitle className="fancy">{title}</StyledTitle>}
      {content && (
        <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
      )}
      {children && <StyledContent>{children}</StyledContent>}
      {actions && <StyledActions>{actions}</StyledActions>}
      {disableClose ? null : (
        <StyledCloseButton
          icon={closeIcon || "v2-close"}
          variant="circle"
          size="small"
          onClick={closeLayer}
        />
      )}
    </StyledModal>
  )
}
ModalContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.node,
  actions: PropTypes.node,
  variant: PropTypes.oneOf(["default", "full-height"]),
  closeIcon: PropTypes.string,
  disableClose: PropTypes.bool,
}
ModalContainer.defaultProps = {
  isOpen: false,
  variant: "default",
}
export default ModalContainer
