export const SITE_SEO_LINKS_BLOGS = [
  {
    title: "Top Blogs",
    content: [
      { name: "Best Treks Africa", link: "/best-treks-africa/" },
      { name: "Best Treks Asia", link: "/best-treks-asia/" },
      { name: "Best Treks Europe", link: "/best-multi-day-hikes-in-europe/" },
      { name: "Best Treks Iceland", link: "/best-multi-day-hikes-iceland/" },
      { name: "Camino Guide", link: "/guide-camino-de-santiago/" },
      { name: "Kilimanjaro Guide", link: "/climb-kilimanjaro/" },
      { name: "Machu Picchu Guide", link: "/machu-picchu-faq-facts/" },
      { name: "TMB Guide", link: "/tour-du-mont-blanc/" },
    ],
  },
  {
    title: "Categories",
    content: [
      { name: "Destinations", link: "/category/destinations/" },
      { name: "Gear Reviews", link: "/category/gear/" },
      { name: "Trip Guides", link: "/category/trip-guide/" },
      { name: "Trip Reports", link: "/category/trip-reports/" },
      { name: "Adventure Health", link: "/category/adventure-health/" },
    ],
  },
]
