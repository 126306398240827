export const SITE_SEO_LINKS_TOURS = [
  {
    title: "Continents",
    content: [
      {
        name: "Africa",
        link: "/tours/africa/",
        childLinks: [
          { name: "Botswana", link: "/tours/botswana-tours/" },
          { name: "Kenya", link: "/tours/kenya/" },
          { name: "Morocco", link: "/tours/morocco/" },
          { name: "South Africa", link: "/tours/south-africa-tours/" },
          { name: "Tanzania", link: "/tours/tanzania/" },
        ],
      },
      {
        name: "Asia",
        link: "/tours/asia/",
        childLinks: [
          { name: "India", link: "/tours/india/" },
          { name: "Indonesia", link: "/tours/indonesia-tours/" },
          { name: "Nepal", link: "/tours/nepal/" },
          { name: "Thailand", link: "/tours/south-africa-tours/" },
          { name: "Vietnam", link: "/tours/vietnam/" },
        ],
      },
      {
        name: "Europe",
        link: "/tours/europe/",
        childLinks: [
          { name: "England", link: "/tours/england/" },
          { name: "France", link: "/tours/france/" },
          { name: "Iceland", link: "/tours/iceland-tours/" },
          { name: "Italy", link: "/tours/italy/" },
          { name: "Portugal", link: "/tours/portugal/" },
          { name: "Spain", link: "/tours/spain/" },
        ],
      },
      {
        name: "Middle East",
        link: "/tours/middle-east/",
        childLinks: [
          { name: "Israel", link: "/tours/israel-tours/" },
          { name: "Jordan", link: "/tours/jordan/" },
          { name: "Turkey", link: "/tours/turkey-tours/" },
        ],
      },
      {
        name: "North America",
        link: "/tours/north-america/",
        childLinks: [
          { name: "Canada", link: "/tours/canada/" },
          { name: "USA", link: "/tours/united-states/" },
        ],
      },
      {
        name: "South America",
        link: "/tours/south-america/",
        childLinks: [
          { name: "Argentina", link: "/tours/argentina/" },
          { name: "Bolivia", link: "/tours/bolivia/" },
          { name: "Chile", link: "/tours/chile/" },
          { name: "Colombia", link: "/tours/colombia/" },
          { name: "Ecuador", link: "/tours/ecuador/" },
          { name: "Peru", link: "/tours/peru/" },
        ],
      },
    ],
  },
  {
    title: "Top Destinations",
    content: [
      { name: "Amalfi Coast", link: "/tours/amalfi-coast-tours/" },
      { name: "Atlas Mountains", link: "/tours/atlas-mountains-tours/" },
      {
        name: "Camino de Santiago",
        link: "/tours/spain-camino-santiago-tours/",
      },
      { name: "Canadian Rockies", link: "/tours/alberta-canadian-rockies/" },
      { name: "Cotswolds", link: "/tours/cotswolds-tours/" },
      { name: "Dolomites", link: "/tours/dolomites-tours/" },
      { name: "Everest", link: "/tours/everest-tours/" },
      { name: "Himalayas", link: "/tours/himalaya-tours/" },
      { name: "Kilimanjaro", link: "/tours/kilimanjaro-tours/" },
      { name: "Laugavegur", link: "/tours/laugavegur-tours/" },
      { name: "Machu Picchu", link: "/tours/machu-picchu-tours/" },
      { name: "Patagonia", link: "/tours/argentinian-patagonia-tours/" },
      {
        name: "Portugese Camino",
        link: "/tours/portugal-camino-santiago-tours/",
      },
      { name: "Torres del Paine", link: "/tours/torres-del-paine-tour/" },
      { name: "Tour de Mont Blanc", link: "/tours/france-mont-blanc-tours/" },
      { name: "Tuscany", link: "/tours/tuscany-tours/" },
    ],
  },
  {
    title: "Tour Activities",
    content: [
      { name: "Biking", link: "/tours/bike-tours/" },
      { name: "Climbing", link: "/tours/climbing-tours/" },
      { name: "Trekking", link: "/tours/trekking-tours/" },
      { name: "Family Trips", link: "/tours/family-tours/" },
      { name: "Horseback Riding", link: "/tours/horseback-riding-tours/" },
      { name: "Multisport", link: "/tours/multi-sport-tours/" },
      { name: "Snow", link: "/tours/snow-tours/" },
      { name: "Water", link: "/tours/water-tours/" },
      { name: "Wildlife", link: "/tours/wildlife-tours/" },
      { name: "Pilgrimages", link: "/tours/pilgrimage-tours/" },
    ],
  },
]
