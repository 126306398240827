import { useStaticQuery, graphql } from "gatsby"
import { mapListToTree } from "@src/utils/trees"

export const useTourRegions = () => {
  const {
    tenTours: {
      tourRegions: { nodes: tourRegions },
    },
  } = useStaticQuery(graphql`
    query TourRegionsQuery {
      tenTours {
        tourRegions(first: 1000) {
          nodes {
            id: databaseId
            name
            slug
            uri
            parentId: parentDatabaseId
          }
        }
      }
    }
  `)
  return tourRegions
}
export const useTourRegionsTree = () => mapListToTree(useTourRegions())
