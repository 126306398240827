import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import LazyLoad from "react-lazyload"
export { forceCheck as forceLazyLoad } from "react-lazyload"
import ImageWrapper from "@src/components/core-image-wrapper"
import ImagePlaceholder from "@src/components/core-image-placeholder"
import { screen } from "@src/styles/theme"

const ImgCaption = styled.div`
  position: absolute;
  bottom: 0;

  & > p {
    margin: 10px 0px;
    color: ${props => props.theme.black};
  }
`
const StyledPicture = styled.picture`
  display: block;
  width: 100%;
  height: 100%;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    ${props => (props.center ? `` : `object-position: top;`)}
  }
`
const StyledLazyLoad = styled(LazyLoad)`
  display: inline-block;
  & > img {
    display: block;
  }
`

const getScreenSizeForImage = name => {
  for (const key in screen) {
    if (name.substr(-1 * key.length - 1) === `-${key}`) {
      return screen[key]
    }
  }
}

const createMinAndMaxWidthObject = ({ min, max }) =>
  `${min ? `(${min})` : ``}${min && max ? ` and ` : ``} ${
    max ? `(${max})` : ``
  }`

const createResponsiveImageSizes = (
  src,
  width,
  height,
  alt,
  sizes,
  artDirection,
  center,
  className
) => {
  const sourceArray = sizes.reduce((result, { src, width, name }) => {
    const screenSize = getScreenSizeForImage(name)
    if (screenSize) {
      result.push({
        srcSet: src,
        width,
        screenSize,
      })
    }
    return result
  }, [])
  sourceArray.forEach((sourceSize, index, array) => {
    sourceSize.media = createMinAndMaxWidthObject({
      min: index > 0 && sourceSize.screenSize.min,
      max:
        (artDirection || index < array.length - 1) && sourceSize.screenSize.max,
    })
  })

  return artDirection ? (
    <StyledPicture className={className} center={center}>
      {sourceArray.map((source, index) => (
        <source key={index} srcSet={source.srcSet} media={source.media} />
      ))}
      <img src={src} width={width} height={height} alt={alt} />
    </StyledPicture>
  ) : (
    <img
      srcSet={sourceArray
        .map(({ srcSet, width }) => `${srcSet} ${width}w`)
        .join(", ")}
      sizes={sourceArray
        .map(({ media, width }) => `${media} ${width}px`)
        .join(", ")}
      src={src}
      width={width}
      height={height}
      alt={alt}
      className={className}
    />
  )
}

const createResponsiveImageArtDirection = (
  src,
  width,
  height,
  alt,
  responsive,
  artDirection,
  center,
  className
) => {
  if (artDirection === true) {
    artDirection = {}
  }

  const sources = []
  for (const responsiveSource of responsive) {
    const target = responsiveSource.target
    sources.push({
      minWidth: screen[target] && screen[target].minWidth,
      sizes: artDirection[target] || null,
      sources: responsiveSource.sources,
    })
  }
  sources.sort((a, b) => (b.minWidth || 0) - (a.minWidth || 0))

  return (
    <StyledPicture className={className} center={center}>
      {sources.map((source, index) => {
        const sourceAttrs = {}
        if (source.minWidth) {
          sourceAttrs["media"] = `(min-width: ${source.minWidth}px)`
        }
        if (source.sizes) {
          sourceAttrs["sizes"] = source.sizes
        }
        source.sources.sort((a, b) => a.width - b.width)
        sourceAttrs["srcSet"] = source.sources.reduce((result, source) => {
          return (
            result +
            (result ? ", " : "") +
            `${source.src || source.url} ${source.width}w`
          )
        }, "")
        return <source key={index} {...sourceAttrs} />
      })}
      <img src={src} width={width} height={height} alt={alt} />
    </StyledPicture>
  )
}

const createResponsiveImage = (
  src,
  width,
  height,
  alt,
  responsive,
  artDirection,
  center,
  className
) => {
  if (artDirection) {
    return createResponsiveImageArtDirection(
      src,
      width,
      height,
      alt,
      responsive,
      artDirection,
      center,
      className
    )
  }

  // combine the responsive sources and detect their corresponsding breakpoints
  const sources = []
  const breakpointSizes = []
  for (const responsiveSource of responsive) {
    const target = responsiveSource.target
    sources.push(...responsiveSource.sources)
    if (!responsiveSource.sources || !responsiveSource.sources.length) {
      continue
    }
    breakpointSizes.push({
      minWidth: screen[target] && screen[target].minWidth,
      width: responsiveSource.sources[0].width,
    })
  }

  // build the srcset attribute (sorted low to high)
  sources.sort((a, b) => a.width - b.width)
  const srcset = sources.reduce((result, source) => {
    return (
      result +
      (result ? ", " : "") +
      `${source.src || source.url} ${source.width}w`
    )
  }, "")

  // build the sizes attribute (sorted high to low)
  breakpointSizes.sort((a, b) => (b.minWidth || 0) - (a.minWidth || 0))

  if (breakpointSizes.length) {
    // remove minWidth from last breakpoint so it will be the default
    delete breakpointSizes[breakpointSizes.length - 1].minWidth
  }

  const sizes = breakpointSizes.reduce((result, breakpoint) => {
    return (
      result +
      (result ? ", " : "") +
      (breakpoint.minWidth ? `(min-width: ${breakpoint.minWidth}px) ` : "") +
      `${breakpoint.width}px`
    )
  }, "")

  return (
    <img
      src={src}
      width={width}
      height={height}
      sizes={sizes}
      srcSet={srcset}
      alt={alt}
      className={className}
    />
  )
}

const Image = ({
  src,
  url,
  width,
  height,
  alt,
  responsive,
  sizes,
  artDirection,
  placeholder,
  wrapper,
  wrapperProps,
  caption,
  showCaption,
  className,
  lazyload,
  center,
  srcSet,
}) => {
  src = src || url
  const ImgTag =
    (responsive &&
      responsive.length > 0 &&
      createResponsiveImage(
        src,
        width,
        height,
        alt,
        responsive,
        artDirection,
        center,
        className
      )) ||
    (sizes &&
      typeof sizes !== "string" &&
      sizes.length &&
      createResponsiveImageSizes(
        src,
        width,
        height,
        alt,
        sizes,
        artDirection,
        center,
        className
      )) ||
    (src && srcSet && (
      <img
        className={className}
        src={src}
        srcSet={srcSet}
        width={width}
        height={height}
        alt={alt}
        sizes={
          sizes && typeof sizes === "string" && sizes.length ? sizes : null
        }
      />
    )) ||
    (src && (
      <img
        className={className}
        src={src}
        width={width}
        height={height}
        alt={alt}
      />
    )) ||
    (placeholder && (
      <ImagePlaceholder
        className={className}
        width={!wrapper ? width : undefined}
        height={!wrapper ? height : undefined}
        alt={alt}
        wrapped={!!wrapper}
        variant={placeholder}
      />
    ))
  const ImgTagLazy = lazyload ? (
    <StyledLazyLoad {...wrapperProps}>{ImgTag}</StyledLazyLoad>
  ) : (
    ImgTag
  )
  const WrapperType = wrapper === true ? ImageWrapper : wrapper
  if (WrapperType) {
    return (
      <>
        <WrapperType center={center} {...wrapperProps}>
          {ImgTagLazy}
        </WrapperType>
        {caption && showCaption && (
          <ImgCaption>
            <p>{caption}</p>
          </ImgCaption>
        )}
      </>
    )
  }
  return ImgTagLazy ? ImgTagLazy : null
}
export const ImagePropTypes = {
  src: PropTypes.string,
  url: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}
export const ImageCreditsPropTypes = {
  titleText: PropTypes.string,
  sourceLink: PropTypes.string,
  creatorText: PropTypes.string,
  creatorLink: PropTypes.string,
  licenseText: PropTypes.string,
  licenseLink: PropTypes.string,
  extraText: PropTypes.string,
}
Image.propTypes = {
  ...ImagePropTypes,
  responsive: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      sources: PropTypes.arrayOf(PropTypes.shape({ ...ImagePropTypes })),
    })
  ),
  sizes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      ...ImagePropTypes,
    })
  ),
  alt: PropTypes.string,
  artDirection: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  caption: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  wrapper: PropTypes.oneOfType([PropTypes.bool, PropTypes.elementType]),
  wrapperProps: PropTypes.object,
  className: PropTypes.string,
  lazyload: PropTypes.bool,
  showCaption: PropTypes.bool,
  center: PropTypes.bool,
  srcSet: PropTypes.string,
}
Image.defaultProps = {
  lazyload: true,
  showCaption: true,
}
export default Image

export const ImageSizesQueries = graphql`
  fragment WpImageSizesQuery on WpImage {
    src: url
    width
    height
    alt
    responsive {
      target
      sources {
        src: url
        width
        height
      }
    }
  }
  fragment WpImageCreditsQuery on WpImage {
    credits {
      titleText
      sourceLink
      creatorText
      creatorLink
      licenseText
      licenseLink
      extraText
    }
  }
  fragment TenAdventuresGQL_ImageSizesQuery on TenAdventuresGQL_Image {
    src: url
    width
    height
    alt
    responsive {
      target
      sources {
        src: url
        width
        height
      }
    }
  }
  fragment TenToursGQL_ImageSizesQuery on TenToursGQL_Image {
    src: url
    width
    height
    alt
    responsive {
      target
      sources {
        src: url
        width
        height
      }
    }
  }
`
