import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { window, exists } from "browser-monads"
import { Link as GatsbyLink } from "gatsby"
import AffiliateLinks from "@src/services/affiliates/links"
import { FancyLink } from "@src/styles/elements"
export { navigate } from "gatsby"
export { FancyLink } from "@src/styles/elements"

const CompactLink = css`
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
  z-index: 0;
  text-decoration: none;
  ${props => props.$variant === "compact" && "line-clamp: 2;"}
`

const SimpleLink = css`
  text-decoration: none;
`
export const StyledLink = css`
  color: ${props =>
    props.active
      ? props.theme.primary
      : props.disabled
      ? props.theme.darkGray
      : props.$variant === "blue"
      ? props.theme.darkBlue
      : props.theme.black};
  ${props =>
    props.disabled
      ? css`
          cursor: default;
        `
      : css`
          &:hover {
            color: ${props.theme.primary};
          }
        `}
  ${props => props.$variant === "fancy" && FancyLink}
  ${props => props.$variant === "compact" && CompactLink}
  ${props => props.$variant === "simple" && SimpleLink}
`
const StyledGatsbyLink = styled(GatsbyLink)`
  ${StyledLink}
`
const StyledHtmlLink = styled.a`
  ${StyledLink}
`

export const addGps = (url, gps) => {
  if (!gps) return url
  url += "#"
  if (Array.isArray(gps) && gps.length) {
    gps.forEach(
      (coordinates, index) =>
        (url += `${index > 0 ? "," : ""}${coordinates.lat},${coordinates.lon}`)
    )
  } else {
    url += `#${gps.lat},${gps.lon}`
  }
  return url
}
const Link = ({
  className,
  children,
  active,
  disabled,
  to,
  href,
  gps,
  email,
  phone,
  external,
  taLink,
  onClick,
  variant,
  ...props
}) => {
  const destination = to || href
  const [loadedProps, setLoadedProps] = useState({})
  const handleClick = useCallback(
    event => {
      if (disabled) {
        event.preventDefault()
        return false
      }
      if (onClick) {
        return onClick(event)
      } else if (destination === "#") {
        event.preventDefault()
        return false
      }
    },
    [disabled, destination, onClick]
  )

  if (active) {
    if (!className) {
      className = ""
    } else {
      className += " "
    }
    className += "active"
  }

  useEffect(() => {
    if (!taLink || !href) {
      setLoadedProps({})
      return
    }
    AffiliateLinks.lookupTaLinksDebounced([href]).then(result => {
      if (result && result.length) {
        const resultLink = result.find(check => check.orig === href)
        if (resultLink) {
          const loadProps = {
            href: resultLink.href,
            title: resultLink.title,
            rel: resultLink.rel,
          }
          if (resultLink.link_id) {
            loadProps.onClick = () => {
              const trackData = { link_id: resultLink.link_id, href }
              if (exists(window)) {
                trackData.page = window.location.href + window.location.search
              }
              AffiliateLinks.trackTaLinkClick(trackData).catch(error =>
                console.log("Error in trackTaLinkClick request: ", error)
              )
            }
          }
          setLoadedProps(loadProps)
        }
      }
    })
  }, [taLink, href, setLoadedProps])

  if (destination) {
    const internal = !external && /^#|^\/(?!\/)/.test(destination)
    if (internal) {
      return (
        <StyledGatsbyLink
          className={className}
          $variant={variant}
          disabled={disabled}
          to={addGps(destination, gps)}
          onClick={handleClick}
          {...props}
        >
          {children}
        </StyledGatsbyLink>
      )
    } else {
      const linkProps = {
        className,
        $variant: variant,
        active,
        disabled,
        href: destination,
        onClick: handleClick,
        ...loadedProps,
      }
      if (!internal) {
        linkProps.target = "_blank"
        linkProps["aria-label"] = "Opens in a new tab or window"
      }
      if (linkProps.rel && props.rel) {
        const relList = linkProps.rel.split(" ")
        linkProps.rel = relList
          .concat(
            props.rel.split(" ").filter(check => !relList.includes(check))
          )
          .join(" ")
      }
      return (
        <StyledHtmlLink {...props} {...linkProps}>
          {children}
        </StyledHtmlLink>
      )
    }
  } else if (email) {
    return (
      <StyledHtmlLink
        className={className}
        $variant={variant}
        disabled={disabled}
        href={`mailto:${email}`}
        onClick={handleClick}
        {...props}
      >
        {children}
      </StyledHtmlLink>
    )
  } else if (phone) {
    return (
      <StyledHtmlLink
        className={className}
        $variant={variant}
        disabled={disabled}
        href={`tel:${phone}`}
        onClick={handleClick}
        {...props}
      >
        {children}
      </StyledHtmlLink>
    )
  } else if (onClick) {
    return (
      <StyledHtmlLink
        className={className}
        $variant={variant}
        active={active}
        disabled={disabled}
        href="#"
        rel="nofollow"
        onClick={handleClick}
        {...props}
      >
        {children}
      </StyledHtmlLink>
    )
  }
  return null
}
Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  rel: PropTypes.string,
  gps: PropTypes.oneOfType([
    PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({ lat: PropTypes.number, lon: PropTypes.number })
    ),
  ]),
  email: PropTypes.string,
  phone: PropTypes.string,
  external: PropTypes.bool,
  taLink: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["default", "blue", "fancy", "compact", "simple", "routes"]),
}
export default Link
