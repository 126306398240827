const getNumberPadding = (value, padding = 0) => {
  const valueStr = `${value}`
  if (valueStr.length < padding) {
    return "0".repeat(padding - valueStr.length)
  }
  return ""
}
export const formatNumber = (
  value,
  decimals = 0,
  separator = ",",
  padding = 0
) => {
  return (
    getNumberPadding(value, padding) +
    parseFloat(value)
      .toFixed(decimals)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + separator)
  )
}

// rounding logic copied from backend SWPH_Geo::round_converted_amount
export const roundToIncrement = (value, roundingIncrements) => {
  const amount = Math.ceil(value)
  const modulo = amount % 100
  for (const increment of roundingIncrements) {
    if (increment === modulo) {
      return amount
    } else if (increment > modulo) {
      return amount + increment - modulo
    }
  }
  return amount
}
