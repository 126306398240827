import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
const Label = styled.label`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  margin-bottom: 10px;
  ${props =>
    props.variant === "blue"
      ? css`
          color: ${props.theme.darkBlue};
        `
      : undefined}
  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 16px;
    line-height: 20px;
  }
`
const Input = styled.input`
  // Can't use ::before and ::after for inputs without -moz-appearance as items should have content.
  // https://stackoverflow.com/questions/35378360/before-after-pseudo-elements-not-showing-firefox
  -moz-appearance: initial;
  position: relative;
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin: 3px;
  display: inline-block;

  &::before,
  &::after {
    content: "";
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: -3px;
    left: -3px;
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }
  &::before {
    background-color: ${props => props.theme.bg1};
    border: 1px solid ${props => props.theme.black};
  }
  &::after {
    background-color: ${props =>
      props.variant === "blue" ? props.theme.darkBlue : props.theme.primary};
    transform: scale(0);
    transition: transform 0.1s ease-in;
  }

  &:focus::before {
    outline: ${props => props.theme.focusOutline};
    outline: -webkit-focus-ring-color auto 1px;
  }
  &:checked::before {
    border-color: ${props =>
      props.variant === "blue" ? props.theme.darkBlue : props.theme.primary};
  }
  &:checked::after {
    transform: scale(1);
    font-family: "icons-10a-v2" !important;
    content: "\\e960";
    font-size: 12px;
    padding: 4px;
  }

  ${Label} > & {
    margin-right: 12px;
  }
`
const InputCheckbox = ({
  id,
  name,
  value,
  valueTrue,
  valueFalse,
  checked: defaultValue,
  label,
  onChange,
  onInit,
  variant,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultValue || value === valueTrue)
  const getValue = useCallback(
    isChecked => (isChecked ? valueTrue || true : valueFalse || false),
    [valueTrue, valueFalse]
  )
  const handleChange = event => {
    const isChecked = event.target && event.target.checked
    setChecked(isChecked)
    if (onChange) {
      onChange(getValue(isChecked))
    }
  }
  useEffect(() => {
    if (onInit) {
      onInit(getValue(checked))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setChecked(defaultValue)
  }, [setChecked, defaultValue])
  const checkbox = (
    <Input
      type="checkbox"
      id={id}
      name={name}
      value={value || valueTrue}
      checked={!!checked}
      onChange={handleChange}
      variant={variant}
      {...props}
    />
  )
  return label ? (
    <Label htmlFor={id} variant={variant}>
      {checkbox}
      {label}
    </Label>
  ) : (
    checkbox
  )
}
InputCheckbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  valueFalse: PropTypes.any,
  valueTrue: PropTypes.any,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  onInit: PropTypes.func,
  variant: PropTypes.oneOf(["blue", "yellow"]),
}
InputCheckbox.defaultProps = {
  valueFalse: false,
  valueTrue: true,
}
export default InputCheckbox
