import { useStaticQuery, graphql } from "gatsby"
import { mapListToTree } from "@src/utils/trees"

export const useRouteRegions = () => {
  const {
    allWpRegion: { nodes: regions },
  } = useStaticQuery(graphql`
    query RegionsQuery {
      allWpRegion {
        nodes {
          id: databaseId
          parentId: parentDatabaseId
          regionType
          name
          slug
          uri
          gps: gpsPoint {
            lat
            lon
          }
          gpsBounds {
            sw: cornerSW {
              lat
              lon
            }
            ne: cornerNE {
              lat
              lon
            }
          }
          ...wpRegionActivityTypePrimary
          ...wpRegionActivityTypes
        }
      }
    }
  `)
  return regions
}

export const useRouteRegionsTree = () => mapListToTree(useRouteRegions())
