import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const sizes = {
  sm: 20,
  md: 40,
  lg: 60,
  xl: 80,
}

const StyledSpinner = styled.div`
  display: inline-block;
  width: ${props => sizes[props.size]}px;
  height: ${props => sizes[props.size]}px;

  &::after {
    content: " ";
    display: block;
    width: ${props => sizes[props.size]}px;
    height: ${props => sizes[props.size]}px;
    border-radius: 50%;
    border: ${props => sizes[props.size] / 10}px solid
      ${props => props.theme[props.color]};
    border-color: ${props => props.theme[props.color]} transparent
      ${props => props.theme[props.color]} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${props =>
    props.align === "center"
      ? css`
          width: 100%;
          &::after {
            margin: 0 auto;
          }
        `
      : ""}
`
const Spinner = ({ className, align, size, color }) => (
  <StyledSpinner
    align={align}
    size={size}
    color={color}
    className={className}
  />
)

Spinner.propTypes = {
  align: PropTypes.oneOf(["center"]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  color: PropTypes.oneOf([
    "primary",
    "black",
    "white",
    "darkGray",
    "darkerGray",
  ]),
  className: PropTypes.string,
}
Spinner.defaultProps = {
  size: "md",
  color: "primary",
}
export default Spinner
