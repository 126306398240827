// Password validation rules
const passwordRules = [
  {
    name: "min-length",
    message: "Must be at least 8 characters",
    check: password => password.length >= 8,
  },
  {
    name: "has-number",
    message: "Must include a number",
    check: password => !!password.match(/\d/),
  },
  {
    name: "has-special",
    message: "Must include a symbol (!@#$%^&*()-_+=)",
    check: password => !!password.match(/[!@#$%^&*()\-_+=]/),
  },
  {
    name: "has-upper",
    message: "Must include an uppercase letter",
    check: password => !!password.match(/[A-Z]/),
  },
  {
    name: "has-lower",
    message: "Must include a lowercase letter",
    check: password => !!password.match(/[a-z]/),
  },
]

const checkPasswordStrength = pass => {
  // source: https://stackoverflow.com/a/11268104/1798697
  let score = 0
  if (!pass) {
    return score
  }

  // award every unique letter until 5 repetitions
  const letters = new Object()
  for (var i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  }

  let variationCount = 0
  for (const check in variations) {
    variationCount += variations[check] == true ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}

export const validatePassword = password =>
  new Promise((resolve, reject) => {
    const checkedRules = []
    let hasFail = false

    for (const rule of passwordRules) {
      const rulePasses = rule.check(password)
      checkedRules.push({
        name: rule.name,
        message: rule.message,
        pass: rulePasses,
      })
      if (!rulePasses) {
        hasFail = true
      }
    }

    const result = {
      strength: checkPasswordStrength(password),
      rules: checkedRules,
      valid: !hasFail,
    }
    if (!result.valid) {
      reject(result)
      return
    }
    resolve(result)
  })
