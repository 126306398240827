import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

const IconStyle = styled.i`
  display: inline-block;
  height: 1em;
  min-width: 1em;
  line-height: 1em;
  vertical-align: middle;
  ${props =>
    props.primary
      ? css`
          color: ${props.theme.primary};
        `
      : ""}

  &.icon {
    font-size: ${props => (props.iconFontSize ? props.iconFontSize : "20px")};
    @media only screen and (${props => props.theme.screen.small.min}) {
      font-size: ${props => (props.iconFontSize ? props.iconFontSize : "40px")};
    }
  }
  &.icon-weightlifter {
    transform: scale(0.8);
  }
`
const Icon = ({ glyph, className, ...props }) => {
  return (
    glyph && (
      <IconStyle
        className={`icon icon-${glyph} ${className || ""}`}
        {...props}
      />
    )
  )
}

IconStyle.propTypes = {
  iconFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Icon.propTypes = {
  className: PropTypes.string,
  glyph: PropTypes.string,
}
export default Icon
