import { css } from "styled-components"

import theme from "@src/styles/theme"

/*
  Typography Definition - applied via @src/layouts/primary.js
*/

const typographyStyles = css`
  html {
    font-size: 100%; /* 16px */
  }
  html,
  body {
    font-family: system-ui, Arial, Helvetica, Sans-serif;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 0;
  }
  h1,
  .h1 {
    font-size: 1.5rem;
    line-height: 1.625em;
    font-weight: 500;
    margin: 10px 0;
  }
  h1.title {
    font-weight: 700;
  }
  h1.region-title {
    font-size: 1.75rem;
    line-height: normal;
    font-weight: 700;
    margin: 0;
  }
  h2,
  .h2 {
    font-size: 1.375rem;
    line-height: 1.5em;
    font-weight: 500;
    margin: 10px 0;
  }
  h3,
  .h3 {
    font-size: 1.125rem;
    line-height: 1.25em;
    font-weight: 500;
    margin: 10px 0;
  }
  h4,
  .h4 {
    font-size: 1rem;
    line-height: normal;
    font-weight: 500;
  }
  h5,
  .h5 {
    font-weight: 500;
  }
  h6,
  .h6 {
    font-weight: 500;
  }
  p {
    font-size: 1rem;
    line-height: 1.125em;
    margin-bottom: 1em;
  }
  p.p1 {
    font-size: 1rem;
    line-height: 1.25em;
  }
  p.p2 {
    font-size: 0.9375rem;
    line-height: 1em;
  }
  p.p3 {
    font-size: 0.875rem;
    line-height: 0.875em;
  }
  p.subtext {
    font-size: 0.75rem;
    line-height: 0.875em;
    margin: 10px 0 0;
  }
  p.tooltip {
    font-size: 0.875rem;
    line-height: 1.5em;
  }
  p.banner {
    font-size: 0.75rem;
    line-height: 1.5em;
    font-weight: 600;
  }

  /* 320px — 480px: Mobile devices. */
  @media only screen and (${theme.screen.mobile.max}) {
  }

  /* 481px — 768px: iPads, Tablets. */
  @media only screen and (${theme.screen.tablet.min}) {
  }

  /* 769px — 1024px: Small screens, laptops. */
  @media only screen and (${theme.screen.small.min}) {
    h1,
    .h1 {
      font-size: 2rem;
      line-height: 2.125em;
      font-weight: 500;
    }
    h1.title {
      font-size: 3.5rem;
      line-height: normal;
      font-weight: 700;
    }
    h1.region-title {
      font-size: 2.5rem;
      line-height: normal;
      font-weight: 700;
    }
    h2,
    .h2 {
      font-size: 1.625rem;
      line-height: 1.75em;
      font-weight: 500;
    }
    h3,
    .h3 {
      font-size: 1.25rem;
      line-height: 1.375em;
      font-weight: 500;
    }
    h4,
    .h4 {
      font-size: 1.125rem;
      line-height: 1.25em;
      font-weight: 500;
    }
    h5,
    .h5 {
    }
    h6,
    .h6 {
    }
    p {
      font-size: 1rem;
      line-height: 1.125em;
      margin-bottom: 1em;
    }
    p.p1 {
      font-size: 1.5rem;
      line-height: 1.625em;
    }
    p.p2 {
      font-size: 1.125rem;
      line-height: 1.25em;
    }
    p.p3 {
      font-size: 1rem;
      line-height: 1.125rem;
    }
    p.subtext {
      font-size: 0.875em;
      line-height: 1em;
    }
    p.tooltip {
      font-size: 1rem;
      line-height: 1.5em;
    }
    p.banner {
      font-size: 1rem;
    }
  }

  /* 1025px — 1200px: Desktops, large screens. */
  @media only screen and (${theme.screen.medium.min}) {
    h1.region-title {
      font-size: 3rem;
    }
  }

  /* 1261px and more — Extra large screens, TV. */
  @media only screen and (${theme.screen.large.min}) {
  }
`
export default typographyStyles
