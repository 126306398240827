import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Link from "@src/components/core-link"
const StyledNav = styled.nav``
const NavUser = ({
  className,
  user,
  signout,
  setUserFlowMode,
  setShowUserModal,
}) => {
  const handleSignout = event => {
    event.preventDefault()
    signout()
      .then(result => {
        console.log("signout result =>", result)
      })
      .catch(error => {
        console.log("signout error =>", error)
      })
  }
  const handleLoginClick = event => {
    event.preventDefault()
    setUserFlowMode("LOGIN")
    setShowUserModal(true)
  }
  const handleSignUpClick = event => {
    event.preventDefault()
    setUserFlowMode("SIGNUP")
    setShowUserModal(true)
  }
  return (
    <StyledNav className={className}>
      {user ? (
        <ul>
          <li>
            <Link to="/user/profile/">My Profile</Link>
          </li>
          <li>
            <Link onClick={handleSignout}>Log out</Link>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <Link onClick={handleLoginClick}>Log in</Link>
          </li>
          <li>
            <Link onClick={handleSignUpClick}>Sign up</Link>
          </li>
        </ul>
      )}
    </StyledNav>
  )
}
NavUser.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  signout: PropTypes.func,
  setUserFlowMode: PropTypes.func,
  setShowUserModal: PropTypes.func,
}
export default NavUser
