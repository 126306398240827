import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import classNames from "classnames"
import parse, { domToReact } from "html-react-parser"
import AffiliateLinks from "@src/services/affiliates/links"
import DistanceValue from "@src/components/core-value-local-distance"
import CurrencyValue from "@src/components/core-value-local-currency"
import Link from "@src/components/core-link"
import Button, { ButtonGroup } from "@src/components/core-button"

const distanceDecimals = {
  km: 1,
  m: 0,
  mi: 1,
  ft: 0,
}
const includesWord = (inString, searchWord) =>
  new RegExp(`(?:^| )${searchWord}(?: |$)`, "im").test(inString)

const HTML_BR_RX = /<br ?\/?>/gi
const parseHtmlParagraphs = html => {
  const paragraphs = html.matchAll(/<p>(.*?)<\/p>/gms)
  for (const paragraph of paragraphs) {
    if (paragraph.length < 2) {
      continue
    }

    // check if the paragraph only has button links
    const nonButtonElements = paragraph[1]
      .split(/<a.*?class="(?:.+ )?button(?: .+)?".*?<\/a>/)
      .filter(item => !!item.replaceAll(HTML_BR_RX, "").trim())
    if (!nonButtonElements.length) {
      html = html.replace(
        paragraph[0],
        `<p class="button-group">${paragraph[1].replaceAll(HTML_BR_RX, "")}</p>`
      )
    }
  }
  return html
}

const addLazyLoading = html => {
  return html.replace(/(<img[^>]+)(\/>)/g, '$1 loading="lazy"$2')
}

const parseHtmlShortcodes = html =>
  html
    .replace(
      /{units unit="(.*?)" value="(.*?)"}/gim,
      '<span data-unit="$1" data-value="$2">$2 $1</span>'
    )
    .replace(
      /{price currency="(.*?)" value="(.*?)"}/gim,
      '<span data-currency="$1" data-value="$2">$1 $2</span>'
    )
const prepareHtmlForParsing = html =>
  parseHtmlParagraphs(addLazyLoading(parseHtmlShortcodes(html)))

export const htmlToJsx = (html, { prices = { showAbbr: false } } = {}) => {
  if (!html) {
    return null
  }
  const parseContent = prepareHtmlForParsing(html)
  const parseOptions = {
    replace: ({ attribs, children, name }) => {
      if (!attribs) {
        return
      }
      if (
        attribs["data-unit"] &&
        attribs["data-value"] &&
        distanceDecimals[attribs["data-unit"]] !== undefined
      ) {
        return (
          <DistanceValue
            value={parseFloat(attribs["data-value"])}
            unit={attribs["data-unit"]}
            decimals={distanceDecimals[attribs["data-unit"]]}
          />
        )
      }
      if (attribs["data-currency"] && attribs["data-value"]) {
        return (
          <CurrencyValue
            amount={parseFloat(attribs["data-value"])}
            code={attribs["data-currency"]}
            showAbbr={prices.showAbbr}
          />
        )
      }
      if (attribs["href"]) {
        const buttonLink =
          attribs["class"] && includesWord(attribs["class"], "button")
        if (attribs["class"]) {
          delete attribs["class"]
        }
        if (AffiliateLinks.isTaLink(attribs["href"])) {
          return buttonLink ? (
            <Button taLink {...attribs}>
              {domToReact(children, parseOptions)}
            </Button>
          ) : (
            <Link taLink {...attribs}>
              {domToReact(children, parseOptions)}
            </Link>
          )
        } else if (buttonLink) {
          return (
            <Button {...attribs}>{domToReact(children, parseOptions)}</Button>
          )
        }
      } else if (
        ["p", "div"].includes(name) &&
        attribs["class"] &&
        includesWord(attribs["class"], "button-group")
      ) {
        delete attribs["class"]
        return <ButtonGroup>{domToReact(children, parseOptions)}</ButtonGroup>
      }
    },
  }
  return parse(parseContent, parseOptions)
}

const StyledHtml = styled.div``
const HtmlContent = ({
  className,
  html,
  fancyHeaders,
  tableStyle,
  imageBlocks,
  prices,
  captionBlocks,
  disclosures,
}) => {
  const classObject = {
    [className]: className,
    content: true,
    "fancy-lists": true,
    "fancy-headers": fancyHeaders,
    "table-style": tableStyle,
    "image-blocks": imageBlocks,
    "caption-blocks": captionBlocks,
    disclosures: disclosures,
  }
  const htmlContentClass = classNames(classObject)
  const jsxConfig = {
    prices,
  }
  return (
    <StyledHtml className={htmlContentClass}>
      {htmlToJsx(html, jsxConfig)}
    </StyledHtml>
  )
}
HtmlContent.propTypes = {
  className: PropTypes.string,
  html: PropTypes.node,
  fancyHeaders: PropTypes.bool,
  tableStyle: PropTypes.bool,
  imageBlocks: PropTypes.bool,
  captionBlocks: PropTypes.bool,
  disclosures: PropTypes.bool,
  prices: PropTypes.shape({
    showAbbr: PropTypes.oneOf([true, false, "after", "after-full-size"]),
  }),
}
HtmlContent.defaultProps = {
  fancyHeaders: false,
  tableStyle: false,
  imageBlocks: false,
  captionBlocks: false,
  disclosures: false,
}
export default HtmlContent
