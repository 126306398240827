import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Button from "@src/components/core-button"
import { scrollbarStyle, scrollbarStyleOutline } from "@src/styles/elements"

const StyledInputWrapper = styled.div`
  position: relative;
  margin-bottom: 18px;

  ${props =>
    props.bottomBorder
      ? css`
          border-bottom: 1px solid lightgray;
          :not(.field-collapsed) {
            padding-bottom: 18px;
          }
        `
      : undefined}

  &.hidden {
    display: none;
  }

  &.field-collapsible {
    padding-right: 5px;
    .field-content {
      transition: max-height 0.3s ease-in;
      max-height: 60vh;
      overflow-y: auto;
      padding-right: 5px;
      font-family: system-ui;
      ${scrollbarStyle}
      ${scrollbarStyleOutline}
    }
    &.field-collapsed {
      .field-content {
        max-height: 0;
        overflow: hidden;
      }
    }
  }

  &.field-required {
    & label {
      &::after {
        content: "*";
        color: ${props => props.theme.status.error};
        margin-left: 2px;
      }
    }
  }
`
export const StyledRequired = styled.span`
  &::after {
    content: "*";
    color: ${props => props.theme.status.error};
    margin-left: 2px;
  }
`
export const StyledLabel = styled.label`
  display: block;
  font-family: system-ui;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;

  ${props =>
    props.collapsible &&
    css`
      margin-right: 32px;
      padding-bottom: 18px;
    `}
  ${props =>
    props.variant === "blue" &&
    css`
      color: ${props.theme.darkBlue};
    `}
`
const Description = styled.p`
  color: ${props => (props.hasError ? "red" : props.theme.darkGray)};
  font-style: italic;

  font-size: 12px;
  line-height: 15px;
  margin: 4px 0 0;

  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 16px;
    line-height: 20px;
  }
`
export const ErrorMessage = styled.p`
  font-weight: bold;
  color: ${props => props.theme.status.error};

  font-size: 12px;
  line-height: 15px;
  margin: 6px 0 0;

  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 16px;
    line-height: 20px;
  }
`
const CollapseButton = styled(Button)`
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  & > i.icon {
    line-height: 32px;
  }
`

const InputWrapper = ({
  className,
  children,
  forId,
  required,
  error,
  label,
  labelSuffix,
  labelIdSuffix,
  labelPassthrough,
  description,
  collapsible,
  variant,
  bottomBorder,
}) => {
  const [collapsed, setCollapsed] = useState(true)
  const handleCollapse = event => {
    event.preventDefault()
    setCollapsed(!collapsed)
  }
  const fieldClasses = className ? className.split(" ") : []
  if (collapsible) {
    fieldClasses.push("field-collapsible")
    if (collapsed) {
      fieldClasses.push("field-collapsed")
    }
  }
  if (required) {
    fieldClasses.push("field-required")
  }
  fieldClasses.unshift("field-wrapper")
  return (
    <StyledInputWrapper
      className={fieldClasses.join(" ")}
      bottomBorder={bottomBorder}
    >
      {label && !labelPassthrough ? (
        <StyledLabel
          className="field-label"
          htmlFor={forId}
          id={forId && labelIdSuffix ? `${forId}${labelIdSuffix}` : undefined}
          collapsible={collapsible}
          variant={variant}
        >
          {label}
        </StyledLabel>
      ) : null}
      {collapsible ? (
        <CollapseButton
          className="field-collapse"
          variant="icon"
          icon={collapsed ? "v2-angle-down" : "v2-angle-up"}
          onClick={handleCollapse}
          aria-label="collapse"
          aria-expanded={!collapsed}
        />
      ) : null}
      <div className="field-content">
        {children}
        {labelPassthrough && required && <StyledRequired />}
        {description ? (
          <Description className="field-description" hasError={!!error}>
            {description}
          </Description>
        ) : null}
        {error ? (
          <ErrorMessage className="field-error">{error}</ErrorMessage>
        ) : null}
      </div>
      {labelSuffix && !labelPassthrough ? (
        <StyledLabel
          className="field-label field-label-suffix"
          htmlFor={forId}
          id={
            forId && labelIdSuffix
              ? `${forId}${labelIdSuffix}-suffix`
              : undefined
          }
          variant={variant}
        >
          {labelSuffix}
        </StyledLabel>
      ) : null}
    </StyledInputWrapper>
  )
}
InputWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  forId: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  labelSuffix: PropTypes.string,
  labelIdSuffix: PropTypes.string,
  labelPassthrough: PropTypes.bool,
  description: PropTypes.string,
  collapsible: PropTypes.bool,
  variant: PropTypes.oneOf(["blue", "yellow", "routes-v2"]),
  bottomBorder: PropTypes.bool,
}
export default InputWrapper
