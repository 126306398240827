import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Icon from "@src/components/core-icon"
export const BasicStyledInput = css`
  background-color: ${props => props.theme.bg1};
  color: ${props => props.theme.darkGray};
  border: 1px solid ${props => props.theme.darkGray};
  border-radius: 4px;
  width: 100%;

  padding: 0 10px ${props => props.hasIcon && " 0 32px"};
  font-size: 16px;
  line-height: 20px;
  height: 34px;

  transition: all 0.2s ease-out;
  transition-property: color, border-color;
  &:focus {
    color: ${props => props.theme.fg1};
    border-color: ${props => props.theme.fg1};
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 0 12px ${props => props.hasIcon && " 0 40px"};
    font-size: 16px;
    line-height: 20px;
    height: 42px;
    font-weight: 500;
  }
`
const StyledInput = styled.input`
  ${BasicStyledInput}
`
const InputWrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`
const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 9px;
  color: ${props => props.theme.darkGray};
  &.icon {
    font-size: 16px;
    line-height: 16px;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 12px 10px;
    &.icon {
      font-size: 20px;
      line-height: 20px;
    }
  }
`
const InputField = ({ icon, register, validation, ...props }) => {
  const ref = register ? register(validation) : React.createRef()
  return icon ? (
    <InputWrapper
      onClick={() => {
        ref?.current?.focus()
      }}
    >
      <StyledInput ref={ref} hasIcon={true} {...props} />
      <StyledIcon glyph={icon} />
    </InputWrapper>
  ) : (
    <StyledInput ref={ref} {...props} />
  )
}
InputField.propTypes = {
  icon: PropTypes.string,
  register: PropTypes.func,
  validation: PropTypes.object,
}
export default InputField
