import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ModalContainer from "@src/components/container-modal"
import { UserContext } from "@src/context/user-context"
import Form, { FormActions } from "@src/components/form"
import FormFieldEmailAddress from "@src/components/form-field-email"
import FormFieldCreatePassword from "@src/components/form-field-password-create"
import FormFieldInputPassword from "@src/components/form-field-input-password"
// import FormFieldInputCheckbox from "@src/components/form-field-input-checkbox"
import FormFieldVerificationCode from "@src/components/form-field-verification-code"
import Button from "@src/components/core-button"
import Link, { navigate } from "@src/components/core-link"
import Image, { forceLazyLoad } from "@src/components/core-image"
import Spinner from "@src/components/core-spinner"

const Modal = styled(ModalContainer)`
  width: 393px;
  padding: 30px 40px;
  top: 50%;
  transform: translate(-50%, -50%);

  // X Button
  & > button {
    background-color: transparent;
    color: #1f1f23; // New scheme - Natasha
    box-shadow: none;
    width: fit-content;
    height: fit-content;
    top: 20px;
    right: 20px;
    & > i.icon.icon-v2-close {
      font-size: 1.5rem;
      line-height: normal;
    }
  }

  // Content
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    padding: 0;

    // Font
    & > * {
      font-family: system-ui; // New scheme - Natasha
      letter-spacing: 0.5px; // New scheme - Natasha
      font-size: 1rem;
      line-height: 1.5rem;
    }

    & > p.h2 {
      font-family: system-ui; // New scheme - Natasha
      letter-spacing: 0.5px; // New scheme - Natasha
      font-size: 1.625rem;
      text-align: center;
      // Required for underline
      line-height: 3rem;
      height: 3rem;
      &.multi-line {
        line-height: 1.5em;
        height: auto;
       }
      // Curved diamond
      & > div.lazyload-wrapper {
        vertical-align: middle;
      }
      // Underline
      & > span.underline {
        display: inline-block;
        height: 100%;
        background-image: url(/images/user-flow-login-underline.svg);
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: center;
      }
      &.forgot {
        width: 280px;
        margin-left: auto;
        margin-right: auto;
        background: url(/images/user-flow-forgot-password-splash.svg);
        background-repeat: no-repeat;
        background-position-x: 246px;
      }
      &.reset {
        width: 280px;
        margin-left: auto;
        margin-right: auto;
        background: url(/images/user-flow-reset-password.svg);
        background-repeat: no-repeat;
        background-position-x: 260px;
        background-position-y: center;
      }
      &.verify {
        width: 280px;
        margin-left: auto;
        margin-right: auto;
        background: url(/images/user-flow-verify-arrow.svg);
        background-repeat: no-repeat;
        background-position-x: 250px;
        background-position-y: 80%;
      }
    }
    & > .center {
      text-align: center;
    }
    & .bold {
      font-weight: bold;
    }
    // All Set Styling
    & img.center {
        margin: 20px 0;
    }
    // Green links
    & a.green {
      text-decoration: none;
      color: #41c689; // New scheme - Natasha
      font-weight: 700;
    }

    // Required for full fields
    & form {
      width: 100%;
    }
    // Input fields
    & div.form-field {
      margin-bottom: 16px;
      & label {
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 6px;
      }

      & input {
        letter-spacing: 0.5px;
        &:not([type='checkbox']) {
          line-height: 1.25rem;
          height: 50px;
        }
      }

      & .icon {
        font-size: 1.25rem;
        line-height: 32px;
      }
    }

    & button {
      width: 300px;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      letter-spacing: 0.5px;
      height: 44px;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 0;
      &:last-of-type {
        margin-bottom: 24px;
      }

      &:hover {
        background-color: ${props => props.theme.lightPrimary};
      }

      &:focus {
        background-color: ${props => props.theme.darkPrimary};
        box-shadow: ${props => props.theme.shadows.darkPrimary};
      }
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 528px;

    // Content
  & > div {
    & button {
      width: 350px;
    }
  }
`

const UserActions = ({ defaultVisualMode, isOpen, setIsOpen }) => {
  const [showModal, setShowModal] = useState(false)
  const [signUpStatus, setSignUpStatus] = useState({
    pending: false,
    success: false,
    formMessage: null,
  })
  const [loginStatus, setLoginStatus] = useState({
    pending: true,
    success: null,
    formMessage: null,
  })
  const [forgotPWStatus, setForgotPWStatus] = useState({
    pending: false,
    success: null,
    formMessage: null,
  })
  const [resetPWStatus, setResetPWStatus] = useState({
    pending: false,
    success: null,
    formMessage: null,
  })
  const [verificationStatus, setVerificationStatus] = useState({
    pending: false,
    success: false,
    formMessage: null,
  })
  const [verificationResendStatus, setVerificationResendStatus] = useState({
    pending: false,
    success: false,
    formMessage: null,
  })
  const [email, setEmail] = useState("")
  const [visualMode, setVisualMode] = useState(defaultVisualMode || "SIGNUP")
  const [visualHistory, setVisualHistory] = useState([])
  const { user } = useContext(UserContext)
  // Open login, signup or other required visual modes from outside of the component - example: user menu
  useEffect(() => {
    if (defaultVisualMode) {
      setVisualMode(defaultVisualMode)
    }
  }, [defaultVisualMode])

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])
  //  Track visual history
  useEffect(() => {
    if (visualMode) {
      setVisualHistory(prev => [...prev, visualMode])
    }
  }, [visualMode])
  // Reset login modal when user logs out
  useEffect(() => {
    if (!user) {
      setLoginStatus({
        pending: false,
        success: null,
        formMessage: null,
      })
    }
  }, [user])

  // Required to load the diamond image
  useEffect(() => {
    if (showModal) {
      forceLazyLoad()
    }
  }, [showModal])

  // Close Modal (internal and external state management)
  const handleModalClose = () => {
    setShowModal(false)
    setIsOpen(false)
    setVisualHistory([])
    setSignUpStatus({
      pending: false,
      success: null,
      formMessage: null,
    })
    setForgotPWStatus({
      pending: false,
      success: null,
      formMessage: null,
    })
    setResetPWStatus({
      pending: false,
      success: null,
      formMessage: null,
    })
    setVerificationStatus({
      pending: false,
      success: null,
      formMessage: null,
    })
    setVerificationResendStatus({
      pending: false,
      success: null,
      formMessage: null,
    })
  }

  // Switch Between Visual Modes
  const switchToLogin = event => {
    event.preventDefault()
    setVisualMode("LOGIN")
  }
  const switchToSignUp = event => {
    event.preventDefault()
    setVisualMode("SIGNUP")
  }
  const switchToForgot = event => {
    event.preventDefault()
    setVisualMode("FORGOT")
  }
  const switchToVerify = event => {
    event.preventDefault()
    setVisualMode("VERIFY")
  }
  const switchToVerificationResend = event => {
    event.preventDefault()
    setVisualMode("CODE_RESEND")
  }
  const switchToReset = event => {
    event.preventDefault()
    setVisualMode("RESET_PW")
  }
  const isBehindLoginWall =
    visualHistory.length && visualHistory[0] === "LOGIN_REQUIRED"

  const navigateToHome = () => {
    navigate("/")
  }
  // Modal Content
  const VISUAL_MODES = {
    // Signup
    SIGNUP: (
      <>
        <p className="h2">
          Create an account{" "}
          <Image src="/images/user-flow-sign-up-diamond.svg" />
        </p>
        <p className="center">
          {signUpStatus.success
            ? `A verification code has been sent to ${
                email ? email : "your email"
              }.`
            : "Start your adventure today."}
        </p>
        <UserContext.Consumer>
          {({ signup }) => {
            const handleSubmit = ({ email, password }) => {
              setSignUpStatus({ ...signUpStatus, pending: true })
              signup(email, password)
                .then(() => {
                  setSignUpStatus({
                    success: true,
                    pending: false,
                    formMessage: null,
                  })
                })
                .catch(error => {
                  setSignUpStatus({
                    success: false,
                    pending: false,
                    formMessage: error,
                  })
                })
            }
            return (
              <Form
                name="user-signup-modal"
                onSubmit={handleSubmit}
                variant="blue"
                netlify
              >
                {signUpStatus.success ? null : (
                  <>
                    <FormFieldEmailAddress
                      id="email"
                      name="email"
                      placeholder="Please enter your email address"
                      label="Email"
                      defaultValue={email}
                      onChange={setEmail}
                      validation={{
                        required:
                          "We'll need your email address to create an account",
                      }}
                    />
                    <FormFieldCreatePassword
                      id="password"
                      name="password"
                      label="Password"
                      placeholder="Set your password"
                    />

                    {/* <FormFieldInputCheckbox
                  id="subscribe-newsletter"
                  name="subscribe_newsletter"
                  label="Subscribe to our newsletter for free gear giveaways and discounts on adventure travel!"
                /> */}
                  </>
                )}
                <FormActions>
                  {signUpStatus.formMessage && (
                    <p className="form-error">{signUpStatus.formMessage}</p>
                  )}
                  {signUpStatus.success ? (
                    <Button variant="rounded" onClick={switchToVerify}>
                      Verify Account
                    </Button>
                  ) : (
                    <Button
                      variant="rounded"
                      type="submit"
                      disabled={signUpStatus.pending}
                    >
                      Sign Up{" "}
                      {signUpStatus.pending && (
                        <Spinner color="white" size="sm" />
                      )}
                    </Button>
                  )}
                  {isBehindLoginWall && (
                    <Button
                      className="center"
                      variant="rounded"
                      color="outline"
                      onClick={navigateToHome}
                    >
                      Back to homepage
                    </Button>
                  )}
                  <p>
                    Already a member?{" "}
                    <Link onClick={switchToLogin} className="green">
                      Login
                    </Link>
                  </p>
                </FormActions>
              </Form>
            )
          }}
        </UserContext.Consumer>
      </>
    ),
    // Login
    LOGIN: (
      <>
        <p className="h2">
          Login to your <span className="underline2">account</span>
        </p>
        <p className="center">Start your adventure today.</p>
        <UserContext.Consumer>
          {({ signin }) => {
            const handleSubmit = ({ email, password }) => {
              setLoginStatus({ ...loginStatus, pending: true })
              signin(email, password)
                .then(() => {
                  setLoginStatus({
                    success: true,
                    pending: false,
                    formMessage: null,
                  })
                })
                .catch(error => {
                  setLoginStatus({
                    success: false,
                    pending: false,
                    formMessage: error,
                  })
                })
            }
            return (
              <Form name="user-signin" onSubmit={handleSubmit}>
                {loginStatus.success ? null : (
                  <>
                    <FormFieldEmailAddress
                      id="email"
                      name="email"
                      placeholder="Enter your email address"
                      label="Email"
                      defaultValue={email}
                      onChange={setEmail}
                      validation={{
                        required: "Enter your email address to log in",
                      }}
                    />
                    <FormFieldInputPassword
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      label="Password"
                      type="password"
                      validation={{
                        required: "Enter your password to log in",
                      }}
                    />
                  </>
                )}
                <FormActions>
                  {loginStatus.formMessage && (
                    <p className="form-error">{loginStatus.formMessage}</p>
                  )}
                  {loginStatus.success ? (
                    <Button variant="rounded" onClick={handleModalClose}>
                      Start Browsing
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="rounded"
                        type="submit"
                        disabled={loginStatus.pending}
                      >
                        Login{" "}
                        {loginStatus.pending && (
                          <Spinner color="white" size="sm" />
                        )}
                      </Button>
                      {isBehindLoginWall && (
                        <Button
                          className="center"
                          variant="rounded"
                          color="outline"
                          onClick={navigateToHome}
                        >
                          Back to homepage
                        </Button>
                      )}
                      <p>
                        {`Don't have an account? `}
                        <Link onClick={switchToSignUp} className="green">
                          Sign up
                        </Link>
                      </p>
                      <p>
                        Forgot password?{" "}
                        <Link onClick={switchToForgot} className="green">
                          Click here
                        </Link>
                      </p>
                      <p>
                        Need to verify?{" "}
                        <Link onClick={switchToVerify} className="green">
                          Get verified
                        </Link>
                      </p>
                    </>
                  )}
                </FormActions>
              </Form>
            )
          }}
        </UserContext.Consumer>
      </>
    ),
    // Forgot Password
    FORGOT: (
      <>
        <p className="h2 forgot">Forgot password?</p>
        <p className="center">
          {forgotPWStatus.success
            ? `The code has been sent to ${email}.`
            : `Enter your email and we'll send you a code to change your password in the next step.`}
        </p>
        <UserContext.Consumer>
          {({ recover }) => {
            const handleSubmit = ({ email }) => {
              setForgotPWStatus({ ...forgotPWStatus, pending: true })
              recover(email)
                .then(() => {
                  setForgotPWStatus({
                    success: true,
                    pending: false,
                    formMessage: null,
                  })
                })
                .catch(error => {
                  setForgotPWStatus({
                    success: false,
                    pending: false,
                    formMessage: error,
                  })
                })
            }
            return (
              <Form
                name="user-account-recovery"
                onSubmit={handleSubmit}
                variant="blue"
              >
                {forgotPWStatus.success ? null : (
                  <FormFieldEmailAddress
                    id="email"
                    name="email"
                    placeholder="Enter your email address"
                    label="Email"
                    defaultValue={email}
                    onChange={setEmail}
                    validation={{
                      required:
                        "We'll send an email with a password reset code",
                    }}
                  />
                )}
                <FormActions>
                  {forgotPWStatus.formMessage && (
                    <p className="form-error">{forgotPWStatus.formMessage}</p>
                  )}
                  {forgotPWStatus.success ? (
                    <Button variant="rounded" onClick={switchToReset}>
                      Reset Password
                    </Button>
                  ) : (
                    <Button
                      variant="rounded"
                      type="submit"
                      disabled={forgotPWStatus.pending}
                    >
                      Submit{" "}
                      {forgotPWStatus.pending && (
                        <Spinner color="white" size="sm" />
                      )}
                    </Button>
                  )}
                  {isBehindLoginWall && (
                    <Button
                      className="center"
                      variant="rounded"
                      color="outline"
                      onClick={navigateToHome}
                    >
                      Back to homepage
                    </Button>
                  )}
                  <p>
                    Have a reset code?{" "}
                    <Link className="green" onClick={switchToReset}>
                      Reset password
                    </Link>
                  </p>
                  <p>
                    Remembered your password?{" "}
                    <Link className="green" onClick={switchToLogin}>
                      Log in
                    </Link>
                  </p>
                </FormActions>
              </Form>
            )
          }}
        </UserContext.Consumer>
      </>
    ),
    // Reset/New PW
    RESET_PW: resetPWStatus.success ? (
      <>
        <p className="h2">{`You're all set!`}</p>
        <p className="center">
          Your password has been reset. Let all the adventures begin!
        </p>
        <Image src="/images/user-flow-all-set.svg" className="center" />
        <Button
          variant="rounded"
          type="submit"
          className="center"
          onClick={switchToLogin}
        >
          Go to login
        </Button>
      </>
    ) : (
      <>
        <p className="h2 reset">Set new password</p>
        {resetPWStatus.success ? (
          <p className="center">{`Password has been reset!`}</p>
        ) : null}

        <UserContext.Consumer>
          {({ recoverPassword }) => {
            const handleSubmit = ({ email, code, password }) => {
              setResetPWStatus({ ...resetPWStatus, pending: true })
              recoverPassword(email, code, password)
                .then(() => {
                  setResetPWStatus({
                    success: true,
                    pending: false,
                    formMessage: null,
                  })
                })
                .catch(error => {
                  setResetPWStatus({
                    success: false,
                    pending: false,
                    formMessage: error,
                  })
                })
            }
            return (
              <Form
                name="user-password-reset"
                onSubmit={handleSubmit}
                variant="blue"
              >
                <FormFieldEmailAddress
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  label="Email"
                  defaultValue={email}
                  onChange={setEmail}
                  validation={{
                    required: "Enter your email address to reset password",
                  }}
                />
                <FormFieldVerificationCode
                  id="code"
                  name="code"
                  placeholder="Enter 6-digit password reset code"
                  label="Enter your password reset code"
                  validation={{
                    required: "Check your email for the password reset code",
                  }}
                />
                <FormFieldCreatePassword
                  id="password"
                  name="password"
                  label="New password"
                  placeholder="Set a new password"
                />
                <FormActions>
                  {resetPWStatus.formMessage && (
                    <p className="form-error">{resetPWStatus.formMessage}</p>
                  )}
                  <Button
                    variant="rounded"
                    type="submit"
                    disabled={resetPWStatus.pending}
                  >
                    Reset password{" "}
                    {resetPWStatus.pending && (
                      <Spinner color="white" size="sm" />
                    )}
                  </Button>
                  <Button
                    variant="rounded"
                    color="outline"
                    onClick={switchToLogin}
                  >
                    Back to login
                  </Button>
                  <p>
                    Need a reset code?{" "}
                    <Link className="green" onClick={switchToForgot}>
                      Get one now
                    </Link>
                  </p>
                </FormActions>
              </Form>
            )
          }}
        </UserContext.Consumer>
      </>
    ),
    // Verification
    VERIFY: (
      <>
        <p className="h2 verify">Verify your email</p>
        {verificationStatus.success ? (
          <p className="center">Your account has been verified!</p>
        ) : email ? (
          <p className="center">
            {`We've sent a 6-digit code to `}
            <span className="bold">{email}</span>
          </p>
        ) : (
          <p className="center">{`Please check your email for a 6-digit code.`}</p>
        )}
        <UserContext.Consumer>
          {({ verify }) => {
            const handleSubmit = ({ email, code }) => {
              setVerificationStatus({ ...verificationStatus, pending: true })
              verify(email, code)
                .then(() => {
                  setVerificationStatus({
                    success: true,
                    pending: false,
                    formMessage: null,
                  })
                })
                .catch(error => {
                  setVerificationStatus({
                    success: false,
                    pending: false,
                    formMessage: error,
                  })
                })
            }
            return (
              <Form
                name="user-verification"
                onSubmit={handleSubmit}
                variant="blue"
              >
                {verificationStatus.success ? null : (
                  <>
                    <FormFieldEmailAddress
                      id="email"
                      name="email"
                      placeholder="Enter email address for verification"
                      label="Email address"
                      defaultValue={email}
                      onChange={setEmail}
                      validation={{
                        required:
                          "We'll need your email address to verify the account",
                      }}
                    />
                    <FormFieldVerificationCode
                      id="code"
                      name="code"
                      placeholder="Enter your 6-digit verification code"
                      label="Verification code"
                      validation={{
                        required:
                          "Please check your email for the verification code",
                      }}
                    />
                  </>
                )}
                <FormActions>
                  {verificationStatus.formMessage && (
                    <p className="form-error">
                      {verificationStatus.formMessage}
                    </p>
                  )}
                  {verificationStatus.pending ? (
                    <Button variant="rounded" disabled>
                      Verifying <Spinner color="white" size="sm" />
                    </Button>
                  ) : (
                    <>
                      {verificationStatus.success ? null : (
                        <Button variant="rounded" type="submit">
                          Verify Account
                        </Button>
                      )}
                      <Button
                        variant="rounded"
                        color="outline"
                        onClick={switchToLogin}
                      >
                        Back to login
                      </Button>
                    </>
                  )}
                  {verificationStatus.success ? null : (
                    <>
                      <p>
                        No verification code?{" "}
                        <Link
                          className="green"
                          onClick={switchToVerificationResend}
                        >
                          Get one here
                        </Link>
                      </p>
                      <p>
                        {`Don't have an account? `}
                        <Link onClick={switchToSignUp} className="green">
                          Sign up
                        </Link>
                      </p>
                    </>
                  )}
                </FormActions>
              </Form>
            )
          }}
        </UserContext.Consumer>
      </>
    ),
    // Verification Resend - Is this needed as a modal?
    CODE_RESEND: (
      <>
        <p className="h2">Get Verification Code</p>
        {verificationResendStatus.success ? (
          <>
            <p className="center">New verification code has been sent!</p>
            <p className="center">
              Please check your email for further instructions.
            </p>
          </>
        ) : null}
        <UserContext.Consumer>
          {({ verifyResend }) => {
            const handleSubmit = ({ email }) => {
              setVerificationResendStatus({
                ...verificationResendStatus,
                pending: true,
              })
              verifyResend(email)
                .then(() => {
                  setVerificationResendStatus({
                    success: true,
                    pending: false,
                    formMessage: null,
                  })
                })
                .catch(error => {
                  setVerificationResendStatus({
                    success: false,
                    pending: false,
                    formMessage: error,
                  })
                })
            }
            return (
              <Form
                name="user-verification-resend"
                onSubmit={handleSubmit}
                variant="blue"
              >
                {verificationResendStatus.success ? null : (
                  <FormFieldEmailAddress
                    id="email"
                    name="email"
                    placeholder="Enter email address to get a new code"
                    label="Email address"
                    validation={{
                      required:
                        "We'll need your email address to send a new code",
                    }}
                  />
                )}
                <FormActions>
                  {verificationResendStatus.formMessage && (
                    <p className="form-error">
                      {verificationResendStatus.formMessage}
                    </p>
                  )}
                  {verificationResendStatus.pending ? (
                    <Button variant="rounded" disabled>
                      Sending <Spinner color="white" size="sm" />
                    </Button>
                  ) : (
                    <>
                      {verificationResendStatus.success ? (
                        <Button variant="rounded" onClick={switchToVerify}>
                          Verify Account
                        </Button>
                      ) : (
                        <Button variant="rounded" type="submit">
                          Resend Verification
                        </Button>
                      )}
                    </>
                  )}
                  {isBehindLoginWall && (
                    <Button
                      className="center"
                      variant="rounded"
                      color="outline"
                      onClick={navigateToHome}
                    >
                      Back to homepage
                    </Button>
                  )}
                  {verificationResendStatus.success ? null : (
                    <p>
                      Already have a code?{" "}
                      <Link className="green" onClick={switchToVerify}>
                        Enter it now
                      </Link>
                    </p>
                  )}
                </FormActions>
              </Form>
            )
          }}
        </UserContext.Consumer>
      </>
    ),
    // Login Wall
    LOGIN_REQUIRED: (
      <>
        <p className="h2 multi-line">
          We know the trails are calling, please sign up or login for free
          access.
        </p>
        <p className="center">
          Log into your account and start your adventure today!
        </p>
        <Button className="center" variant="rounded" onClick={switchToLogin}>
          Login{" "}
        </Button>
        <Button className="center" variant="rounded" onClick={switchToSignUp}>
          Sign Up{" "}
        </Button>
        <Button
          className="center"
          variant="rounded"
          color="outline"
          onClick={navigateToHome}
        >
          Back to homepage
        </Button>
      </>
    ),
  }
  return (
    <Modal
      id="user-flow"
      isOpen={showModal}
      onClose={handleModalClose}
      closeIcon="v2-close"
    >
      {VISUAL_MODES[visualMode]}
    </Modal>
  )
}

UserActions.propTypes = {
  defaultVisualMode: PropTypes.oneOf([
    "SIGNUP",
    "LOGIN",
    "FORGOT",
    "RESET_PW",
    "VERIFY",
    "CODE_RESEND",
    "RECOVERY",
    "ALL_SET",
    "LOGIN_REQUIRED",
  ]),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default UserActions
