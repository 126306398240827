import { FormData, exists } from "browser-monads"
import axios from "axios"

const taLinkHrefRx = new RegExp(
  `^${process.env.GATSBY_WP_LINKS_BASE_RX.replaceAll(".", "\\.")}/${
    process.env.GATSBY_TA_LINK_PREFIX
  }/.+`,
  "i"
)
const isTaLink = href => taLinkHrefRx.test(href)

const TA_DEBOUNCE_TIME = 1000

const lookupTaLinks = async links => {
  if (!exists(FormData)) {
    return
  }
  const formData = new FormData()
  formData.append("action", "ta_link_fixer")
  for (const [i, link] of links.entries()) {
    for (const [key, value] of Object.entries(link)) {
      formData.append(`hrefs[${i}][${key}]`, value)
    }
  }
  try {
    const result = await axios.post(process.env.GATSBY_REST_ENDPOINT, formData)
    if (result && result.data) {
      return result.data
    }
    return null
  } catch (error) {
    console.log("Error in lookupTaLinks request: ", error)
    return null
  }
}

// each call to lookupTaLinks returns its own promise
// all calls within the debounce batch resolve at once (when remote call completes)
// the debounced batch is reset as soon as the remote call is initiated
//  (active batch data is preserved in local scope for result handling)
async function lookupTaLinksDebounced(links) {
  // assert there is a debounced batch available
  if (!this.debounced) {
    this.debounced = {
      links: [],
      calls: [],
      running: null,
    }
  }

  // each request gets its own promise
  const result = new Promise((resolve, reject) => {
    const requestLinks = []
    for (const link of links) {
      let requestLink = this.debounced.links.find(check => check.href === link)
      if (!requestLink) {
        requestLink = {
          key: this.debounced.links.length,
          href: link,
        }
        this.debounced.links.push(requestLink)
      }
      if (
        requestLink &&
        !requestLinks.some(check => check.href === requestLink.href)
      ) {
        requestLinks.push(requestLink)
      }
    }
    // all requests are collected into a debounced list
    this.debounced.calls.push({
      resolve,
      reject,
      links: requestLinks,
    })
  })

  // debounce the active timeout
  if (this.debounced.running) {
    clearTimeout(this.debounced.running)
    this.debounced.running = null
  }
  this.debounced.running = setTimeout(async () => {
    // capture activeRequest and reset debounced state
    const activeRequest = this.debounced
    this.debounced = null
    const result = await lookupTaLinks(activeRequest.links)
    for (const call of activeRequest.calls) {
      call.resolve(
        result && result.data
          ? result.data.reduce((result, item) => {
              const orig = call.links.find(check => check.key === item.key)
              if (orig) {
                result.push({
                  orig: orig.href,
                  href: item.href,
                  title: item.title,
                  rel: item.rel,
                  link_id: item.link_id,
                })
              }
              return result
            }, [])
          : []
      )
    }
  }, TA_DEBOUNCE_TIME)
  return result
}

async function trackTaLinkClick({ link_id, page, href }) {
  if (!exists(FormData)) {
    return
  }
  const formData = new FormData()
  formData.append("action", "ta_click_data_redirect")
  if (link_id) {
    formData.append("link_id", link_id)
  }
  if (page) {
    formData.append("page", page)
  }
  if (href) {
    formData.append("href", href)
  }
  return await axios.post(process.env.GATSBY_REST_ENDPOINT, formData)
}

const AffiliateLinks = {
  isTaLink,
  lookupTaLinks,
  lookupTaLinksDebounced,
  trackTaLinkClick,
}
export default AffiliateLinks
