import { css } from "styled-components"
import theme from "@src/styles/theme"

export const scrollbarStyle = css`
  &::-webkit-scrollbar {
    width: 12px;
    height: 10px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }
`
export const scrollbarStyleOutline = css`
  &::-webkit-scrollbar-track {
    border: 1px solid ${theme.scrollbar.base.trackOutline};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.scrollbar.base.thumb};
  }
`

/* IconFont10a copied from /static/icons/icons-10a/style.css */
export const IconFont10a = css`
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons-10a" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const utilityStyles = css`
  .all-caps {
    text-transform: uppercase;
  }
`

const imgStyles = css`
  img {
    max-width: 100%;
    height: auto;
  }
`

const inputItems = css`
  ::placeholder {
    color: ${theme.lightGray};
    opacity: 1;
  }
`

export const FancyListBullets = css`
  font-size: 1rem;
  line-height: 1.125em;
  margin-bottom: 1em;
  list-style-type: none;
  /* Nested lists should not have a gap if followed by other li */
  & ul {
    margin: 0;
  }
  /* Yellow bullets */
  & li {
    position: relative;
    padding-left: 15px;
    margin-bottom: 0.5em;

    &::before,
    & ul::before {
      position: absolute;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      border-radius: 50%;
      background-color: ${theme.primary};
    }
    /* Overrides double bullets for nested lists */
    & ul::before {
      background-color: ${theme.white};
    }
  }
`
export const FancyListOrdered = css`
  font-size: 1rem;
  line-height: 1.125em;
  margin-bottom: 1em;
  list-style-type: none;
  counter-reset: item;
  & li {
    position: relative;
    padding-left: 18px;
    counter-increment: item;
    margin-bottom: 0.5em;
    &::before {
      position: absolute;
      content: counter(item) ".";
      color: ${theme.primary};
      left: 0px;
    }
  }
`
const ListChecks = css`
  list-style-type: none;
  & > li {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: 0.5em;
    &::before {
      ${IconFont10a};
      content: "\\f00c";
      position: absolute;
      left: 0;
      top: 0;
      font-size: 1.125em;
      color: ${theme.primary};
    }
  }
`
export const listItems = css`
  ul.primary {
    ${FancyListBullets}
  }
  ol.primary {
    ${FancyListOrdered}
  }
  ul.checks {
    ${ListChecks}
  }
  .fancy-lists {
    & ul {
      ${FancyListBullets}
    }
    & ol {
      ${FancyListOrdered}
    }
  }
`
const yellowBlock = css`
  position: relative;
  padding-left: 15px;
  &::before {
    content: "";
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: 0;
    width: 8px;
    background-color: ${theme.primary};
  }
`

const fancyHeader = css`
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    &.fancy:not(.sub-header) {
      ${yellowBlock}
    }
  }
  h2.sub-header,
  .h2.sub-header,
  h3.sub-header,
  .h3.sub-header {
    text-align: center;
    margin: 10px 0;
  }
`
const fancyHeaders = css`
  .fancy-headers {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      &:not(.sub-header) {
        ${yellowBlock}
      }
    }
  }
`
export const FancyLink = css`
  position: relative;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 2px 1px;
  max-width: 100%;
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: ${theme.primary};
    border-radius: 5px;
    top: 50%;
    left: 0;
    height: 10px;
    opacity: 0;
    width: 0;
  }
  &.active,
  &:hover {
    color: ${theme.black};
    &::before {
      animation: 0.15s ease-out 0s fancyUnderline;
      opacity: 1;
      width: 100%;
    }
  }
`
const fancyLinkStyles = css`
  @keyframes fancyUnderline {
    0% {
      width: 0;
      opacity: 0;
    }
    100% {
      width: 100%;
      opacity: 1;
    }
  }
  .fancy-link {
    ${FancyLink}
  }
`
const tableStyle = css`
  .table-style {
    table {
      width: 100%;
      border-collapse: collapse;
      margin: 0 auto 1em auto;
      border: 1px solid black;
      & tbody {
        text-align: left;
        vertical-align: top;
        & tr {
          border: 1px solid black;
          & th {
            padding: 10px 15px;
            border-left: 1px solid black;
          }
          & td {
            padding: 10px 15px;
            border-left: 1px solid black;
          }
        }
      }
    }
  }
`
const imageBlocks = css`
  .image-blocks {
    .image-block {
      width: 100%;
      & > img {
        height: auto;
        max-width: 100%;
      }
      &.image-desktop {
        display: none;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    .image-blocks {
      .image-block {
        margin: 0 -5px 6px;
        & > img {
          margin: 0 5px 8px;
        }
        &.image-desktop {
          display: block;
        }
        &.image-mobile {
          display: none;
        }
      }
    }
  }
`
const captionBlocks = css`
  .caption-blocks {
    .caption-block {
      background-color: ${props => props.theme.backgroundGray};
      border-left: 5px solid ${props => props.theme.primary};
      padding: 15px 20px;
      font-weight: 500;
      font-size: 1.1em;
      line-height: 1.25;
    }
  }
`
const disclosureBlocks = css`
  .disclosures {
    .disclosure {
      font-size: 0.75em;
      line-height: 2;
    }
  }
`

export const whiteTextBlackOutline = css`
  color: ${props => props.theme.white};
  text-shadow: 2px 2px ${props => props.theme.black};
`
const elementStyles = css`
  ${utilityStyles}
  ${imgStyles}
  ${inputItems}
  ${listItems}
  ${fancyHeader}
  ${fancyHeaders}
  ${fancyLinkStyles}
  ${tableStyle}
  ${imageBlocks}
  ${captionBlocks}
  ${disclosureBlocks}
`
export default elementStyles
