export const SOCIAL_MEDIA = {
  instagram: {
    name: "Instagram",
    link: "https://www.instagram.com/10adventures/",
    icon: "instagram",
    tag: "@10Adventures",
  },
  facebook: {
    name: "Facebook",
    link: "https://www.facebook.com/10adventures/",
    icon: "facebook",
    tag: "@10Adventures",
  },
  pinterest: {
    name: "Pinterest",
    link: "https://www.pinterest.com/10adventures/",
    icon: "pinterest",
    tag: "@10Adventures",
  },
  twitter: {
    name: "Twitter",
    link: "https://twitter.com/10adventures/",
    icon: "twitter",
    tag: "@10Adventures",
  },
  linkedin: {
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/10adventures/",
    icon: "linkedin",
    tag: "@10Adventures",
  },
}
