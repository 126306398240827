import React, { useState, forwardRef } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Icon from "@src/components/core-icon"

const CollapsibleDiv = styled.div`
  & > .collapsible-header {
    display: flex;
    justify-content: space-between;
    & > .collapsible-icon {
      background: transparent;
      transition: transform 0.2s ease;
      &.rotated {
        transform: rotate(180deg);
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  &.collapsible-div > .collapsible-content.hidden {
    display: none !important;
  }

  ${props =>
    props.collapse === "tablet"
      ? css`
          @media only screen and (${props => props.theme.screen.small.min}) {
            &.collapsible-div > .collapsible-header {
              & > .collapsible-icon.icon {
                display: none;
              }
            }
            &.collapsible-div > .collapsible-content.hidden {
              display: flex;
            }
          }
        `
      : null}
`

const CollapsibleContent = forwardRef(
  (
    { className, defaultOpen, header, content, collapse, icon, handleClick },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(defaultOpen)

    return (
      <CollapsibleDiv
        className={`collapsible-div ${className ? className : ""}`}
        collapse={collapse}
      >
        <div
          ref={ref}
          className="collapsible-header"
          onClick={() => (handleClick ? handleClick() : setIsOpen(!isOpen))}
        >
          {header}
          {icon && (
            <Icon
              className={`collapsible-icon ${isOpen ? "rotated" : ""}`}
              variant="icon"
              glyph={icon}
            />
          )}
        </div>
        <div className={`collapsible-content ${!isOpen ? "hidden" : ""}`}>
          {content}
        </div>
      </CollapsibleDiv>
    )
  }
)

CollapsibleContent.displayName = "CollapsibleContent"
CollapsibleContent.propTypes = {
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  collapse: PropTypes.oneOf(["tablet", "all"]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleClick: PropTypes.func,
}

CollapsibleContent.defaultProps = {
  defaultOpen: true,
  collapse: "all",
  icon: "circle-chevron-bottom",
}
export default CollapsibleContent
