function mapChildren(itemId, items) {
  const children = items.filter(item => item.parentId === itemId)
  children.forEach(item => (item.items = mapChildren(item.id, items)))
  return children
}
export const mapListToTree = (items, parentId = null) =>
  mapChildren(parentId, items)

function flattenDescendants(items) {
  if (!items || !items.length) {
    return []
  }
  return items.reduce((result, item) => {
    result.push(item)
    if (item && item.items) {
      const children = flattenDescendants(item.items)
      if (children && children.length) {
        result = result.concat(children)
      }
    }
    return result
  }, [])
}
export const mapTreeToList = treeTop => flattenDescendants(treeTop)

export const getAncestorList = (itemId, list) => {
  const result = []
  let addItemId = itemId
  while (addItemId) {
    const addItem = list.find(check => check.id === addItemId)
    if (addItem) {
      result.unshift(addItem.id)
      addItemId = addItem.parentId
    } else {
      addItemId = null
    }
  }
  return result
}
