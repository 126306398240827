import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import SubscriptionForm from "@src/components/form-subscription"

const StyledSubcription = styled(SubscriptionForm)`
  max-width: 500px;
  margin: 0 auto;
`
const BannerFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 475px;
  margin-top: 20px;
  background-color: #fbf8f5; // Natasha design
  & > .contents {
    font-family: system-ui;
    position: relative;
    z-index: 1;
    align-self: center;
    padding: 30px;
    & button {
      background-color: #26383b; // Natasha design
      font-weight: 600;
      &:hover {
        background-color: #315254; // Natasha design
      }
    }
    & > p {
      text-align: center;
      color: #667085; // Natasha design
    }
    & > h2 {
      font-family: system-ui;
      text-align: center;
      font-weight: 900;
    }
  }
  & label {
    font-weight: 500;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .contents {
      & > h2 {
        font-size: 40px;
      }
    }
  }
`
const WidgetFormContainer = styled.div`
  width: 100%;
`
const BannerForm = ({ className, children }) => {
  return (
    <BannerFormContainer className={className}>{children}</BannerFormContainer>
  )
}
const WidgetForm = ({ className, children }) => {
  return (
    <WidgetFormContainer className={className}>{children}</WidgetFormContainer>
  )
}

const NewsletterSubscriptionForm = ({ checkboxLabel, variant, className }) => {
  const VariantContainer = variant === "widget" ? WidgetForm : BannerForm
  return (
    <VariantContainer className={className}>
      <div className="contents">
        <h2 className={variant === "widget" ? "fancy" : null}>
          {variant === "widget"
            ? "Join our newsletter"
            : "Get travel inspiration and discounts"}
        </h2>
        <p className="p3">
          {variant === "widget"
            ? "Get a weekly dose of discounts and inspiration for adventure lovers"
            : "Join our weekly travel newsletter"}
        </p>
        <StyledSubcription
          formName="newsletter"
          checkboxLabel={checkboxLabel}
          buttonSize="full-width"
        />
      </div>
    </VariantContainer>
  )
}

export default NewsletterSubscriptionForm

BannerForm.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
WidgetForm.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

NewsletterSubscriptionForm.propTypes = {
  checkboxLabel: PropTypes.string,
  variant: PropTypes.oneOf(["banner", "widget"]),
  className: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
}
