export const SITE_SEO_LINKS_ROUTES = [
  {
    title: "Top Regions",
    content: [
      {
        name: "Canada",
        link: "/hikes/canada/",
        childLinks: [
          { name: "Banff", link: "/hikes/banff/" },
          { name: "Jasper", link: "/hikes/jasper/" },
          { name: "Kananaskis", link: "/hikes/kananaskis-trail/" },
          { name: "Whistler", link: "/hikes/whistler/" },
          { name: "Yoho", link: "/hikes/yoho-national-park/" },
        ],
      },
      {
        name: "England",
        link: "/hikes/england/",
        childLinks: [
          {
            name: "Lake District",
            link: "/hikes/lake-district-national-park/",
          },
          { name: "Cotswolds", link: "/hikes/cotswolds/" },
          {
            name: "North York Moors",
            link: "/hikes/north-york-moors-national-park/",
          },
          {
            name: "Peak District",
            link: "/hikes/peak-district-national-park/",
          },
          { name: "South Downs", link: "/hikes/south-downs/" },
        ],
      },
      {
        name: "France",
        link: "/hikes/france/",
        childLinks: [
          { name: "Chamonix", link: "/hikes/chamonix/" },
          { name: "Pyrenees", link: "/hikes/pyrenees/" },
          { name: "Mercantour", link: "/hikes/mercantour-national-park/" },
          { name: "Vanoise", link: "/hikes/vanoise/" },
        ],
      },
      {
        name: "Italy",
        link: "/hikes/italy/",
        childLinks: [
          { name: "Amalfi Coast", link: "/hikes/amalfi-coast/" },
          { name: "Dolomites", link: "/hikes/the-dolomites/" },
          {
            name: "Gran Paradiso",
            link: "/hikes/gran-paradiso-national-park/",
          },
          { name: "Tuscany", link: "/hikes/tuscany/" },
        ],
      },
      {
        name: "Scotland",
        link: "/hikes/scotland/",
        childLinks: [
          { name: "Cairngorms", link: "/hikes/cairngorms-national-park/" },
          {
            name: "Loch Lomond",
            link: "/hikes/loch-lomond-and-the-trossachs/",
          },
          { name: "Highlands", link: "/hikes/the-highlands/" },
        ],
      },
      {
        name: "USA",
        link: "/hikes/usa/",
        childLinks: [
          { name: "Glacier", link: "/hikes/glacier-national-park/" },
          { name: "Grand Canyon", link: "/hikes/grand-canyon-national-park/" },
          { name: "Yellowstone", link: "/hikes/yellowstone/" },
          { name: "Yosemite", link: "/hikes/yosemite-national-park/" },
          { name: "Zion", link: "/hikes/zion-national-park/" },
        ],
      },
    ],
  },
  {
    title: "Activities",
    content: [
      { name: "Backpacking", link: "/backpacking/" },
      { name: "City Walks", link: "/city-walks/" },
      { name: "Hiking", link: "/hikes/" },
      { name: "Scrambling", link: "/scrambling/" },
      { name: "Mountain Biking", link: "/mountain-biking/" },
      { name: "Road Biking", link: "/road-biking/" },
      { name: "Snowshoeing", link: "/snowshoeing/" },
      { name: "XC Skiing", link: "/xc-skiing/" },
    ],
  },
]
