import React, { useState } from "react"
import PropTypes from "prop-types"
import { FormContext } from "@src/components/form"
import FormFieldInputEmail from "@src/components/form-field-input-email"

// source: https://stackoverflow.com/a/46181/1798697
const validateEmail = email => {
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailPattern.test(String(email).toLowerCase())
}

const FormFieldEmailAddress = ({
  id,
  name,
  label,
  confirmation,
  required,
  validation,
  autoComplete,
  ...props
}) => {
  const [hasBlurred, setHasBlurred] = useState(false)
  const [confirmationHasBlurred, setConfirmationHasBlurred] = useState(false)
  return (
    <FormContext>
      {({ getValues, trigger }) => (
        <>
          <FormFieldInputEmail
            id={id}
            name={name}
            label={label}
            required={required}
            onBlur={() => {
              if (!hasBlurred) {
                setHasBlurred(true)
              }
              trigger(name)
            }}
            onChange={() => {
              if (hasBlurred) {
                trigger(name)
                if (confirmation && confirmationHasBlurred) {
                  trigger(`${name}-confirm`)
                }
              }
            }}
            validation={{
              ...validation,
              validate: value => {
                return (
                  validateEmail(value) || "Is that the right email address?"
                )
              },
            }}
            autoComplete={autoComplete}
            {...props}
          />
          {confirmation && (
            <FormFieldInputEmail
              id={`${id}-confirm`}
              name={`${name}-confirm`}
              label={`${label} confirmation`}
              placeholder="Confirm email address"
              required={required}
              onBlur={() => {
                if (!confirmationHasBlurred) {
                  setConfirmationHasBlurred(true)
                }
                trigger(`${name}-confirm`)
              }}
              onChange={() => {
                if (confirmationHasBlurred) {
                  trigger(`${name}-confirm`)
                }
              }}
              validation={{
                validate: value => {
                  const values = getValues()
                  const email = values[name]
                  return (
                    (email && email === value) || "Email address must match"
                  )
                },
              }}
              autoComplete={autoComplete}
            />
          )}
        </>
      )}
    </FormContext>
  )
}
FormFieldEmailAddress.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  confirmation: PropTypes.bool,
  required: PropTypes.bool,
  validation: PropTypes.object,
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
FormFieldEmailAddress.defaultProps = {
  validation: {},
  confirmation: false,
}
export default FormFieldEmailAddress
