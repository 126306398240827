import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { SITE_SEO_LINKS_TOURS } from "@src/utils/constants/site-seo-links/tours"
import { SITE_SEO_LINKS_ROUTES } from "@src/utils/constants/site-seo-links/routes"
import { SITE_SEO_LINKS_BLOGS } from "@src/utils/constants/site-seo-links/blogs"
import Link from "@src/components/core-link"
import ResponsiveSection from "@src/components/container-responsive-section"
import CollapsibleContent from "@src/components/core-collapsible-content"
import BottomSeoTile from "@src/components/content-bottom-seo-tile"

export const SiteSeoLinksContainer = styled(ResponsiveSection)`
  border-top: 1px solid ${props => props.theme.border1};
  padding: 16px 0;
  > h3 {
    font-family: system-ui;
    font-weight: bold;
    &:first-child {
      margin-top: 0;
    }
  }
  & .collapsible-header > h2,
  & .collapsible-header > h4 {
    font-family: system-ui;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  & .collapsible-content {
    font-family: system-ui;
  }
  & > .collapsible-div {
    margin: 5px 0;
    border-radius: 8px;
    border: 1px solid #e5e7eb; //Refactor with design guide
    padding: 24px;
    & a {
      padding: 5px 0;
    }
    &.static > .collapsible-content {
      display: flex;
      flex-direction: column;
      margin: 5px 0;
      & > .seo-links-subdiv {
        margin: 5px 0;
        & > a:first-child {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          max-width: 100%;
          font-weight: bold;
        }
      }
      & a {
        margin: 5px 0;
      }
    }
  }

  @media only screen and (${props => props.theme.screen.tablet.min}) {
    & > .collapsible-div.static > .collapsible-content {
      flex-direction: row;
      flex-wrap: wrap;
      & > * {
        width: calc(33.33%);
      }
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .collapsible-div {
      padding: 32px;
      & > .collapsible-header {
        & > h2,
        & > h4 {
          font-size: 18px;
        }
        & > .collapsible-icon.icon {
          font-size: 22px;
        }
      }
      &.static > .collapsible-content {
        & > * {
          width: calc(16.66%);
        }
      }
    }
  }
`
// Static SEO
const generateLinks = links => {
  return links.map(item => {
    const subheader = !!item.childLinks
    return subheader ? (
      <div
        key={item.name}
        className={`seo-links-subdiv ${item.name.toLowerCase()}`}
      >
        <Link key={`${item.name}-link`} to={item.link} variant="simple">
          {item.name}
        </Link>
        {generateLinks(item.childLinks)}
      </div>
    ) : (
      <Link key={item.name} to={item.link} variant="compact">
        {item.name}
      </Link>
    )
  })
}
const linkArray = [
  { title: "Tours", content: SITE_SEO_LINKS_TOURS },
  { title: "Routes", content: SITE_SEO_LINKS_ROUTES },
  { title: "Blogs", content: SITE_SEO_LINKS_BLOGS },
]

const SiteSeoLinks = ({ seoData }) => {
  return (
    <SiteSeoLinksContainer align="left">
      {seoData && seoData.length
        ? // Dynamic
          seoData.map(data => {
            const { title, ...seoData } = data
            return (
              <CollapsibleContent
                key={title}
                header={<h2>{title}</h2>}
                content={<BottomSeoTile data={seoData} />}
                className={`dynamic ${title.toLowerCase()}`}
                icon="v2-angle-down"
              />
            )
          })
        : // Static
          linkArray.map(linkItem => (
            <React.Fragment key={linkItem.title}>
              <h3>{linkItem.title}</h3>
              {linkItem.content.map(section => {
                return (
                  <CollapsibleContent
                    key={section.title}
                    header={<h4>{section.title}</h4>}
                    content={generateLinks(section.content)}
                    className={`static ${section.title.toLowerCase()}`}
                    icon="v2-angle-down"
                  />
                )
              })}
            </React.Fragment>
          ))}
    </SiteSeoLinksContainer>
  )
}

SiteSeoLinks.propTypes = {
  seoData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      backlink: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string, link: PropTypes.string })
      ),
    })
  ),
}

export default SiteSeoLinks
