import React, { useState, useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import Form, { FormActions } from "@src/components/form"
import FormFieldInputText from "@src/components/form-field-input-text"
import FormFieldInputEmail from "@src/components/form-field-input-email"
import FormFieldInputCheckbox from "@src/components/form-field-input-checkbox"
import Button from "@src/components/core-button"

const StyledFormActions = styled(FormActions)`
  @media only screen and (${props => props.theme.screen.small.min}) {
    align-items: flex-start;
  }
`

const StyledForm = styled(Form)`
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .narrow {
      width: 49%;
    }
    & > .full-width {
      width: 100%;
    }
    & > .left {
      float: left;
    }
    & > .right {
      float: right;
    }
  }
`

const SubscriptionForm = ({
  formName,
  className,
  checkboxLabel,
  children,
  buttonSize,
  nameRequired,
  buttonText,
}) => {
  const [formStatus, setFormStatus] = useState(null)

  const handleSubmit = useCallback(() => {
    setFormStatus({
      processing: "Please wait a moment while we sign you up...",
    })
  }, [setFormStatus])
  const handleSubmitError = useCallback(
    error => {
      setFormStatus({ error: error.message })
    },
    [setFormStatus]
  )
  const handleSubmitSuccess = useCallback(
    result => {
      if (result.netlify) {
        setFormStatus({
          success: "Thanks for subscribing!",
        })
        GTM.dataLayerPush({
          event: "10a.subscription.success",
          subscribed: true,
        })
      } else {
        handleSubmitError({ message: "There was an error signing you up." })
      }
    },
    [setFormStatus, handleSubmitError]
  )
  return (
    <StyledForm
      name={`subscription-zoho${formName && `-${formName}`}`}
      onSubmit={handleSubmit}
      onSubmitError={handleSubmitError}
      onSubmitSuccess={handleSubmitSuccess}
      netlify
      className={className}
    >
      <FormFieldInputText
        name="first_name"
        label="First name"
        placeholder="First name"
        validation={
          nameRequired
            ? { required: "First name is required to subscribe." }
            : undefined
        }
        className="narrow left"
      />
      <FormFieldInputText
        name="last_name"
        label="Last name"
        placeholder="Last name"
        validation={
          nameRequired
            ? { required: "Last name is required to subscribe." }
            : undefined
        }
        className="narrow right"
      />
      <FormFieldInputEmail
        name="email"
        label="Email"
        placeholder="Email"
        icon="mail"
        validation={{ required: "Email address is required to subscribe." }}
        className="full-width left"
      />
      {children}
      {checkboxLabel && (
        <FormFieldInputCheckbox
          name="subscribe"
          label={checkboxLabel}
          validation={{
            validate: value => value || "You must agree to subscribe.",
          }}
          className="full-width left"
        />
      )}
      <StyledFormActions
        submitting={formStatus && formStatus.processing ? true : undefined}
        className="full-width left"
      >
        {(formStatus?.success && (
          <p className="form-success">{formStatus.success}</p>
        )) ||
          (formStatus?.error && (
            <p className="form-error">{formStatus.error}</p>
          )) ||
          (formStatus?.processing && (
            <p className="form-info">{formStatus.processing}</p>
          ))}
        <Button
          type="submit"
          size={buttonSize}
          disabled={formStatus && formStatus.processing ? true : undefined}
        >
          {buttonText || "Subscribe"}
        </Button>
      </StyledFormActions>
    </StyledForm>
  )
}

SubscriptionForm.propTypes = {
  formName: PropTypes.string,
  className: PropTypes.string,
  checkboxLabel: PropTypes.string,
  children: PropTypes.node,
  buttonSize: PropTypes.string,
  nameRequired: PropTypes.bool,
  buttonText: PropTypes.string,
}

export default SubscriptionForm
